export const JAVA_MAX_INT = 2147483647;

export const PUBLICKEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4GCt4K59fqWTW/PahKux
9cIGXCorQhOxGqPjEmnDgLyNkBZMEUz1kJwi1By5ojUxRXjmI5LRyDJ/D6HIBWUQ
4Thss3apqgdfqp2BuNB+ia/2uzXwDFgiyIvlYU/GPakXvFQGNQ40IoHYOODVwLCb
vTnELmP8wrDQUNTyR7PFgx5zGLF2iO5i87iy2iz6DzrBkZfxtUWBlIHkh1J/2Rtv
i1AJj6iKLS3p/k2WOF7RqMlpcWBtGqYJ3/GhuDDtb3nOdIv2XHfVvVBHCBFNv1Hh
GK6G9+dk2RER5SlGm5vtdbgoPXJtKgGyPY6xHMYtPa2Akz2t/stb4ZWQ8IdWxw2m
xwIDAQAB
-----END PUBLIC KEY-----`;
