import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, FormInstance, Input, InputNumber, Row, Select, Table, Typography } from 'antd';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import CustomPagination from '../../../components/custom-pagination';
import { convertQueryStringToObj, objectHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { priceListService } from '../services/pricelist.service';
import { IPriceList, IPriceListListing } from '../types/price-list-types.s';

interface IPriceListDiscountProps {}

const searchStatusOptions = [
  { label: 'All', value: '' },
  { label: 'Open', value: 'OPEN' },
  { label: 'Scheduled', value: 'SCHEDULED' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'On-Hold', value: 'ON-HOLD' },
  { label: 'Closed', value: 'CLOSED' },
  // { label: 'Expired', value: 'EXPIRED' },
  { label: 'Differed', value: 'DIFFERED' }
];

const sortOptions = [
  { label: 'Name', value: 'price_list_name' },
  { label: 'Rank', value: 'rank' },
  { label: 'Status', value: 'status' },
  { label: 'Is Exclusive', value: 'is_exclusive' }
];

interface EditableRowProps {
  index: number;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<any> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef<any>(null);
  const form = React.useContext(EditableContext)!;

  React.useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (dataIndex === 'rank') form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    if (editing) {
      if (dataIndex === 'rank')
        childNode = (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`
              }
            ]}
          >
            <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={1} />
          </Form.Item>
        );
    } else {
      childNode = (
        <div className="cursor-pointer" onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

const PriceListDiscountListing: React.FunctionComponent<IPriceListDiscountProps> = props => {
  //new declare
  const [priceListForm] = Form.useForm();
  const navigate = useNavigate();

  //new State
  const [priceListFormResponse, setPriceListFormResponse] = React.useState({} as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const [masterCatalogOptions, setMasterCatalogOptions] = React.useState({} as any);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '5');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 5
    };
  });

  //other declerations
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const columns: any = [
    // {
    //   title: 'ID',
    //   align: 'center',
    //   dataIndex: 'id'
    // },
    {
      title: 'Name',
      align: 'center',
      dataIndex: 'price_list_name'
    },
    {
      title: 'Rank',
      align: 'center',
      editable: true,
      dataIndex: 'rank',
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        return (
          <>
            {record?.rank}{' '}
            {record?.status !== 'ACTIVE' && <EditOutlined style={{ color: '#5885af', fontSize: '16px' }} />}
          </>
        );
      }
    },
    {
      title: 'Start date',
      align: 'center',
      editable: false,
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        return <div style={{ width: '100px' }}>{record?.start_date}</div>;
      }
    },
    {
      title: 'End date',
      align: 'center',
      editable: false,
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        return <div style={{ width: '100px' }}>{record?.end_date}</div>;
      }
    },
    {
      title: 'Exclusive',
      align: 'center',
      dataIndex: 'exeStatus',
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        let exeStatus = record?.is_exclusive ? 'true' : 'false';
        return <>{exeStatus}</>;
      }
    },
    // {
    //   title: 'Parent List ID',
    //   align: 'center',
    //   dataIndex: 'parent_price_list_id'
    // },
    {
      title: 'Parent List Name',
      align: 'center',
      dataIndex: 'parent_price_list_id',
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        return <>{getParentPricelistName(record)}</>;
      }
    },
    // {
    //   title: 'Master Catalog ID',
    //   align: 'center',
    //   dataIndex: 'master_catalog_id'
    // },
    {
      title: 'Master Catalog Name',
      align: 'center',
      dataIndex: 'master_catalog_id',
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        return <>{getMasterCatalogName(record)}</>;
      }
    },
    {
      title: 'Catalog Reference',
      align: 'center',
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        return <>{record?.import_catalog_ref_name}</>;
      }
    },
    {
      title: 'Total Active',
      align: 'center',
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        return <>{record?.total_active_line_items}</>;
      }
    },
    {
      title: 'Total Inactive',
      align: 'center',
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        return <>{record?.total_in_active_line_items}</>;
      }
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'status'
    },
    {
      title: 'Action',
      width: '15%',
      render(value: IPriceListListing, record: IPriceListListing, index: any) {
        return (
          <section className="flex flex-wrap justify-center">
            <ActionButton
              action="VIEW"
              title="View"
              onClick={() => {
                const params = {
                  offset: '0',
                  backTabTo: 'BASIC-DETAILS'
                };
                const paramString = new URLSearchParams(params).toString();
                navigate(`/promotion-engine/promotions/price-list/${record?.id}/price-list-view-form?${paramString}`);
              }}
            ></ActionButton>
            <ActionButton
              action="CREATE_NEW_VERSION"
              title="Edit"
              onClick={() => {
                const params = {
                  offset: '0',
                  backTabTo: 'BASIC-DETAILS'
                };
                const paramString = new URLSearchParams(params).toString();
                navigate(`/promotion-engine/promotions/price-list/${record?.id}/price-list-edit-form?${paramString}`);
              }}
            ></ActionButton>
            <ActionButton
              action="DELETE"
              title="Delete"
              onClick={() => {
                handleOnDelete(record?.id);
              }}
            ></ActionButton>
          </section>
        );
      },
      align: 'center'
    },
    {
      title: 'Status Update',
      width: '15%',
      render(value: IPriceList, record: IPriceList, index: any) {
        let actions: { label: string; action: string }[] = [];
        if (record.status === 'OPEN') {
          actions.push(
            ...[
              { label: 'Schedule', action: 'SCHEDULED' },
              { label: 'Cancel', action: 'CANCELLED' }
            ]
          );
        }
        if (record.status === 'CANCELLED') {
          actions.push({ label: 'Open', action: 'OPEN' });
        }
        if (record.status === 'SCHEDULED') {
          actions.push(
            ...[
              { label: 'Open', action: 'OPEN' },
              { label: 'Cancel', action: 'CANCELLED' },
              { label: 'Active', action: 'ACTIVE' }
            ]
          );
        }
        if (record.status === 'ACTIVE') {
          actions.push(
            ...[
              { label: 'Close', action: 'CLOSED' },
              // { label: 'Expired', action: 'EXPIRED' },
              { label: 'On Hold', action: 'ON-HOLD' }
            ]
          );
        }
        if (record.status === 'ON-HOLD') {
          actions.push(
            ...[
              { label: 'Active', action: 'ACTIVE' },
              // { label: 'Expired', action: 'EXPIRED' },
              { label: 'Differed', action: 'DIFFERED' },
              { label: 'Close', action: 'CLOSED' }
            ]
          );
        }
        if (record.status === 'EXPIRED') {
          actions.push(...[{ label: 'Close', action: 'CLOSED' }]);
        }
        if (record.status === 'DIFFERED') {
          actions.push(...[{ label: 'Close', action: 'CLOSED' }]);
        }
        return (
          <section className="flex flex-wrap justify-center">
            {actions.map(({ action, label }) => (
              <Button
                className={'cursor-pointer'}
                key={action}
                type="link"
                onClick={() => handleStatusChange(action, record.id)}
              >
                {label}
              </Button>
            ))}
          </section>
        );
      }
    }
  ];

  const handleOnDelete = async (ItemId: string) => {
    setLoading(true);
    const { errors } = await priceListService.deletePriceList(ItemId);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Item Removed Success' });
      if (pageControl.currentPage == 1) {
        loadAllPriceLists();
      } else {
        getAllMasterCatalogData();
        setPageControl(prev => ({ ...prev, currentPage: 1 }));
      }
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleStatusChange = async (status: string, Id: string) => {
    setLoading(true);
    const { errors } = await priceListService.updateStatus(Id, status);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Price List status updated successfully!' });
      if (pageControl.currentPage == 1) {
        loadAllPriceLists();
      } else {
        getAllMasterCatalogData();
        setPageControl(prev => ({ ...prev, currentPage: 1 }));
      }
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getParentPricelistName = (record: IPriceListListing) => {
    let parentPriceListName = '';
    if (record?.parent_price_list_id) {
      let parentPriceListdata = priceListFormResponse?.data?.data?.find(
        (item: IPriceListListing) => item?.id === record?.parent_price_list_id
      );
      parentPriceListName = parentPriceListdata?.price_list_name || 'Name Not Found';
    }
    return parentPriceListName;
  };

  const getMasterCatalogName = (record: IPriceListListing) => {
    let masterCatalogName = '';
    if (record?.master_catalog_id && masterCatalogOptions?.count >= 1) {
      let parentPriceListdata = masterCatalogOptions?.data.find((item: any) => {
        return item.id === record?.master_catalog_id;
      });
      masterCatalogName = parentPriceListdata?.name || 'Name Not Found';
    }
    return masterCatalogName;
  };

  React.useEffect(() => {
    getAllMasterCatalogData();
  }, []);

  React.useEffect(() => {
    if (pageControl?.currentPage) handleOnSearch(pageControl?.currentPage - 1);
  }, [pageControl]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const columnsEdited = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  const getEditedKey = (previousRowValues: any, currentRowValues: any): string => {
    for (const key in previousRowValues) {
      if (previousRowValues[key as keyof any] !== currentRowValues[key as keyof any]) {
        return key;
      }
    }
    return '';
  };

  const editingActions: Record<string, any> = {
    rank: (id: string, value: string) => priceListService.patchPriceListRank(id, value)
  };

  const handleSave = async (row: any) => {
    const transformedRow = { ...row };

    const newData = [...priceListFormResponse?.data?.data];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    const PriceListId = row.id;
    const editedKey = getEditedKey(item, transformedRow);

    if (editedKey === '') return;

    setLoading(true);
    const { errors } = await editingActions[editedKey](PriceListId, transformedRow[editedKey as keyof any]);
    setLoading(false);

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
      return;
    }
    //!Find out which index is edit
    newData.splice(index, 1, {
      ...item,
      ...transformedRow
    });
    setPriceListFormResponse((response: any) => {
      return {
        ...response,
        data: {
          ...response?.data,
          data: newData
        }
      };
    });

    displaySuccessNotification({ message: 'PriceList update success' });
  };

  const loadAllPriceLists = async () => {
    handleOnSearch(0);
    getAllMasterCatalogData();
  };

  const handleOnSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = priceListForm.getFieldsValue();
    const filteredFormValues = {
      ...objectHelpers.deleteUndefinedValuesFromObject(formValues),
      offset: offset + '',
      limit: pageControl.pageSize + ''
    };
    setSearchParams(filteredFormValues);
    const params = {
      ...formValues,
      offset,
      limit: pageControl.pageSize
    };
    const { data, errors } = await priceListService.getPriceList(params);
    if (_.isEmpty(errors)) {
      const transformedData = data?.price_lists?.map((item: IPriceListListing) => {
        return {
          ...item,
          key: item?.id
        };
      });
      const transformedResponse: any = {
        ...data,
        data: {
          data: transformedData,
          count: data?.count
        }
      };
      setPriceListFormResponse(transformedResponse);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getAllMasterCatalogData = async (offset = 0) => {
    setLoading(true);
    let params = { offset, limit: 100 };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    const { data, errors } = await priceListService.getMasterData(filteredParams);
    if (_.isEmpty(errors)) {
      setMasterCatalogOptions(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  // const handlePageChange = (current_page: number) => {
  //   setCurrentPage(current_page);
  // };

  // const handlePageChangeNext = (current_page: number) => {
  //   setCurrentPage(current_page);
  //   handleOnSearch(current_page + 8);
  // };

  // const handlePageChangePrevious = (current_page: number) => {
  //   setCurrentPage(current_page);
  //   handleOnSearch(current_page - 1);
  // };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Form
            form={priceListForm}
            layout="vertical"
            onFinish={() =>
              pageControl?.currentPage == 1 ? handleOnSearch(0) : setPageControl(prev => ({ ...prev, currentPage: 1 }))
            }
          >
            <Row justify={'space-between'} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Price Lists
                </Typography.Title>
              </Col>
              <Col>
                <div className="flex gap-4">
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                      navigate(`/promotion-engine/promotions/price-list/price-list-create-form`);
                    }}
                  >
                    <BoldButtonLabel labelText="Create Price List" />
                  </Button>
                </div>
              </Col>
            </Row>
            <TenantInfo />
            <Row gutter={24} className="mt-4">
              <Col xs={24} md={6}>
                <Form.Item
                  name="price_list_name"
                  label="Name"
                  rules={[{ required: false, message: "This field can't be empty" }]}
                >
                  <Input size="large" placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="status" label="Status">
                  <Select placeholder="Status" size="large" allowClear options={searchStatusOptions}></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Button htmlType="submit" type="primary" size="large" className="mb-4">
                <BoldButtonLabel labelText="Search" />
              </Button>
            </Row>
            {!_.isEmpty(priceListFormResponse?.data) && (
              <Row className="my-2" justify={'space-between'} gutter={[12, 12]} align={'middle'}>
                <Col xs={24} md={6}>
                  <Form.Item label="Sort By" name={'sort'} className="mb-0">
                    <Select
                      size="large"
                      placeholder="Sort By"
                      onChange={value => {
                        handleOnSearch(pageControl.currentPage - 1);
                      }}
                      options={sortOptions}
                    ></Select>
                  </Form.Item>
                </Col>
                {/* <Col>
                  <CustomPagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(priceListFormResponse.data.count / 10)}
                    handleNext={handlePageChangeNext}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChangePrevious}
                  />
                </Col> */}
              </Row>
            )}
            <Table
              loading={false}
              pagination={{
                current: pageControl?.currentPage,
                total: priceListFormResponse?.data?.count || 0,
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '2', '5', '10', '20', '50', '100'],

                onChange: (currentPage, pageSize) => setPageControl({ currentPage, pageSize })
              }}
              components={components}
              bordered
              dataSource={priceListFormResponse.data?.data || []}
              columns={columnsEdited}
              scroll={{ x: 1000 }}
            ></Table>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PriceListDiscountListing;
