import { Button, Col, Form, Input, Modal, Popconfirm, Row, Table, TableColumnsType, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomPagination from '../../../components/custom-pagination';
import { profileService } from '../../../services';
import { IUserListingResponse, User } from '../../../types/profile.types';
import _ from '../../../helpers/lodash';
import { useLoader } from '../../../stores/use-loader';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { objectHelpers } from '../../../helpers';
import ActionButton from '../../../components/ActionButton';
import UserUpdateForm from './user-update-form';
import TenantInfo from '../../../components/TenantIdInfo';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

interface IManageUserListing { }

const ManageUserListing: React.FunctionComponent<IManageUserListing> = props => {
  const [manageUserForm] = Form.useForm();
  const [manageUserEditForm] = Form.useForm();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [userListingResponse, setUserListingResponse] = useState({} as IUserListingResponse);
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const columns: TableColumnsType<any> = [
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('firstName')}
          <div className="flex flex-col ">
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleSort(0, 'first_name', 'sort');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleSort(0, 'first_name', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        return <>{record.first_name}</>;
      },
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('lastName')}
          <div className="flex flex-col ">
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleSort(0, 'last_name', 'sort');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleSort(0, 'last_name', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        return <>{record.last_name}</>;
      },
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('userName')}
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        return <>{record?.user_name}</>;
      },
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('emailId')}
          <div className="flex flex-col ">
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleSort(0, 'email', 'sort');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleSort(0, 'email', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        return <>{record.email}</>;
      },
    },
    {
      title: t('groups'),
      align: 'center',
      render(value, record, index) {
        return (
          <div>
            {(record?.subscriptions || [])?.map((item: any, index: any) => {
              return (
                <div key={index}>
                  {item.product} : {item.role}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t('actions'),
      align: 'center',
      render(value, record, index) {
        return (
          <section className="flex justify-center">
            <ActionButton
              action="CREATE_NEW_VERSION"
              onClick={() => {
                navigate('/admin/manage_User/edit-user?user_name=' + record.user_name);
              }}
              title={t('editUser')}
            />
            <Popconfirm
              title={t('areYouSure')}
              onConfirm={() => {
                handleOnConfirmDelete(record.user_name);
              }}
            >
              <ActionButton action="DELETE" title={t('delete')} />
            </Popconfirm>
          </section>
        );
      },
    },
  ];
  
  const [searchParams, setSearchParams] = useSearchParams({ offset: '0', limit: '10' });

  useEffect(() => {
    manageUserForm.setFieldsValue(Object.fromEntries(searchParams.entries()));
    fetchUserList();
  }, [searchParams]);

  const { setLoading } = useLoader();

  const handleOnConfirmDelete = async (email: any) => {
    setLoading(true);
    const { errors } = await profileService.removeUser(email);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('userDeletedSuccessfully') });

      if (searchParams.get('offset') === '0') {
        fetchUserList();
      }
      setSearchParams((prev: any) => {
        prev.set('offset', '0');
        return prev;
      });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const fetchUserList = async (offset = 0, sortKey: any = 'first_name', sortType: any = 'sort') => {
    setLoading(true);
    try {
      let params = { offset, limit: 10 };
      params = { ...params, ...Object.fromEntries(searchParams.entries()) };
      const { data, errors } = await profileService.getUserList(params);
      if (_.isEmpty(errors)) {
        setUserListingResponse(data);
      } else {
        displayErrorNotifications(errors);
      }
    } catch (error: any) {
      displayErrorNotifications([error?.message || t('anUnexpectedErrorOccurred')]);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (currentPage: number) => {
    const offsetValue = (currentPage - 1) * 10;
    await fetchUserList(offsetValue);
  };

  const getPageNumberFromOffset = (offset: number) => {
    if (offset === 0) return 1;
    let pageNumber = offset / 10 + 1;
    return pageNumber || 1;
  };

  const handleOnModalClose = () => {
    setManageModalVisible(false);
  };

  const handleOnUpdate = () => {
    const { first_name, last_name } = manageUserEditForm.getFieldsValue();

    handleOnModalClose();
  };
  const handleFilterSearch = () => {
    const formValues = manageUserForm.getFieldsValue();
    const offset = 0;
    const limit = searchParams.get('limit') || 10;
    const sort = searchParams.get('sort') || '';
    const desc = searchParams.get('desc') || '';
    const params = {
      ...formValues,
      offset,
      limit,
      sort,
      desc
    };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
  };
  const handleSort = (offset: any = 0, sortKey: any = 'first_name', sortType: any = 'sort') => {
    const deletePreviousSort = 'sort' === sortType ? 'desc' : 'sort';
    setSearchParams((prev: any) => {
      prev.set(sortType, sortKey);
      prev.delete(deletePreviousSort);
      return prev;
    });
  };

  return (
    <>
      <Form
        form={manageUserForm}
        layout={'vertical'}
        onFinish={() => {
          handleFilterSearch();
        }}
      >
        <Row gutter={12} justify={'space-between'} className="bg-white p-6">
          <Typography.Title level={3} className="text-[#2e2a5b] pb-4">
            <div className="pb-2 pt-2">{t('manageUsers')}</div>
            <TenantInfo />
          </Typography.Title>

          <Col>
            <div className="flex gap-4">
              <Button
                className="w-[150px] mr-[27px]"
                type="primary"
                onClick={() => {
                  navigate(`/admin/manage_User/add-user`);
                }}
              >
                <BoldButtonLabel labelText={t('createUser')} />
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="bg-white pt-4 pb-4 pl-8 pr-8 m-6 search-user gap-3">
          <Col sm={24} xs={24}>
            <Form.Item name={'first_name'} label={t('firstName')} className="w-full p-0 m-0">
              <Input className="w-full" allowClear placeholder={t('searchByFirstName')} />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24}>
            <Form.Item name={'last_name'} label={t('lastName')} className="w-full p-0 m-0">
              <Input className="w-full" allowClear placeholder={t('searchByLastName')} />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} className="flex items-center ">
            <Button type="primary" htmlType="submit" block className="w-full p-0 m-0">
              <BoldButtonLabel labelText={t('search')} />
            </Button>
          </Col>
        </Row>

        <div className=" m-6 bg-white p-6">
          <section className="">
            <p className="pb-6 font-medium text-[16px]">{t('manageUsers')}</p>
            <Table
              loading={false}
              pagination={{
                total: userListingResponse?.count || 0,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                pageSize: parseInt(searchParams.get('limit') as string) || 10,
                current: parseInt((searchParams.get('offset') as string) || '0') + 1,
                onChange: (currentPage, pageSize) => {
                  setSearchParams(prev => {
                    prev.set('offset', (currentPage - 1).toString());
                    prev.set('limit', pageSize + '');
                    return prev;
                  });
                }
              }}
              bordered
              dataSource={userListingResponse.users || []}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </div>
      </Form>

      {/* manageModalVisible */}
      <Modal
        centered
        width={900}
        open={manageModalVisible}
        onCancel={handleOnModalClose}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
        <Typography.Title level={4}>{t('updateUserDetails')}</Typography.Title>
          </div>
        }
      >
        <UserUpdateForm formInstance={manageUserEditForm} handleOnUpdate={handleOnUpdate} />
      </Modal>
    </>
  );
};
export default ManageUserListing;
