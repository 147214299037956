import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Spin,
  Statistic,
  Table,
  TableColumnsType,
  TimePicker,
  Typography
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import {
  countryList,
  currencyList,
  currencyListWithCode,
  priceHelpers,
  timeZonesWithCode
} from '../../../../data/intl-data';

import { promotionServiceCreate } from '../../services/promotionCreate.service';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../../components/ActionButton';
import { TENANT_DOMAIN_INDEX } from '../../../../config/envConfig';
import { validationHelpers } from '../../../../helpers';
import { getDayJsObjectWithFormattedString } from '../../../../helpers/date.helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import {
  formatToTwoDecimalPlaces,
  getFormattedNumeric2DecimalPointValidatorForInput,
  getTitleValidator
} from '../../../../helpers/validation.helpers';
import { useAuth } from '../../../../stores/use-auth';
import { useLoader } from '../../../../stores/use-loader';
import { useLocale } from '../../../../stores/use-locale';
import { AnalyticsService } from '../../../loyalty-engine/services/Analytics.service';
import { useCustomers } from '../../hooks/drop-down-hooks/use-customers';
import { useOnlineChannels } from '../../hooks/drop-down-hooks/use-online-channels';
import { usePos } from '../../hooks/drop-down-hooks/use-pos-channel';
import { useProducts } from '../../hooks/drop-down-hooks/use-products';
import { useSellers } from '../../hooks/drop-down-hooks/use-sellers';
import { promotionService } from '../../services/promotion.service';
import { Criteria } from '../../types/create-promo';
import { loyaltyService } from '../../../loyalty-engine/services/loyalty.service';

interface IPromotionFormProps {
  isUpdate?: boolean;
  promotionFormInitialValues?: any;
  viewOnly?: boolean;
}

const filterRespose = [
  { label: 'Tier Level', value: 'TIER' },
  { label: 'Segments', value: 'SEGMENT' },
  { label: 'RFM Category', value: 'RFM_CATEGORY' }
];

const MAX_LIMIT = 999999999;

const staff = [
  { label: 'Pavan Kumar', value: 'EMP001' },
  { label: 'Dhanush Amin', value: 'EMP002' },
  { label: 'Ankur Dixit', value: 'EMP003' }
];

const appliesToClassForAll = [
  { label: 'Order Total', value: 'ORDER_TOTAL' },
  { label: 'Line Items', value: 'LINE_ITEMS' },
  { label: 'Shipping', value: 'SHIPPING' },
  { label: 'Loyalty program', value: 'LOYT_POINTS' },
  { label: 'Payments', value: 'PAYMENTS' }
];

const appliesToClassForSubTotal = [
  { label: 'Order Total', value: 'ORDER_TOTAL' },
  { label: 'Line Items', value: 'LINE_ITEMS' }
];

const promotionTypes = [
  { label: 'Sub Total Discount Promo', value: 'SUB_TTL_DIS' },
  { label: 'Volume Discount Promo', value: 'VOLUME_DISC' },
  { label: 'Bundle Discount Promo', value: 'BUNDLE_DISC' },
  { label: 'Shipping Discount Promo', value: 'SHIPPING_DISC' },
  { label: 'BXGY Promotion', value: 'BXGY' }
];
const timeZones = [
  { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India' },
  { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA' },
  { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile' },
  { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' },
  { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe' }
];
let months = [
  { label: 'January', value: 'January', checked: false },
  { label: 'February', value: 'February', checked: false },
  { label: 'March', value: 'March', checked: false },
  { label: 'April', value: 'April', checked: false },
  { label: 'June', value: 'June', checked: false },
  { label: 'July', value: 'July', checked: false },
  { label: 'August', value: 'August', checked: false },
  { label: 'September', value: 'September', checked: false },
  { label: 'October', value: 'October', checked: false },
  { label: 'November', value: 'November', checked: false },
  { label: 'December', value: 'December', checked: false }
];
let weeks = [
  { label: 'Monday', value: 'Monday', checked: false },
  { label: 'Tuesday', value: 'Tuesday', checked: false },
  { label: 'Wednesday', value: 'Wednesday', checked: false },
  { label: 'Thursday', value: 'Thursday', checked: false },
  { label: 'Friday', value: 'Friday', checked: false },
  { label: 'Saturday', value: 'Saturday', checked: false },
  { label: 'Sunday', value: 'Sunday', checked: false }
];
let seasons = [
  { label: 'Spring', value: 'Spring', checked: false },
  { label: 'Summer', value: 'Summer', checked: false },
  { label: 'Monsoon', value: 'Monsoon', checked: false },
  { label: 'Winter', value: 'Winter', checked: false }
];
let special_day_list = [
  { label: 'Birth Day', value: 'birthday', checked: false },
  { label: 'Sign up day', value: 'signupday', checked: false }
];

const PromotionForm: React.FunctionComponent<IPromotionFormProps> = ({ promotionFormInitialValues, viewOnly }) => {
  const [promotionForm] = Form.useForm();
  const [specialPriceVolumeModalForm] = Form.useForm();
  const [percentPriceVolumeModalForm] = Form.useForm();
  const [fixedPriceVolumeModalForm] = Form.useForm();
  const [percentPriceSubTotalForm] = Form.useForm();
  const [fixedSubTotalForm] = Form.useForm();
  const [specialSubTotalForm] = Form.useForm();
  const [categoryEditForm] = Form.useForm();
  const [categoryEditVolumeForm] = Form.useForm();
  const [specialPriceEditBundleForm] = Form.useForm();
  const [percentageEditBundleForm] = Form.useForm();
  const [FixedEditBundleForm] = Form.useForm();

  // rewardType
  const [ecomOptions, setEcomOptions] = React.useState<any>([]);
  const [storeOptions, setStoreOptions] = React.useState<any>([]);
  const [promotionTypeList, setPromotionTypeList] = React.useState(promotionTypes);
  const [timeZoneList, setTimeZoneList] = React.useState(timeZones);
  const [currencyTypeList, setCurrencyTypeList] = React.useState(currencyList);
  const [promotionID, setpromotionID] = React.useState(null);
  const navigate = useNavigate();
  const [radioValue, setRadioValue] = React.useState(1);
  const [singleRadio, setSingleRadio] = React.useState(1);
  const [validTimeFrame, setValidTimeFrame] = React.useState(1);
  const { RangePicker } = TimePicker;
  const [categoryOptions, setCategoryOptions] = React.useState<any>([]);
  const [categoryProductOptions, setCategoryProductOptions] = React.useState<any>([]);
  const [activeKey, setActiveKey] = React.useState<string | string[] | undefined>(undefined);
  const [isCategoryDisabled, setIsCategoryDisabled] = React.useState<boolean>(false);
  const [countryListOptions, setCountryListOptions] = React.useState<any>([]);
  //Bundle States
  // discount_type === Special
  const [specialPriceData, setSpecialPriceData] = React.useState<any>({});
  const [specialPriceCategory, setSpecialPriceCategory] = React.useState<any>([]);
  const [specialPriceFilterData, setSpecialPriceFilterData] = React.useState<any>([]);
  // discount_type === Fixed
  const [fixedPriceData, setfixedPriceData] = React.useState<any>({});
  const [fixedPriceCategory, setFixedPriceCategory] = React.useState<any>([]);
  const [fixedPriceFilterData, setfixedPriceFilterData] = React.useState<any>({});
  // discount_type === Percent
  const [percentPriceData, setPercentPriceData] = React.useState<any>({});
  const [percentPriceCategory, setPercentPriceCategory] = React.useState<any>([]);
  const [percentPriceFilterData, setPercentPriceFilterData] = React.useState<any>({});
  //EditingFields - Bundle
  const [specialPriceEditRecord, setSpecialPriceEditRecord] = React.useState<any>(null);
  const [FixedPriceEditRecord, setFixedPriceEditRecord] = React.useState<any>(null);
  const [percentPriceEditRecord, setPercentPriceEditRecord] = React.useState<any>(null);
  //Volume States
  // discount_type === Special
  const [specialPriceVolumeData, setSpecialPriceVolumeData] = React.useState<any>([]);
  const [specialPriceVolumeCategory, setSpecialPriceVolumeCategory] = React.useState<any>([]);
  const [specialPriceVolumeFilterData, setSpecialPriceVolumeFilterData] = React.useState<any>([]);
  const [specialpriceVolumeModal, setSpecialPriceVolumeModal] = React.useState(false);
  // discount_type === Percent
  const [percentPriceVolumeData, setPercentPriceVolumeData] = React.useState<any>({});
  const [percentPriceVolumeCategory, setPercentPriceVolumeCategory] = React.useState<any>([]);
  const [percentPriceVolumeFilterData, setPercentPriceVolumeFilterData] = React.useState<any>([]);
  const [percentPriceVolumeModal, setPercentPriceVolumeModal] = React.useState(false);
  // discount_type === Fixed
  const [fixedPriceVolumeData, setFixedPriceVolumeData] = React.useState<any>({});
  const [fixedPriceVolumeCategory, setFixedPriceVolumeCategory] = React.useState<any>([]);
  const [fixedPriceVolumeFilterData, setFixedPriceVolumeFilterData] = React.useState<any>([]);
  const [fixedPriceVolumeModal, setFixedPriceVolumeModal] = React.useState(false);
  //BXGY States
  const [bxgyData, setBxgyData] = React.useState<any[]>([]);
  const [nextId, setNextId] = React.useState(1);
  //SubTotalDiscount States
  // discount_type === Special
  const [specialPriceSTData, setSpecialPriceSTData] = React.useState<any>({});
  const [specialPriceSTCategory, setSpecialPriceSTCategory] = React.useState<any>([]);
  const [specialPriceSTFilterData, setSpecialPriceSTFilterData] = React.useState<any>([]);
  // discount_type === Fixed
  const [fixedPriceSTData, setfixedPriceSTData] = React.useState<any>({});
  const [fixedPriceSTCategory, setFixedPriceSTCategory] = React.useState<any>([]);
  const [fixedPriceSTFilterData, setfixedPriceSTFilterData] = React.useState<any>({});
  // discount_type === Percent
  const [percentPriceSTData, setPercentPriceSTData] = React.useState<any>({});
  const [percentPriceSTCategory, setPercentPriceSTCategory] = React.useState<any>([]);
  const [percentPriceSTFilterData, setPercentPriceSTFilterData] = React.useState<any>({});
  //EditingFields - SubTotal
  const [specialPriceSTEditRecord, setSpecialPriceSTEditRecord] = React.useState<any>(null);
  const [FixedPriceSTEditRecord, setFixedPriceSTEditRecord] = React.useState<any>(null);
  const [percentPriceSTEditRecord, setPercentPriceSTEditRecord] = React.useState<any>(null);
  //Category States
  const [categoryData, setCategoryData] = React.useState<any>({});
  //modal ST
  const [stItemEditModal, setStItemEditModal] = React.useState(false);
  const [stPercentItemEditModal, setStPercentItemEditModal] = React.useState(false);
  const [stSpecialItemEditModal, setStSpecialItemEditModal] = React.useState(false);
  const [categoryEditModal, setCategoryEditModal] = React.useState(false);
  const [categoryEditModalVolume, setCategoryEditModalVolume] = React.useState(false);
  const [categoryEditModalBundle, setCategoryEditModalBundle] = React.useState(false);
  const [categoryEditPercentModalBundle, setCategoryPercentEditModalBundle] = React.useState(false);
  const [categoryEditFixedModalBundle, setCategoryEditFixedModalBundle] = React.useState(false);

  const isUpdate = !_.isEmpty(promotionFormInitialValues);

  const { id } = useParams();

  const [searchParams] = useSearchParams();

  const { baseCurrency, userCurrency } = useLocale(
    ({
      setUserCurrency,
      baseCurrency,
      userCurrency,
      orderNumber,
      incrementOrderNumber,
      promiseNumber,
      setPromiseNumber
    }) => ({
      setUserCurrency,
      baseCurrency,
      userCurrency,
      orderNumber,
      promiseNumber,
      incrementOrderNumber,
      setPromiseNumber
    })
  );

  React.useEffect(() => {
    loadCategoryOptions();
    loadCategoryProductOptions();
    loadCountryOptions();
    loadChannelData();
    if (!_.isEmpty(promotionFormInitialValues)) loadInitialApiValuesIntoForm();
    else {
      promotionForm.setFieldsValue({
        trigger: searchParams.get('promo_header_type'),
        promotionType: searchParams.get('promotion_type'),
        max_discount_item_count: 1,
        applicability: 'CART',
        onlyForNewUser: false,
        isAvailablePos: true,
        isAvailableOnline: true,
        chooseEcomType: 'All',
        chooseStoreType: 'All',
        productSelection_RC: 'ALL',
        status: 'OPEN',
        seller_Selection: 'ALL',
        thresholdSelection: 'NO_THRESHOLD',
        rewards_Data: [
          {
            product_reward: {
              product_info: [{}]
            }
          }
        ],
        for_generate: 'REGISTERED_USER',
        customerRestriction: 'ALL',
        filters: [{}]
      });

      //!Set InitialValues of the form
      if (promotionForm.getFieldsValue()?.promotionType === 'BUNDLE_DISC') {
        promotionForm.setFieldsValue({
          appliesTo: 'LINE_ITEMS',
          rewardType: 'BUNDLE_DISCOUNT',
          discount_type: 'PERCENTAGE'
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'SHIPPING_DISC') {
        promotionForm.setFieldsValue({
          appliesTo: 'SHIPPING',
          rewardType: 'DISCOUNT',
          threshold_selection: 'NO_THRESHOLD',
          discount_value: 1,
          max_discount: 1
          // shipping_discount: [{}]
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'VOLUME_DISC') {
        promotionForm.setFieldsValue({
          appliesTo: 'LINE_ITEMS',
          rewardType: 'VOLUME_DISCOUNT',
          rewards_Data: [
            {
              threshold_selection: 'QUANTITY',
              product_reward: {
                product_info: [{}]
              }
            }
          ],
          discount_type: 'PERCENTAGE',
          apply_discount_on: 'PRODUCTS'
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'BUNDLE_DISC') {
        promotionForm.setFieldsValue({
          appliesTo: 'LINE_ITEMS',
          rewardType: 'BUNDLE_DISCOUNT'
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'BXGY') {
        promotionForm.setFieldsValue({
          appliesTo: 'LINE_ITEMS',
          rewardType: 'PRODUCT',
          BXGY_buy: [{}],
          BXGY_get: [{}],
          applies_to_price: 'NONE',
          discount_type: 'PERCENTAGE',
          apply_discount_on: 'PRODUCTS'
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'LOYT_REWARD') {
        promotionForm.setFieldsValue({
          appliesTo: 'LOYT_POINTS',
          rewardType: 'POINTS'
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'SUB_TTL_DIS') {
        promotionForm.setFieldsValue({
          appliesTo: 'LINE_ITEMS',
          rewardType: 'DISCOUNT',
          discount_type: 'PERCENTAGE',
          apply_discount_on: 'PRODUCTS',
          threshold_selection: 'NO_THRESHOLD',
          subtotal_treshold: [{ max_limit_set: false }]
        });
      }

      triggerSelectionHandler(searchParams.get('promo_header_type') || 'PROMO');
    }
  }, [promotionFormInitialValues]);

  const [promotionFormValues, setPromotionFormValues] = React.useState({} as any);
  const { setLoading } = useLoader(({ setLoading }) => ({
    setLoading
  }));

  const { Panel } = Collapse;

  const SpecialPriceColumns = (
    specialPriceEditRecord: any,
    setSpecialPriceEditRecord: any,
    handleSave: any
  ): ColumnsType<any> => [
    {
      title: 'SKU',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.sku}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.productName}</>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Product Desc',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.desc}</>;
      }
    },
    {
      title: 'Unit Price Ref',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.unit_current_price}</>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.special_price}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => SpecialPriceEditBundleModal(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = specialPriceFilterData?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    const allData = specialPriceData?.data?.filter((product: any) => product?.sku !== record?.sku);
                    let combData = {
                      data: allData
                    };
                    setSpecialPriceFilterData(filteredProducts);
                    setSpecialPriceData(combData);
                    const categories = Array.from(new Set(allData?.map((item: any) => item.category)));
                    setSpecialPriceCategory(categories);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const SpecialPriceSTColumns = (
    specialPriceSTEditRecord: any,
    setSpecialPriceSTEditRecord: any,
    handleSave: any
  ): ColumnsType<any> => [
    {
      title: 'SKU',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.sku}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.productName}</>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Product Desc',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.desc}</>;
      }
    },
    {
      title: 'Unit Price Ref',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.unit_current_price}</>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.special_price}</>;
      }
    },
    {
      title: 'Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.minimum_qty || record?.quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => SpecialPriceEditModal(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = specialPriceSTFilterData?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    const allData = specialPriceSTData?.data?.filter((product: any) => product?.sku !== record?.sku);
                    let combData = {
                      data: allData
                    };
                    setSpecialPriceSTFilterData(filteredProducts);
                    setSpecialPriceSTData(combData);
                    const categories = Array.from(new Set(allData?.map((item: any) => item.category)));
                    setSpecialPriceSTCategory(categories);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const FixedPriceColumns = (
    FixedPriceEditRecord: any,
    setFixedPriceEditRecord: any,
    handleSave: any
  ): ColumnsType<any> => [
    {
      title: 'SKU',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.sku}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.productName}</>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Product Desc',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.desc}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.min_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.max_quantity}</>;
      }
    },
    // {
    //   title: 'Discount Value',
    //   align: 'center',
    //   render: (_: any, record: any) => {
    //     return <>{record?.discount_value}</>;
    //   }
    // },
    // {
    //   title: 'Maximum Discount Value',
    //   align: 'center',
    //   render: (_: any, record: any) => {
    //     return <>{record?.maximum_discount_value}</>;
    //   }
    // },
    {
      title: 'Unit Price Ref',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.unit_current_price}</>;
      }
    },
    {
      title: 'Price Ratio',
      align: 'center',
      render: (_: any, record: any) => {
        const calculateTotalPrice = (data: any) => {
          return data.reduce((total: number, item: any) => {
            const unitPrice = parseFloat(item.unit_current_price);
            const minQuantity = parseInt(item.min_quantity, 10);
            return total + unitPrice * minQuantity;
          }, 0);
        };
        const totalPrice = calculateTotalPrice(fixedPriceData.data);
        const percentPrice = (record.unit_current_price / totalPrice).toFixed(2);
        return <>{percentPrice}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => PercentPriceEditBundleModal(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = fixedPriceFilterData?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    const allData = fixedPriceData?.data?.filter((product: any) => product?.sku !== record?.sku);
                    let combData = {
                      data: allData
                    };
                    setPercentPriceFilterData(filteredProducts);
                    setfixedPriceData(combData);
                    const categories = Array.from(new Set(allData?.map((item: any) => item.category)));
                    setFixedPriceCategory(categories);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const FixedPriceColumnsST = (
    FixedPriceSTEditRecord: any,
    setFixedPriceSTEditRecord: any,
    handleSave: any
  ): ColumnsType<any> => [
    {
      title: 'SKU',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.sku}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.productName}</>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Product Desc',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.desc}</>;
      }
    },
    {
      title: 'Unit Price Ref',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.unit_current_price}</>;
      }
    },
    {
      title: 'Minium Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.min_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.max_quantity_item}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.percent_discount_value_item || record?.percent_discount_value}</>;
      }
    },
    {
      title: 'Maximum Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.max_discount_Value_item}</>;
      }
    },
    // {
    //   title: 'Price Ratio',
    //   align: 'center',
    //   render: (_: any, record: any) => {
    //     const calculateTotalPrice = (data: any) => {
    //       return data.reduce((total: number, item: any) => {
    //         const unitPrice = parseFloat(item.unit_current_price);
    //         const minQuantity = parseInt(item.min_quantity, 10);
    //         return total + unitPrice * minQuantity;
    //       }, 0);
    //     };
    //     const totalPrice = calculateTotalPrice(fixedPriceSTData.data);
    //     const percentPrice = (record.unit_current_price / totalPrice).toFixed(2);
    //     return <>{percentPrice}</>;
    //   }
    // },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => FixedEditModal(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = fixedPriceSTFilterData?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    const allData = fixedPriceSTData?.data?.filter((product: any) => product?.sku !== record?.sku);
                    let combData = {
                      data: allData
                    };
                    setfixedPriceSTFilterData(filteredProducts);
                    setfixedPriceSTData(combData);
                    const categories = Array.from(new Set(allData?.map((item: any) => item.category)));
                    setFixedPriceSTCategory(categories);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const PercentPriceColumns = (
    percentPriceEditRecord: any,
    setPercentPriceEditRecord: any,
    handleSave: any
  ): ColumnsType<any> => [
    {
      title: 'SKU',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.sku}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.productName}</>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Product Desc',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.desc}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.min_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        // This Has an issue somewhere ignoring now record?.max_discount
        return <>{record?.max_quantity || record?.max_discount}</>;
      }
    },
    // {
    //   title: 'Discount Value',
    //   align: 'center',
    //   render: (_: any, record: any) => {
    //     return <>{record?.discount_value}</>;
    //   }
    // },
    // {
    //   title: 'Maximum Discount Value',
    //   align: 'center',
    //   render: (_: any, record: any) => {
    //     return <>{record?.maximum_discount_value}</>;
    //   }
    // },
    {
      title: 'Unit Price Ref',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.unit_current_price}</>;
      }
    },
    {
      title: 'Price Ratio',
      align: 'center',
      render: (_: any, record: any) => {
        const calculateTotalPrice = (data: any) => {
          return data.reduce((total: number, item: any) => {
            const unitPrice = parseFloat(item.unit_current_price);
            const minQuantity = parseInt(item.min_quantity, 10);
            return total + unitPrice * minQuantity;
          }, 0);
        };
        const totalPrice = calculateTotalPrice(percentPriceData.data);
        const percentPrice = (record.unit_current_price / totalPrice).toFixed(2);
        return <>{percentPrice}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => FixedPriceEditBundleModal(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = percentPriceFilterData?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    const allData = percentPriceData?.data?.filter((product: any) => product?.sku !== record?.sku);
                    let combData = {
                      data: allData
                    };
                    setPercentPriceFilterData(filteredProducts);
                    setPercentPriceData(combData);
                    const categories = Array.from(new Set(allData?.map((item: any) => item.category)));
                    setPercentPriceCategory(categories);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const PercentPriceSTColumns = (
    percentPriceSTEditRecord: any,
    setPercentPriceSTEditRecord: any,
    handleSave: any
  ): ColumnsType<any> => [
    {
      title: 'SKU',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.sku}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.productName}</>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Product Desc',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.desc}</>;
      }
    },
    {
      title: 'Unit Price Ref',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.unit_current_price}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.min_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.max_quantity_item}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.percent_discount_value_item}</>;
      }
    },
    {
      title: 'Maximum Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.max_discount_Value_item}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => SubTotalEditModal(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = percentPriceSTFilterData?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    const allData = percentPriceSTData?.data?.filter((product: any) => product?.sku !== record?.sku);
                    let combData = {
                      data: allData
                    };
                    setPercentPriceSTFilterData(filteredProducts);
                    setPercentPriceSTData(combData);
                    const categories = Array.from(new Set(allData?.map((item: any) => item.category)));
                    setPercentPriceSTCategory(categories);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const SpecialPriceVolumeColumns: TableColumnsType<any> = [
    {
      title: 'SKU',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.sku}</div>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.productName}</div>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Product Desc',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.desc}</>;
      }
    },
    {
      title: 'Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.quantity}</>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.special_price}</>;
      }
    },
    {
      title: 'Unit Price Ref',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.unit_current_price}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => SpecialPriceVolumeModalEdit(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = specialPriceVolumeFilterData?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    const allData = specialPriceVolumeData?.data?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    let combData = {
                      data: allData
                    };
                    setSpecialPriceVolumeFilterData(filteredProducts);
                    setSpecialPriceVolumeData(combData);
                    const categories = Array.from(new Set(allData?.map((item: any) => item.category)));
                    setSpecialPriceVolumeCategory(categories);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const PercentPriceVolumeColumns: TableColumnsType<any> = [
    {
      title: 'SKU',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.sku}</div>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.productName}</div>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Product Desc',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.desc}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.min_quantity_item_vol || record?.min_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.max_quantity_item_vol || record?.max_quantity}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.discount_value_item_vol}</>;
      }
    },
    {
      title: 'Maximum Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        let data = record?.max_discount_item_vol || MAX_LIMIT;
        return <>{data}</>;
      }
    },
    {
      title: 'Unit Price Ref',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.unit_current_price}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => PercentPriceVolumeModalEdit(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = percentPriceVolumeFilterData?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    const allData = percentPriceVolumeData?.data?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    let combData = {
                      data: allData
                    };
                    setPercentPriceVolumeFilterData(filteredProducts);
                    setPercentPriceVolumeData(combData);
                    const categories = Array.from(new Set(allData?.map((item: any) => item.category)));
                    setPercentPriceVolumeCategory(categories);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const FixedPriceVolumeColumns: TableColumnsType<any> = [
    {
      title: 'SKU',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.sku}</div>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.productName}</>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Product Desc',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.desc}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.min_quantity_item_vol || record?.min_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.max_quantity_item_vol || record?.max_quantity}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.discount_value_item_vol}</>;
      }
    },
    {
      title: 'Unit Price Ref',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.unit_current_price}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => FixedPriceVolumeModalEdit(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = fixedPriceVolumeFilterData?.filter(
                      (product: any) => product?.sku !== record?.sku
                    );
                    const allData = fixedPriceVolumeData?.data?.filter((product: any) => product?.sku !== record?.sku);
                    let combData = {
                      data: allData
                    };
                    setFixedPriceVolumeFilterData(filteredProducts);
                    setFixedPriceVolumeData(combData);
                    const categories = Array.from(new Set(allData?.map((item: any) => item.category)));
                    setFixedPriceVolumeCategory(categories);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const ExpandVolumeRowColumnspecialPrice: TableColumnsType<any> = [
    {
      title: 'From Quantity (Threshold Minimum Value)',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.from_Quantity || record?.minimum_value}</div>;
      }
    },
    {
      title: 'To Quantity (Threshold Maximum Value)',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.to_Quantity || record?.maximum_value}</div>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.volume_value || record?.discount_value}</div>;
      }
    }
  ];

  const ExpandVolumeRowColumnpercentPrice: TableColumnsType<any> = [
    {
      title: 'From Quantity (Threshold Minimum Value)',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.from_Quantity || record?.minimum_value}</div>;
      }
    },
    {
      title: 'To Quantity (Threshold Maximum Value)',
      align: 'center',
      render(value, record, index) {
        let data = record?.max_limit_set ? MAX_LIMIT : record?.to_Quantity || record?.maximum_value;
        return <div>{data}</div>;
      }
    },
    {
      title: 'Discount',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.volume_value || record?.discount_value}</div>;
      }
    }
  ];

  const ExpandVolumeRowColumnfixedPrice: TableColumnsType<any> = [
    {
      title: 'From Quantity (Threshold Minimum Value)',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.from_Quantity || record?.minimum_value}</div>;
      }
    },
    {
      title: 'To Quantity (Threshold Maximum Value)',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.to_Quantity || record?.maximum_value}</div>;
      }
    },
    {
      title: 'Discount',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.volume_value || record?.discount_value}</div>;
      }
    }
  ];

  const BXGY_Special_Price_Columns: TableColumnsType<any> = [
    {
      title: 'Selected Item',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.selected_item || record?.item_id}</div>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.category}</div>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.product_Name}</div>;
      }
    },
    {
      title: 'Quantity',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.item_quantity}</div>;
      }
    },
    {
      title: 'Unit Price',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.unit_current_price}</div>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.special_price_BXGY_buy || record?.discount_value}</div>;
      }
    }
  ];

  const BXGY_Percentage_Price_Columns: TableColumnsType<any> = [
    {
      title: 'Selected Item',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.selected_item || record?.item_id}</div>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.category}</div>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.product_Name}</div>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.minimum_Quantity_BXGY_buy || record?.minimum_quantity}</div>;
      }
    },
    {
      title: 'Unit Price',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.unit_current_price}</div>;
      }
    }
  ];

  const BXGY_Fixed_Price_Columns: TableColumnsType<any> = [
    {
      title: 'Selected Item',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.selected_item || record?.item_id}</div>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.category}</div>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.product_Name}</div>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.minimum_Quantity_BXGY_buy || record?.minimum_quantity}</div>;
      }
    },
    {
      title: 'Unit Price',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.unit_current_price}</div>;
      }
    }
  ];

  const BXGYBuyCols: TableColumnsType<any> = [
    {
      title: 'Selected Item',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.selected_item || record?.item_id}</div>;
      }
    },
    {
      title: 'Category',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.category}</div>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.product_Name}</div>;
      }
    },
    {
      title: 'Quantity',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.item_quantity || record?.minimum_quantity}</div>;
      }
    },
    {
      title: 'Unit Price',
      align: 'center',
      render(value, record, index) {
        return <div>{record?.unit_current_price}</div>;
      }
    }
  ];

  const categoryColumns: TableColumnsType<any> = [
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.discount_value || record?.percent_discount_value}</>;
      }
    },
    {
      title: 'Maximum Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.max_discount_Value_item || record?.max_discount}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => CategoryModalOfSTD(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = categoryData?.data?.filter((product: any) => {
                      return !(
                        product?.category === record?.category &&
                        product?.max_discount_Value_item === record?.max_discount_Value_item &&
                        product?.maximum_quantity === record?.maximum_quantity &&
                        product?.minimum_quantity === record?.minimum_quantity
                      );
                    });

                    let combData = {
                      data: filteredProducts
                    };

                    setCategoryData(combData);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const volumeCategoryColumns: TableColumnsType<any> = [
    {
      title: 'Category',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Maximum Discount Value',
      align: 'center',
      render: (_: any, record: any) => {
        let data = record?.max_discount || MAX_LIMIT;
        return <>{data}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <>
            {!viewOnly && (
              <section className="flex justify-center">
                <ActionButton
                  action="CREATE_NEW_VERSION"
                  title="Edit"
                  onClick={() => CategoryModalOfVolume(record)}
                ></ActionButton>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    const filteredProducts = categoryData?.data?.filter((product: any) => {
                      return !(
                        product?.category === record?.category &&
                        product?.max_discount_Value_item === record?.max_discount_Value_item &&
                        product?.maximum_quantity === record?.maximum_quantity &&
                        product?.minimum_quantity === record?.minimum_quantity
                      );
                    });

                    let combData = {
                      data: filteredProducts
                    };

                    setCategoryData(combData);
                    displaySuccessNotification({ message: 'Item Removed' });
                  }}
                >
                  <ActionButton action="DELETE" title={''} />
                </Popconfirm>
              </section>
            )}
          </>
        );
      }
    }
  ];

  const getTotalSpecialPrice = () => {
    const totalSpecialPrice = (specialPriceData?.data || [])?.reduce((acc: number, item: any) => {
      return acc + parseFloat(item.special_price);
    }, 0);
    let currConvertor = priceHelpers.formatPriceToLocaleCurrency({
      amount: totalSpecialPrice,
      baseCurrency,
      userCurrency
    });
    return currConvertor;
  };

  const getTotalSpecialPriceforST = () => {
    const totalSpecialPrice = (specialPriceSTData?.data || [])?.reduce((acc: number, item: any) => {
      return acc + parseFloat(item.special_price);
    }, 0);
    let currConvertor = priceHelpers.formatPriceToLocaleCurrency({
      amount: totalSpecialPrice,
      baseCurrency,
      userCurrency
    });
    return currConvertor;
  };

  const loadCategoryOptions = async () => {
    setLoading(true);
    const { data, errors } = await AnalyticsService.getCategoryData();
    if (_.isEmpty(errors)) {
      const outputArray = data?.value?.map((item: any) => ({ label: item.replaceAll('_', ' '), value: item }));
      setCategoryOptions(outputArray);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const loadChannelData = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getAllChannelList();
    if (_.isEmpty(errors)) {
      let EcomOptions: any = [];
      let StoreOptions: any = [];
      (data?.data || []).filter((item: any) => {
        if (item.channel_type === 'DOTCOM') {
          let obj = { label: item?.channel_name, value: item?.channel_name };
          EcomOptions.push(obj);
          return obj;
        } else if (item.channel_type === 'STORE') {
          let obj = { label: item?.channel_name, value: item?.channel_name };
          StoreOptions.push(obj);
          return obj;
        }
        return;
      });
      setEcomOptions(EcomOptions);
      setStoreOptions(StoreOptions);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const loadCountryOptions = () => {
    // const url = `${window.location.host}`.replace('www.', '');
    // const urlParts = url.split('.');
    // const tenantIndex = parseInt(TENANT_DOMAIN_INDEX as string);
    // const tenantId = urlParts[tenantIndex];
    // if (tenantId === 'sparta') {
    //   let spartaList = [{ label: '🇨🇱 Chile', value: 'Chile' }];
    //   let spartaListTimeZone = [
    //     { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile' },
    //     { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' }
    //   ];
    //   let spartaCurrency = [{ label: '🇨🇱 CLP', value: 'CLP', country: 'Chile' }];
    //   setCountryListOptions(spartaList);
    //   setTimeZoneList(spartaListTimeZone);
    //   setCurrencyTypeList(spartaCurrency);
    //   promotionForm.setFieldsValue({ country: 'Chile' });
    // } else {
    //   setCountryListOptions(countryList);
    //   setTimeZoneList(timeZones);
    //   setCurrencyTypeList(currencyList);
    // }

    setCountryListOptions(countryList);
    setTimeZoneList(timeZones);
    setCurrencyTypeList(currencyList);
  };

  const loadCategoryProductOptions = async () => {
    setLoading(true);
    const { data, errors } = await AnalyticsService.getCategoryProductData();
    if (_.isEmpty(errors)) {
      const formattedData = (data || [])?.map((item: any) => ({
        label: item,
        value: item
      }));
      setCategoryProductOptions(formattedData);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const setObjRewardDataBXGY = (obj: any) => {
    if (obj.discount_type === 'PERCENTAGE') {
      promotionForm.setFieldsValue({
        discount_type: obj.discount_type,
        percent_discount_value: obj.discount_value,
        max_discount: obj.max_discount || MAX_LIMIT,
        apply_discount_on: obj.apply_discount_on,
        min_line_items: obj?.apply_discount_on === 'CATEGORY' ? obj?.product_reward?.product_info[0]?.min_line_items : 1
      });
      const allConditions = obj?.product_reward?.product_info
        ?.filter((info: any) => info.selection_type === 'REWARD_ON')
        .flatMap((info: any) => info.conditions);
      let combData = {
        data: allConditions
      };
      setCategoryData(combData);
    }
    if (obj.discount_type === 'FIXED') {
      promotionForm.setFieldsValue({
        discount_type: obj.discount_type,
        percent_discount_value: obj.discount_value,
        max_discount: obj.max_discount || MAX_LIMIT,
        apply_discount_on: obj.apply_discount_on,
        min_line_items: obj?.apply_discount_on === 'CATEGORY' ? obj?.product_reward?.product_info[0]?.min_line_items : 1
      });
      const allConditions = obj?.product_reward?.product_info
        ?.filter((info: any) => info.selection_type === 'REWARD_ON')
        .flatMap((info: any) => info.conditions);
      let combData = {
        data: allConditions
      };
      setCategoryData(combData);
    }
    if (obj.discount_type === 'SPL_PRICE') {
      promotionForm.setFieldsValue({
        discount_type: obj.discount_type,
        percent_discount_value: obj.discount_value,
        max_discount: obj.max_discount || MAX_LIMIT,
        apply_discount_on: obj.apply_discount_on,
        min_line_items: obj?.apply_discount_on === 'CATEGORY' ? obj?.product_reward?.product_info[0]?.min_line_items : 1
      });
      const allConditions = obj?.product_reward?.product_info
        ?.filter((info: any) => info.selection_type === 'REWARD_ON')
        .flatMap((info: any) => info.conditions);
      let combData = {
        data: allConditions
      };
      setCategoryData(combData);
    }
  };

  const setObjRewardData = (obj: any) => {
    // let isOrderTotal = appliesTo === 'ORDER_TOTAL';
    if (obj.discount_type === 'PERCENTAGE') {
      promotionForm.setFieldsValue({
        discount_type: obj.discount_type,
        percent_discount_value_item: obj.discount_value,
        percent_discount_value: obj.discount_value,
        max_discount: obj.max_discount || MAX_LIMIT,
        max_discount_Value_item: obj.max_discount,
        apply_discount_on: obj.apply_discount_on,
        min_line_items: obj?.product_reward?.product_info[0]?.min_line_items,
        threshold_selection: obj?.threshold_selection
      });
      const allConditions = obj?.product_reward?.product_info?.flatMap((info: any) => info.conditions);
      const updatedConditions = allConditions?.map((condition: any, index: number) => ({
        ...condition,
        item_id: index + 1 // Set item_id as a number
      }));
      let combData = {
        data: updatedConditions
      };
      setCategoryData(combData);
    }
    if (obj.discount_type === 'FIXED') {
      promotionForm.setFieldsValue({
        discount_type: obj.discount_type,
        percent_discount_value: obj.discount_value,
        max_discount: obj.max_discount || MAX_LIMIT,
        max_discount_Value_item: obj.max_discount,
        apply_discount_on: obj.apply_discount_on,
        min_line_items: obj?.product_reward?.product_info[0]?.min_line_items,
        threshold_selection: obj?.threshold_selection
      });
      const allConditions = obj?.product_reward?.product_info?.flatMap((info: any) => info.conditions);
      const updatedConditions = allConditions?.map((condition: any, index: number) => ({
        ...condition,
        item_id: index + 1 // Set item_id as a number
      }));
      let combData = {
        data: updatedConditions
      };
      setCategoryData(combData);
    }
    if (obj.discount_type === 'SPL_PRICE') {
      promotionForm.setFieldsValue({
        discount_type: obj.discount_type,
        percent_discount_value: obj.discount_value,
        max_discount: obj.max_discount || MAX_LIMIT,
        max_discount_Value_item: obj.max_discount || 0,
        apply_discount_on: obj.apply_discount_on,
        min_line_items: obj?.product_reward?.product_info[0]?.min_line_items,
        threshold_selection: obj?.threshold_selection
      });
      const allConditions = obj?.product_reward?.product_info?.flatMap((info: any) => info.conditions);
      let combData = {
        data: allConditions
      };
      setCategoryData(combData);
    }
  };

  const getRewardData = (data: any, intialValues: any) => {
    const { promotion_type, application_type } = intialValues;
    data.forEach((obj: any) => {
      if (obj.cap_value > 0) {
        obj.isRange = true;
      }
      if (obj.max_discount >= 9999999) {
        obj.no_limit = true;
      } else {
        obj.no_limit = false;
      }
      if (promotion_type === 'SUB_TTL_DIS') {
        setObjRewardData(obj);
        if (application_type === 'ORDER_TOTAL') {
          promotionForm.setFieldsValue({
            subtotal_treshold: obj?.thresholds?.threshold_list
          });
        }
      }
      if (promotion_type === 'VOLUME_DISC') {
        setObjRewardData(obj);
      }
      if (promotion_type === 'BXGY') {
        setObjRewardDataBXGY(obj);
      }
      if (promotion_type === 'BUNDLE_DISC') {
        setObjRewardDataBXGY(obj);
      }
    });
    return data || [];
  };

  const getBundlespecialPriceIttems = async (conditionData: any) => {
    let paramData = conditionData?.map((item: any) => item?.item_id);

    const getSPPrice = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.discount_value;
    };

    const getMQ = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.minimum_quantity;
    };

    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      let tableData = data?.data?.map((item: any) => {
        let obj = {
          category: item.category,
          desc: item.description,
          productName: item.product_name,
          sku: item.item_id,
          special_price: getSPPrice(item.item_id),
          quantity: getMQ(item.item_id),
          unit_current_price: item.unit_price
        };
        return obj;
      });
      let allCatData = tableData?.map((data: any) => data.category);
      let uniqueCategoryData = allCatData.filter((item: any, index: any) => allCatData.indexOf(item) === index);
      let combData = {
        data: tableData
      };
      setSpecialPriceCategory(uniqueCategoryData);
      setSpecialPriceData(combData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getBundlespecialPriceIttemsST = async (conditionData: any) => {
    let paramData = conditionData?.map((item: any) => item?.item_id);

    const getSPPrice = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.discount_value;
    };

    const getMQ = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.minimum_quantity;
    };

    const getSubtotal_treshold = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.thresholds;
    };

    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      let tableData = data?.data?.map((item: any) => {
        let obj = {
          category: item.category,
          desc: item.description,
          productName: item.product_name,
          sku: item.item_id,
          special_price: getSPPrice(item.item_id),
          quantity: getMQ(item.item_id),
          unit_current_price: item.unit_price,
          subtotal_treshold: getSubtotal_treshold(item.item_id)
        };
        return obj;
      });
      let allCatData = tableData?.map((data: any) => data.category);
      let uniqueCategoryData = allCatData.filter((item: any, index: any) => allCatData.indexOf(item) === index);
      let combData = {
        data: tableData
      };
      setSpecialPriceSTCategory(uniqueCategoryData);
      setSpecialPriceSTData(combData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getBundlePrecentPriceItems = async (conditionData: any) => {
    let paramData = conditionData?.map((item: any) => item?.item_id);

    const getpercentPrice = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.discount_value;
    };

    const getpercentmaxdis = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.maximum_quantity;
    };

    const getpercentminQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.minimum_quantity;
    };
    const getAbsolutePrecentDiscountQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.discount_value;
    };

    const getAbsolutePrecentMaxDiscountVal = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.max_discount;
    };

    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      let tableData = data?.data?.map((item: any) => {
        let obj = {
          sku: item.item_id,
          productName: item.product_name,
          category: item.category,
          desc: item.description,
          unit_current_price: item.unit_price || 0,
          percent_discount_value: getpercentPrice(item.item_id),
          max_discount: getpercentmaxdis(item.item_id),
          min_quantity: getpercentminQty(item.item_id),
          discount_value: getAbsolutePrecentDiscountQty(item.item_id),
          maximum_discount_value: getAbsolutePrecentMaxDiscountVal(item.item_id)
        };
        return obj;
      });
      let allCatData = tableData?.map((data: any) => data.category);
      let uniqueCategoryData = allCatData.filter((item: any, index: any) => allCatData.indexOf(item) === index);
      let combData = {
        data: tableData
      };
      setPercentPriceCategory(uniqueCategoryData);
      setPercentPriceData(combData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getBundlePrecentPriceItemsST = async (conditionData: any) => {
    let paramData = conditionData?.map((item: any) => item?.item_id);
    const getpercentPrice = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.discount_value;
    };

    const getpercentmaxdis = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.max_discount;
    };

    const getpercentminQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.minimum_quantity;
    };

    const getSubtotal_treshold = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.thresholds;
    };

    const getMaxQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.maximum_quantity;
    };

    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      let tableData = data?.data?.map((item: any) => {
        let obj = {
          sku: item.item_id,
          productName: item.product_name,
          category: item.category,
          desc: item.description,
          unit_current_price: item.unit_price || 0,
          percent_discount_value_item: getpercentPrice(item.item_id),
          max_quantity_item: getMaxQty(item.item_id),
          max_discount_Value_item: getpercentmaxdis(item.item_id),
          min_quantity: getpercentminQty(item.item_id),
          subtotal_treshold: getSubtotal_treshold(item.item_id)
        };
        return obj;
      });
      let allCatData = tableData?.map((data: any) => data.category);
      let uniqueCategoryData = allCatData.filter((item: any, index: any) => allCatData.indexOf(item) === index);
      let combData = {
        data: tableData
      };
      setPercentPriceSTCategory(uniqueCategoryData);
      setPercentPriceSTData(combData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getBundleAbsolutePriceItems = async (conditionData: any) => {
    let paramData = conditionData?.map((item: any) => item?.item_id);
    const getAbsolutePrecentDiscount = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.discount_value;
    };
    const getAbsolutePrecentDiscountQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.discount_value;
    };
    const getAbsolutePrecentMinQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.minimum_quantity;
    };
    const getAbsolutePrecentMaxQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.maximum_quantity;
    };
    const getAbsolutePrecentMaxDiscountVal = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.max_discount;
    };
    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      let tableData = data?.data?.map((item: any) => {
        let obj = {
          sku: item.item_id,
          productName: item.product_name,
          category: item.category,
          desc: item.description,
          unit_current_price: item.unit_price,
          percent_discount_value: getAbsolutePrecentDiscount(item.item_id),
          min_quantity: getAbsolutePrecentMinQty(item.item_id),
          max_quantity: getAbsolutePrecentMaxQty(item.item_id),
          discount_value: getAbsolutePrecentDiscountQty(item.item_id),
          maximum_discount_value: getAbsolutePrecentMaxDiscountVal(item.item_id)
        };
        return obj;
      });
      let allCatData = tableData?.map((data: any) => data.category);
      let uniqueCategoryData = allCatData.filter((item: any, index: any) => allCatData.indexOf(item) === index);
      let combData = {
        data: tableData
      };
      setFixedPriceCategory(uniqueCategoryData);
      setfixedPriceData(combData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getBundleAbsolutePriceItemsST = async (conditionData: any) => {
    let paramData = conditionData?.map((item: any) => item?.item_id);
    const getAbsolutePrecentDiscount = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.discount_value;
    };
    const getAbsolutePrecentDiscountQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.minimum_quantity;
    };
    const getSubtotal_treshold = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.thresholds;
    };
    const getmax_quantity_item = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.maximum_quantity;
    };
    const get_max_discount_Value_item = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.max_discount;
    };
    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      let tableData = data?.data?.map((item: any) => {
        let obj = {
          sku: item.item_id,
          productName: item.product_name,
          category: item.category,
          desc: item.description,
          unit_current_price: item.unit_price,
          percent_discount_value: getAbsolutePrecentDiscount(item.item_id),
          min_quantity: getAbsolutePrecentDiscountQty(item.item_id),
          max_quantity_item: getmax_quantity_item(item.item_id),
          subtotal_treshold: getSubtotal_treshold(item.item_id),
          max_discount_Value_item: get_max_discount_Value_item(item.item_id)
        };
        return obj;
      });
      let allCatData = tableData?.map((data: any) => data.category);
      let uniqueCategoryData = allCatData.filter((item: any, index: any) => allCatData.indexOf(item) === index);
      let combData = {
        data: tableData
      };
      setFixedPriceSTCategory(uniqueCategoryData);
      setfixedPriceSTData(combData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getVolumeSpecialPriceItems = async (conditionData: any) => {
    let paramData = conditionData?.map((item: any) => item?.item_id);

    const getVolumeDiscountSpecialPrice = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      let renderChildData = foundItem?.thresholds?.map((item: any) => {
        let childData = {
          from_Quantity: item?.minimum_value || MAX_LIMIT,
          to_Quantity: item?.maximum_value || MAX_LIMIT,
          discount_value: item?.discount_value
        };
        return childData;
      });
      return renderChildData;
    };

    const getVolumeMinQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.minimum_quantity;
    };

    const getVolumeSpecialPriceItems = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.thresholds.length >= 1 ? null : foundItem.discount_value;
    };

    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      let tableData = data?.data?.map((item: any) => {
        let obj = {
          sku: item.item_id,
          productName: item.product_name,
          category: item.category,
          desc: item.description,
          unit_current_price: item.unit_price,
          volume_Threshold_qty: getVolumeDiscountSpecialPrice(item.item_id),
          minimum_qty: getVolumeMinQty(item.item_id),
          special_price: getVolumeSpecialPriceItems(item.item_id)
        };
        return obj;
      });
      let allCatData = tableData?.map((data: any) => data.category);
      let uniqueCategoryData = allCatData.filter((item: any, index: any) => allCatData.indexOf(item) === index);
      let combData = {
        data: tableData
      };
      const hasMultipleVolumeThresholds = combData.data.some(
        (item: any) => item.volume_Threshold_qty && item.volume_Threshold_qty.length > 1
      );
      if (!hasMultipleVolumeThresholds) {
        promotionForm.setFieldsValue({
          isRange: true,
          volume_Threshold_qty: [{}]
        });
      }
      setSpecialPriceVolumeCategory(uniqueCategoryData);
      setSpecialPriceVolumeData(combData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getVolumePercentPriceItems = async (conditionData: any) => {
    let paramData = conditionData?.map((item: any) => item?.item_id);
    const getPercentValue = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.discount_value;
    };

    const getVolumeDiscountPercentPrice = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      let renderChildData = foundItem?.thresholds?.map((item: any) => {
        let childData = {
          from_Quantity: item?.minimum_value || MAX_LIMIT,
          to_Quantity: item?.maximum_value || MAX_LIMIT,
          discount_value: item?.discount_value
        };
        return childData;
      });
      if (renderChildData?.length >= 1) {
        promotionForm.setFieldsValue({
          isRange: true,
          volume_Threshold_qty: [{}]
        });
      }
      return renderChildData;
    };

    const getPercentMinQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.minimum_quantity;
    };

    const getPercentMaxQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.maximum_quantity;
    };

    const getMaxOfItem = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.max_discount;
    };

    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      let tableData = data?.data?.map((item: any) => {
        let obj = {
          sku: item.item_id,
          productName: item.product_name,
          category: item.category,
          desc: item.description,
          unit_current_price: item.unit_price,
          min_quantity: getPercentMinQty(item.item_id),
          max_quantity_item_vol: getPercentMaxQty(item.item_id),
          discount_value_item_vol: getPercentValue(item.item_id),
          percent_discount_value: getPercentValue(item.item_id),
          volume_Threshold_qty: getVolumeDiscountPercentPrice(item.item_id),
          max_discount_item_vol: getMaxOfItem(item.item_id)
        };
        return obj;
      });
      let allCatData = tableData?.map((data: any) => data.category);
      let uniqueCategoryData = allCatData.filter((item: any, index: any) => allCatData.indexOf(item) === index);
      let combData = {
        data: tableData
      };
      setPercentPriceVolumeCategory(uniqueCategoryData);
      setPercentPriceVolumeData(combData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getVolumeFixedPriceItems = async (conditionData: any) => {
    let paramData = conditionData?.map((item: any) => item?.item_id);
    const getAbsolutePrecentDiscount = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem.minimum_quantity;
    };
    const getAbsolutePrecentDiscountQty = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem?.minimum_quantity;
    };
    const getAbsolutePrecentDiscountQtyMax = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem?.maximum_quantity;
    };
    const getByDisplayValue = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      return foundItem?.discount_value;
    };
    const getVolumeDiscountPercentPrice = (itemId: any) => {
      const foundItem = conditionData?.find((item: any) => item.item_id === itemId);
      let renderChildData = foundItem?.thresholds?.map((item: any) => {
        let childData = {
          from_Quantity: item?.minimum_value || MAX_LIMIT,
          to_Quantity: item?.maximum_value || MAX_LIMIT,
          discount_value: item?.discount_value
        };
        return childData;
      });
      return renderChildData;
    };
    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      let tableData = data?.data?.map((item: any) => {
        let obj = {
          sku: item.item_id,
          productName: item.product_name,
          category: item.category,
          desc: item.description,
          unit_current_price: item.unit_price,
          percent_discount_value: getAbsolutePrecentDiscount(item.item_id),
          min_quantity: getAbsolutePrecentDiscountQty(item.item_id),
          max_quantity: getAbsolutePrecentDiscountQtyMax(item.item_id),
          discount_value_item_vol: getByDisplayValue(item.item_id),
          volume_Threshold_qty: getVolumeDiscountPercentPrice(item.item_id)
        };
        return obj;
      });
      let allCatData = tableData?.map((data: any) => data.category);
      let uniqueCategoryData = allCatData.filter((item: any, index: any) => allCatData.indexOf(item) === index);
      let combData = {
        data: tableData
      };
      setFixedPriceVolumeCategory(uniqueCategoryData);
      setFixedPriceVolumeData(combData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const BXGYApiCall = (buyItems: any, getItems: any, respData: any) => {
    const getName = (itemId: any) => {
      const foundItem = respData?.find((item: any) => item.item_id === itemId);
      return foundItem.product_name;
    };

    const getMRP = (itemId: any) => {
      const foundItem = respData?.find((item: any) => item.item_id === itemId);
      return foundItem.unit_price;
    };

    let newBuyItems = buyItems?.map((item: any) => {
      let obj = {
        selected_item: item?.item_id || '',
        category: item?.category || '',
        minimum_Quantity_BXGY_buy: item?.minimum_quantity || '',
        maximum_Quantity_BXGY_buy: item?.maximum_quantity || '',
        product_Name: getName(item?.item_id) || '',
        unit_current_price: getMRP(item?.item_id) || '',

        item_quantity: item?.minimum_quantity
      };
      return obj;
    });

    let newGetItems = getItems?.map((item: any) => {
      let obj = {
        selected_item: item?.item_id || '',
        category: item?.category || '',
        minimum_Quantity_BXGY_buy: item?.minimum_quantity || '',
        maximum_Quantity_BXGY_buy: item?.maximum_quantity || '',
        product_Name: getName(item?.item_id) || '',
        unit_current_price: getMRP(item?.item_id) || '',
        special_price_BXGY_buy: item?.discount_value || '',
        percent_discount_value: item?.discount_value || '',
        item_quantity: item?.minimum_quantity
      };
      return obj;
    });

    const newEntry = {
      id: nextId,
      buyData: newGetItems,
      getData: newBuyItems
    };
    setBxgyData([newEntry]);
    setNextId(prevId => prevId + 1);
  };

  const getBXGYData = async (conditionData: any) => {
    let buyItems: any = [];
    let getItems: any = [];

    conditionData.forEach((item: any) => {
      if (item.selection_type === 'REWARD_ON') {
        buyItems = item.conditions;
      } else if (item.selection_type === 'REWARDED') {
        getItems = item.conditions || [];
      }
    });
    let allBuy = buyItems?.map((item: any) => item.item_id);
    let allGet = getItems?.map((item: any) => item.item_id);

    let paramData = [...allBuy, ...allGet];
    let respData: any = [];
    const { data, errors } = await promotionService.getAllProductDetails(paramData);
    if (_.isEmpty(errors)) {
      respData = [...data.data];
      BXGYApiCall(buyItems, getItems, respData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getNewRewardData = () => {
    const { promotion_type, discount } = promotionFormInitialValues;
    if (promotion_type === 'BUNDLE_DISC' && discount[0]?.discount_type === 'SPL_PRICE') {
      getBundlespecialPriceIttems(discount[0]?.product_reward?.product_info[0]?.conditions);
    } else if (promotion_type === 'BUNDLE_DISC' && discount[0]?.discount_type === 'PERCENTAGE') {
      getBundlePrecentPriceItems(discount[0]?.product_reward?.product_info[0]?.conditions);
      let headerData = discount[0];
      promotionForm.setFieldsValue({
        discount_value: headerData?.discount_value,
        maximum_discount_value: headerData?.max_discount
      });
    } else if (promotion_type === 'BUNDLE_DISC' && discount[0]?.discount_type === 'FIXED') {
      getBundleAbsolutePriceItems(discount[0]?.product_reward?.product_info[0]?.conditions);
      let headerData = discount[0];
      promotionForm.setFieldsValue({
        discount_value: headerData?.discount_value,
        maximum_discount_value: headerData?.max_discount
      });
    } else if (promotion_type === 'VOLUME_DISC' && discount[0]?.discount_type === 'SPL_PRICE') {
      getVolumeSpecialPriceItems(discount[0]?.product_reward?.product_info[0]?.conditions);
    } else if (promotion_type === 'VOLUME_DISC' && discount[0]?.discount_type === 'PERCENTAGE') {
      getVolumePercentPriceItems(discount[0]?.product_reward?.product_info[0]?.conditions);
    } else if (promotion_type === 'VOLUME_DISC' && discount[0]?.discount_type === 'FIXED') {
      getVolumeFixedPriceItems(discount[0]?.product_reward?.product_info[0]?.conditions);
    } else if (promotion_type === 'BXGY') {
      getBXGYData(discount[0]?.product_reward?.product_info);
    } else if (promotion_type === 'SUB_TTL_DIS' && discount[0]?.discount_type === 'SPL_PRICE') {
      getBundlespecialPriceIttemsST(discount[0]?.product_reward?.product_info[0]?.conditions);
    } else if (promotion_type === 'SUB_TTL_DIS' && discount[0]?.discount_type === 'PERCENTAGE') {
      getBundlePrecentPriceItemsST(discount[0]?.product_reward?.product_info[0]?.conditions);
    } else if (promotion_type === 'SUB_TTL_DIS' && discount[0]?.discount_type === 'FIXED') {
      getBundleAbsolutePriceItemsST(discount[0]?.product_reward?.product_info[0]?.conditions);
    } else if (promotion_type === 'SHIPPING_DISC') {
      const { discount_type, threshold_selection, discount_value, max_discount, thresholds } = discount[0];
      let getThreshold =
        thresholds?.threshold_list?.map((item: any) => ({
          from_Quantity: item.minimum_value,
          to_Quantity: item.max_limit_set ? undefined : item.maximum_value, // Hide 'to_Quantity' if 'max_limit_set' is true
          discount_Value: item.discount_value,
          max_limit_set: item.max_limit_set
        })) || null;
      promotionForm.setFieldsValue({
        discount_type: discount_type,
        threshold_selection: threshold_selection,
        discount_value: discount_value,
        max_discount: max_discount,
        shipping_discount: getThreshold // Set the mapped values here
      });
    }
  };

  const getDiscountType = () => {
    const { discount } = promotionFormInitialValues;
    return discount[0]?.discount_type || '';
  };

  const getAppliesToPrice = () => {
    const { discount } = promotionFormInitialValues;
    return discount[0]?.applies_to_price || '';
  };

  const loadInitialApiValuesIntoForm = () => {
    setLoading(true);
    const {
      title,
      status,
      application_type,
      trigger,
      promotion_type,
      criterias,
      timezone,
      discount: {
        discount_type,
        eligible_for,
        minimum_value,
        cap_value,
        applies_to_price,
        type,
        percentage,
        product_reward,
        max_discount_item_count
      },
      visible_on_popup,
      priority,
      buyer_selection,
      product_selection,
      products,
      is_pos,
      is_online,
      pos_list,
      online_list,
      max_usage,
      limit_per_user,
      new_buyer,
      seller_selection,
      sellers,
      application,
      reward_type,
      reward_name,
      reward_value,

      visibility,
      tier_restriction,
      buyers,
      code_type,
      code,
      coupon_quantity_to_generate,
      start_date,
      end_date,
      start_time,
      end_time,
      transaction_apply_count,
      valid_on_given_timeframe,
      schedule,
      applies_to_product_in_sale,
      ignore_price_lock,
      combine_with_other_promo,
      combine_promo_ids,
      currency,
      next_order_applicable,
      no_of_next_orders
    } = promotionFormInitialValues as any;

    let use_max_limit = false;
    if (max_usage >= MAX_LIMIT) {
      use_max_limit = true;
    }

    if (promotion_type === 'BXGY') {
      promotionForm.setFieldsValue({
        BXGY_buy: [{}],
        BXGY_get: [{}]
      });
    }

    const { daily, daily_info, monthly, monthly_info, seasonal, seasonal_info, weekly, weekly_info } =
      schedule || ({} as any);
    const { from_date, to_date, from_time, to_time } = daily_info || ({} as any);
    const countryValue: string = extractCountryValue(criterias);
    const appliesPerTransaction = transaction_apply_count === 99999 ? 'UNLIMITED' : 'COUNT';
    const appliesPerTransaction_Count = transaction_apply_count;
    const validTimeFrame = valid_on_given_timeframe ? 1 : 2;

    const { reward_on_product, rewarded_product } = product_reward || ({} as any);
    const selectedItem = reward_on_product?.ids || [];
    const rewardItem = rewarded_product?.ids || [];

    const itemQuantity = reward_on_product?.quantity || 0;
    const qualityReward = rewarded_product?.quantity || 0;
    const rfmCategories = RFMextractValuesFromCriteras(criterias, 'RFM_CATEGORIES');
    const customerSegment = extractValuesFromCriteras(criterias, 'CUSTOMER_SEGMENT');
    const customerTire = extractValuesFromCriteras(criterias, 'CUSTOMER_TIER');
    const brandCrits = extractValuesFromCriteras(criterias, 'BRAND');
    const productCrits = extractValuesFromCriteras(criterias, 'PRODUCTS');
    const categoryCrits = extractValuesFromCriteras(criterias, 'CATEGORY');
    const modelCrits = extractValuesFromCriteras(criterias, 'MODEL');
    //!Criteras and Filters
    const critereas = [];

    if (!_.isEmpty(productCrits)) {
      critereas.push({
        attribute_type: 'SKU',
        sku: productCrits
      });
    }

    if (!_.isEmpty(modelCrits)) {
      critereas.push({
        attribute_type: 'MODEL',
        model: modelCrits
      });
    }

    if (!_.isEmpty(brandCrits)) {
      critereas.push({
        attribute_type: 'BRAND',
        brand: brandCrits
      });
    }
    if (!_.isEmpty(categoryCrits)) {
      critereas.push({
        attribute_type: 'CATEGORIES',
        category: categoryCrits
      });
    }

    const filters = [];
    if (tier_restriction) filters.push({ filter_type: 'TIER', customerTireRestriction: tier_restriction });
    if (!_.isEmpty(customerSegment)) filters.push({ filter_type: 'SEGMENT', segment: customerSegment });
    if (!_.isEmpty(customerTire)) filters.push({ filter_type: 'TIER', customerTireRestriction: customerTire });
    if (!_.isEmpty(rfmCategories)) filters.push({ filter_type: 'RFM_CATEGORY', RFM_CATEGORY: rfmCategories });

    let customerRestrictionSelection = buyer_selection === 'ALL' ? 'ALL' : 'SPECIFIC';

    const excluded_products = getExcludedProductsFromApiCriterea(criterias);
    const rewards = getRewardData(promotionFormInitialValues?.discount, promotionFormInitialValues);
    let formValues: any = {
      title,
      status,
      appliesTo: application_type,
      applicability: 'CART',
      trigger,
      promotionType: promotion_type,
      country: countryValue,
      timeZone: timezone,
      currency,
      priority,
      productSelection_RC: product_selection,
      product1: products || [],
      isAvailablePos: is_pos,
      isAvailableOnline: is_online,
      chooseEcomType: online_list.length === 0 ? 'All' : 'Specific',
      chooseStoreType: pos_list.length === 0 ? 'All' : 'Specific',
      posList: online_list || [],
      onlineList: pos_list || [],
      limitPerUser: limit_per_user,
      maxUsage: max_usage,
      onlyForNewUser: new_buyer,
      seller_Selection: seller_selection,
      specific_seller: sellers,
      rewardType: reward_type,
      thresholdSelection: eligible_for,
      reward_name,
      next_order_applicable: next_order_applicable ? true : false,
      no_of_next_orders: no_of_next_orders,
      reward_value,
      tresholdValue: minimum_value,
      maxDiscount: cap_value,
      // applies_to_price,
      selectDiscountType: type,
      discountValue: percentage,
      Combine_with_other_promotions: combine_with_other_promo ? true : false,
      Id_Product_Selection: combine_promo_ids || [],
      for_generate: visibility,
      customerTireRestriction: tier_restriction,
      userSelection: buyer_selection,
      specific_buyer: buyers,
      code_gen: code,
      quantityToGenerate: coupon_quantity_to_generate,
      appliesPerTransaction,
      appliesPerTransaction_Count,
      daily_val: daily,
      week_val: weekly,
      month_val: monthly,
      seasonal_val: seasonal,
      weekValues: weekly_info,
      monthValues: monthly_info,
      seasonalValues: seasonal_info,
      dateRangeInSchedule: [
        getDayJsObjectWithFormattedString(start_date, 'YYYY-MM-DD'),
        getDayJsObjectWithFormattedString(end_date, 'YYYY-MM-DD')
      ],
      timeRangeInSchedule_startTime: getDayJsObjectWithFormattedString(start_time, 'HH:mm:ss'),
      timeRangeInSchedule_endTime: getDayJsObjectWithFormattedString(end_time, 'HH:mm:ss'),
      selectedItem,
      rewardItem,
      itemQuantity,
      qualityReward,
      critereas: application_type !== 'ORDER_TOTAL' ? critereas : [],
      filters,
      customerRestriction: customerRestrictionSelection,
      applies_to_product_in_sale,
      ignore_price_lock,
      max_discount_item_count,
      combine_with_other_promo,
      excluded_products,
      rewards_Data: rewards,
      // shipping_discount: rewards,
      discount_type: getDiscountType(),
      applies_to_price: getAppliesToPrice(),
      use_max_limit
    };

    getNewRewardData();
    // console.log('Set Data here');

    if (formValues.promotionType === 'BUNDLE_DISC') {
      promotionForm.setFieldsValue({ appliesTo: 'ITEM', rewardType: 'BUNDLE_DISCOUNT' });
    }

    if (from_date && to_date && from_time && to_time) {
      formValues = {
        ...formValues,
        dateRangeInScheduleFilter: [
          getDayJsObjectWithFormattedString(from_date, 'YYYY-MM-DD'),
          getDayJsObjectWithFormattedString(to_date, 'YYYY-MM-DD')
        ],
        timeRangeInScheduleFilter: [
          getDayJsObjectWithFormattedString(from_time, 'HH:mm:ss'),
          getDayJsObjectWithFormattedString(to_time, 'HH:mm:ss')
        ]
      };
    }
    //!Set the unnecessary states also with the form values TODO - need to refactor
    triggerSelectionHandler(trigger);
    changeCountryHandler(countryValue as string);
    //!Visible on popup radio
    setRadioValue(visible_on_popup ? 1 : 2);
    //!Code type
    const codeTypeValue = code_type === 'SINGLE' ? 1 : 2;
    setSingleRadio(codeTypeValue);

    //!ValidTimeframe
    setValidTimeFrame(validTimeFrame);

    promotionForm.setFieldsValue(formValues);
    setPromotionFormValues(formValues);
    setLoading(false);
  };

  React.useEffect(() => {
    promotionForm.setFieldsValue(promotionFormValues);
  }, [promotionFormValues]);

  const getExcludedProductsFromApiCriterea = (criteria: { criteria_list: Criteria[] }): string[] => {
    const productExlcusionCriterea = criteria.criteria_list.find(
      criterion =>
        criterion.type === 'PRODUCTS' && criterion.operation === 'EXCLUDE' && criterion.value_type === 'SKU_ID'
    );

    return productExlcusionCriterea?.values || [];
  };

  const extractCountryValue = (criteria: { criteria_list: Criteria[] }) => {
    const countryCriterion = criteria.criteria_list.find(
      criterion => criterion.type === 'COUNTRY' && criterion.values?.length > 0
    );

    return countryCriterion?.values[0] || '';
  };

  const extractValuesFromCriteras = (criteria: any, key: any) => {
    criteria.criteria_list = criteria.criteria_list.filter((item: any) => item !== null);
    const criterea = criteria?.criteria_list?.find(
      (criterion: any) => criterion.type === key && criterion.values?.length > 0
    );
    return criterea?.values || [];
  };

  const RFMextractValuesFromCriteras = (criteria: any, key: any) => {
    let nedC = criteria.criteria_list.filter((criteria: any) => criteria !== null);
    let data = nedC?.filter((item: any) => item.type === 'RFM_CATEGORY');
    return data.length > 0 ? data[0].values : [];
  };

  const getDateString = (date: any, formatter: string) => {
    let reqDate = dayjs(date).format(formatter);
    return reqDate;
  };

  const getTimeString = (time: any, format: string) => {
    let reqTime = dayjs(time).format(format);
    return reqTime;
  };

  const triggerSelectionHandler = (value: string) => {
    setPromotionTypeList(promotionTypes);
  };

  const changeCountryHandler = (value: string) => {
    let crList = currencyListWithCode;
    let tzList = timeZonesWithCode;
    promotionForm.setFieldsValue({
      timeZone: null,
      currency: null
    });
    const currencyFilter = crList.filter((item: any) => {
      return item.code === value || item.country === value;
    });
    const timeZoneFilter = tzList.filter((item: any) => {
      return item.code === value || item.country === value;
    });
    setCurrencyTypeList(currencyFilter);
    setTimeZoneList(timeZoneFilter);
    if (value === 'Chile') {
      promotionForm.setFieldsValue({
        timeZone: 'CHILE_CONTINENTAL',
        currency: 'CLP'
      });
    }
  };

  const handleFormSaveHandler = async () => {
    setLoading(true);
    try {
      // Get all the fields' names in the form
      const allFields = Object.keys(promotionForm.getFieldsValue());

      // Specify the field you want to ignore
      const fieldToIgnore = [
        'discount_type',
        'category',
        'selected_item',
        'product_Name',
        'unit_current_price',
        'quantity',
        'special_price',
        'min_quantity',
        'percent_discount_value',
        'max_discount',
        'operator_selection_buy',
        'operator_selection_get',
        'minimum_quantity',
        'maximum_quantity',
        'max_discount_Value_item',
        'percent_discount_value_item',
        'max_discount_Value_item',
        'max_quantity_item',
        'discount_value',
        'min_quantity_item_vol',
        'discount_value_item_vol',
        'max_quantity_item_vol',
        'max_quantity',
        'maximum_discount_value'
      ];

      // Filter out the field to ignore from the list of all fields
      const fieldsToValidate = allFields.filter(field => !fieldToIgnore?.includes(field));

      // Validate all fields except the one to ignore
      await promotionForm.validateFields(fieldsToValidate);

      // Proceed with update logic
      valatiateSaveHandler();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
      // displayErrorNotifications([{ message: 'Something Went Wrong !' }]);
    }
    setLoading(false);
  };

  const valatiateSaveHandler = () => {
    const bodyData = generateRequestBody();
    if (promotionForm.getFieldsValue().rewardType === 'BUNDLE_DISCOUNT') {
      let data = bodyData?.reward_details[0]?.product_reward?.product_info[0]?.conditions;
      if (data.length >= 2) {
        SaveHandler(bodyData);
      } else {
        displayErrorNotifications([{ message: 'Minimum Two Items are required for Reward Bundle !' }]);
      }
    } else {
      SaveHandler(bodyData);
    }
  };

  const SaveHandler = async (bodyData: any) => {
    if (isUpdate) {
      await handleUpdate(bodyData);
    } else {
      // console.log('Create bodyData ===>', bodyData);
      const { data, errors } = await promotionServiceCreate.getCreatePromotion(bodyData);
      if (_.isEmpty(errors)) {
        displaySuccessNotification({ message: 'Promotion created successfully!' });
        setpromotionID(data.promotion.id);
        navigate(-1);
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };

  const handleSaveSpecial = (sku: string, newPrice: any, data_Field_Name: string) => {
    const updatedData = specialPriceData.data?.map((item: any) => {
      if (item.sku === sku) {
        return { ...item, [data_Field_Name]: newPrice };
      }
      return item;
    });

    setSpecialPriceData({ data: updatedData });
    setSpecialPriceFilterData(updatedData);
    setSpecialPriceEditRecord(null);
    displaySuccessNotification({ message: 'Price Updated' });
  };

  const handleSaveSpecialST = (sku: string, newPrice: any, data_Field_Name: string) => {
    const updatedData = specialPriceSTData.data?.map((item: any) => {
      if (item.sku === sku) {
        return { ...item, [data_Field_Name]: newPrice };
      }
      return item;
    });

    setSpecialPriceSTData({ data: updatedData });
    setSpecialPriceSTFilterData(updatedData);
    setSpecialPriceSTEditRecord(null);
    displaySuccessNotification({ message: 'Price Updated' });
  };

  const handleSavePercentST = (sku: string, newPrice: any, data_Field_Name: string) => {
    const updatedData = percentPriceSTData.data?.map((item: any) => {
      if (item.sku === sku) {
        return { ...item, [data_Field_Name]: newPrice };
      }
      return item;
    });

    setPercentPriceSTData({ data: updatedData });
    setPercentPriceSTFilterData(updatedData);
    setPercentPriceSTEditRecord(null);
    displaySuccessNotification({ message: 'Price Updated' });
  };

  const handleSaveFixed = (sku: string, newPrice: any, data_Field_Name: string) => {
    const updatedData = fixedPriceData.data?.map((item: any) => {
      if (item.sku === sku) {
        return { ...item, [data_Field_Name]: newPrice };
      }
      return item;
    });

    setfixedPriceData({ data: updatedData });
    setfixedPriceFilterData(updatedData);
    setFixedPriceEditRecord(null);
    displaySuccessNotification({ message: 'Price Updated' });
  };

  const handleSaveFixedST = (sku: string, newPrice: any, data_Field_Name: string) => {
    const updatedData = fixedPriceSTData.data?.map((item: any) => {
      if (item.sku === sku) {
        return { ...item, [data_Field_Name]: newPrice };
      }
      return item;
    });

    setfixedPriceSTData({ data: updatedData });
    setfixedPriceSTFilterData(updatedData);
    setFixedPriceSTEditRecord(null);
    displaySuccessNotification({ message: 'Price Updated' });
  };

  const handleSavePercent = (sku: string, newPrice: any, data_Field_Name: string) => {
    const updatedData = percentPriceData.data?.map((item: any) => {
      if (item.sku === sku) {
        return { ...item, [data_Field_Name]: newPrice };
      }
      return item;
    });

    setPercentPriceData({ data: updatedData });
    setPercentPriceFilterData(updatedData);
    setPercentPriceEditRecord(null);
    displaySuccessNotification({ message: 'Price Updated' });
  };

  const { tenentName, bussinessGroupName } = useAuth(({ tenentName, bussinessGroupName }) => ({
    tenentName,
    bussinessGroupName
  }));

  const generateRequestBody = () => {
    //!New Fields
    let { filters, applies_to_product_in_sale, combine_with_other_promo, ignore_price_lock, excluded_products } =
      promotionForm.getFieldsValue();

    filters = filters || [];

    let TireValues = filters
      .filter((item: any) => item.filter_type === 'TIER')
      ?.map((item: any) => item.customerTireRestriction)
      .flat();

    let SegmentValues = filters
      .filter((item: any) => item.filter_type === 'SEGMENT')
      ?.map((item: any) => item.segment)
      .flat();

    let RfmValues = filters
      .filter((item: any) => item.filter_type === 'RFM_CATEGORY')
      ?.map((item: any) => item.RFM_CATEGORY)
      .flat();

    let filterData = filters?.map((item: any) => {
      if (item.filter_type === 'TIER') {
        return {
          operation: 'INCLUDE',
          type: 'CUSTOMER_TIER',
          value_type: '',
          values: TireValues
        };
      } else if (item.filter_type === 'SEGMENT' && Array.isArray(item.segment)) {
        return {
          operation: 'INCLUDE',
          type: 'CUSTOMER_SEGMENT',
          value_type: '',
          values: SegmentValues
        };
      } else if (item.filter_type === 'RFM_CATEGORY' && Array.isArray(item.RFM_CATEGORY)) {
        return {
          operation: 'INCLUDE',
          type: 'RFM_CATEGORY',
          value_type: '',
          values: RfmValues
        };
      }
      return null;
    });
    let buyer_selection = promotionForm.getFieldsValue().customerRestriction;
    let buyers: any = promotionForm.getFieldsValue().specific_buyer || [];
    const exclusions = [];

    if (!_.isEmpty(excluded_products)) {
      exclusions.push({
        operation: 'EXCLUDE',
        type: 'PRODUCTS',
        value_type: 'SKU_ID',
        values: excluded_products || []
      });
    }
    let specialDayData = promotionForm.getFieldsValue().special_day
      ? {
          operation: 'INCLUDE',
          type: 'SPL_DATE',
          value_type: '',
          values: ['birthday']
        }
      : null;
    let Inclusions = [
      {
        operation: 'INCLUDE',
        type: 'COUNTRY',
        value_type: '',
        values: [promotionForm.getFieldsValue()?.country]
      },
      specialDayData,
      ...filterData
    ];

    let validUntilStartDate = promotionForm.getFieldsValue().dateRangeInDiscount
      ? getDateString(promotionForm.getFieldsValue().dateRangeInDiscount[0], 'YYYY-MM-DD')
      : null;
    let validUnitlEndDate = promotionForm.getFieldsValue().dateRangeInDiscount
      ? getDateString(promotionForm.getFieldsValue().dateRangeInDiscount[1], 'YYYY-MM-DD')
      : null;
    let validUntilStartTime = promotionForm.getFieldsValue().timeRangeInDiscount_StartTime
      ? getTimeString(promotionForm.getFieldsValue().timeRangeInDiscount_StartTime, 'HH:mm:ss')
      : null;
    let validUnitilEndTime = promotionForm.getFieldsValue().timeRangeInDiscount_EndTime
      ? getTimeString(promotionForm.getFieldsValue().timeRangeInDiscount_EndTime, 'HH:mm:ss')
      : null;
    let newRewardData = getNewReward();
    // console.log('rewardData', newRewardData);
    let cretriaData = [...exclusions, ...Inclusions].filter(item => item !== null);
    let couponUsage = promotionForm.getFieldsValue().use_max_limit
      ? MAX_LIMIT
      : Number(promotionForm.getFieldsValue().maxUsage);
    const newRequest = {
      basic_details: {
        tenant_id: tenentName,
        business_group_id: bussinessGroupName,
        title: promotionForm.getFieldsValue().title,
        trigger: promotionForm.getFieldsValue().trigger,
        rank: Number(promotionForm.getFieldsValue().priority),
        promotion_type: promotionForm.getFieldsValue().promotionType,
        status: promotionForm.getFieldsValue().status,
        application_type: promotionForm.getFieldsValue().appliesTo,
        application: 'CART',
        currency: promotionForm.getFieldsValue().currency,
        visibility: promotionForm.getFieldsValue().for_generate
          ? promotionForm.getFieldsValue().for_generate
          : 'ANONYMOUS',
        buyer_selection,
        buyers,
        seller_selection: promotionForm.getFieldsValue().seller_Selection || 'ALL',
        staff_selection: 'NONE', //hardCoded as their is no staff selection now CONFIRMED BY BE
        sellers: promotionForm.getFieldsValue().specific_seller ? promotionForm.getFieldsValue().specific_seller : [],
        product_selection: promotionForm.getFieldsValue().productSelection_RC || 'ALL',
        reward_type: promotionForm.getFieldsValue().rewardType,
        next_order_applicable:
          promotionForm.getFieldsValue().trigger === 'MANUAL'
            ? promotionForm.getFieldsValue().next_order_applicable
            : null,
        no_of_next_orders:
          promotionForm.getFieldsValue().next_order_applicable === true
            ? promotionForm.getFieldsValue().no_of_next_orders
            : null
      },
      criterias: {
        criteria_list: cretriaData
      },
      channel_details: {
        is_pos: promotionForm.getFieldsValue().isAvailablePos,
        pos: promotionForm.getFieldsValue().posList ? promotionForm.getFieldsValue().posList : [],
        online: promotionForm.getFieldsValue().onlineList ? promotionForm.getFieldsValue().onlineList : [],
        is_online: promotionForm.getFieldsValue().isAvailableOnline
      },
      loyalty_details: promotionForm.getFieldsValue()?.reward_name
        ? {
            reward_name: promotionForm.getFieldsValue()?.reward_name,
            reward_value: promotionForm.getFieldsValue()?.reward_value
          }
        : null,
      usage_details: {
        max_usage: couponUsage,
        limit_per_user: Number(promotionForm.getFieldsValue().limitPerUser)
      },
      validity: {
        start_date: promotionForm.getFieldsValue().dateRangeInSchedule
          ? getDateString(promotionForm.getFieldsValue().dateRangeInSchedule[0], 'YYYY-MM-DD')
          : null,
        end_date: promotionForm.getFieldsValue().dateRangeInSchedule
          ? getDateString(promotionForm.getFieldsValue().dateRangeInSchedule[1], 'YYYY-MM-DD')
          : null,
        start_time:
          promotionForm.getFieldsValue().timeRangeInSchedule_startTime &&
          getTimeString(promotionForm.getFieldsValue().timeRangeInSchedule_startTime, 'HH:mm:ss'),
        end_time:
          promotionForm.getFieldsValue().timeRangeInSchedule_endTime &&
          getTimeString(promotionForm.getFieldsValue().timeRangeInSchedule_endTime, 'HH:mm:ss'),
        timezone: promotionForm.getFieldsValue().timeZone,
        valid_on_given_time_frame: validTimeFrame === 1
      },
      schedule: getSchedule(),
      code_details:
        promotionForm.getFieldsValue().trigger === 'MANUAL'
          ? {
              code_type: singleRadio === Number(1) ? 'SINGLE' : 'MULTIPLE',
              code: promotionForm.getFieldsValue()?.code_gen || null,
              coupon_quantity_to_generate:
                singleRadio === Number(2) ? Number(promotionForm.getFieldsValue().quantityToGenerate) : 1,
              coupon_start_date: validUntilStartDate,
              coupon_end_date: validUnitlEndDate,
              coupon_start_time: validUntilStartTime,
              coupon_end_time: validUnitilEndTime,
              coupon_usage_limit: promotionForm.getFieldsValue().coupon_usage_limit
            }
          : null,
      reward_details: newRewardData,
      additional_config: {
        transaction_apply_count: 1,
        new_buyer: promotionForm.getFieldsValue().onlyForNewUser ? true : false,
        visible_on_popup: radioValue === Number(1) ? true : false,
        ignore_price_lock: ignore_price_lock ? ignore_price_lock : false,
        applies_to_product_in_sale: applies_to_product_in_sale ? applies_to_product_in_sale : false,
        combine_with_other_promo: combine_with_other_promo ? combine_with_other_promo : false
      }
    };
    return newRequest;
  };

  const handleUpdate = async (bodyData: any) => {
    // const bodyData = generateRequestBody();
    // console.log('Update bodyData', bodyData);
    const { data, errors } = await promotionServiceCreate.updatePromotion(
      bodyData,
      promotionFormInitialValues?.id || (id as string)
    );
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotion updated successfully!' });
      setpromotionID(data?.promotion?.id);
      navigate(-1);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const scheduleDiscount = async () => {
    if (promotionID == null) {
      return;
    }
    const { data, errors } = await promotionServiceCreate.scheduleDiscount(promotionID);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotion scheduled successfully!' });
      setTimeout(() => {
        navigate(-1);
      }, 500);
    } else {
      displayErrorNotifications(errors);
    }
  };
  const getSchedule = () => {
    let schedule = {};
    if (promotionForm.getFieldsValue().daily_val) {
      schedule = {
        ...schedule,
        ...{
          daily: promotionForm.getFieldsValue().daily_val,
          daily_info: {
            from_date: promotionForm.getFieldsValue().dateRangeInSchedule
              ? getDateString(promotionForm.getFieldsValue().dateRangeInSchedule[0], 'YYYY-MM-DD')
              : null,
            to_date: promotionForm.getFieldsValue().dateRangeInSchedule
              ? getDateString(promotionForm.getFieldsValue().dateRangeInSchedule[1], 'YYYY-MM-DD')
              : null,
            from_time: promotionForm.getFieldsValue().timeRangeInScheduleFilter
              ? getTimeString(promotionForm.getFieldsValue().timeRangeInScheduleFilter[0], 'HH:mm:ss')
              : null,
            to_time: promotionForm.getFieldsValue().timeRangeInScheduleFilter
              ? getTimeString(promotionForm.getFieldsValue().timeRangeInScheduleFilter[1], 'HH:mm:ss')
              : null
          }
        }
      };
    }
    if (promotionForm.getFieldsValue().week_val) {
      schedule = {
        ...schedule,
        ...{
          weekly: promotionForm.getFieldsValue().week_val,
          weekly_info: promotionForm.getFieldsValue().weekValues || []
        }
      };
    }

    if (promotionForm.getFieldsValue().month_val) {
      schedule = {
        ...schedule,
        ...{
          monthly: promotionForm.getFieldsValue().month_val,
          monthly_info: promotionForm.getFieldsValue().monthValues || []
        }
      };
    }

    // if (promotionForm.getFieldsValue().seasonal_val) {
    //   schedule = {
    //     ...schedule,
    //     ...{
    //       seasonal: promotionForm.getFieldsValue().seasonal_val,
    //       seasonal_info: promotionForm.getFieldsValue().seasonalValues || []
    //     }
    //   };
    // }

    if (promotionForm.getFieldsValue().special_day) {
      schedule = {
        ...schedule,
        ...{
          seasonal: promotionForm.getFieldsValue().special_day,
          seasonal_info: promotionForm.getFieldsValue().special_day || []
        }
      };
    }
    let returnScheduleData = Object.values(schedule).length > 0 ? schedule : null;
    return returnScheduleData;
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const onChangeRadioS_M = (e: RadioChangeEvent) => {
    setSingleRadio(e.target.value);
  };

  const handleValidTimeFrame = (e: RadioChangeEvent) => {
    setValidTimeFrame(e.target.value);
  };

  const handleGenerateRandomString = () => {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }

    promotionForm.setFieldValue('code_gen', result);
  };

  const disabledDate = (current: any) => {
    // Disable dates before today
    return current && current < dayjs().startOf('day');
  };

  const disabledNewTime = (current: Dayjs | null) => {
    let selectedStartDate =
      promotionForm?.getFieldsValue()?.dateRangeInSchedule &&
      getDateString(promotionForm?.getFieldsValue()?.dateRangeInSchedule[0], 'YYYY-MM-DD');
    let todayDate = dayjs().format('YYYY-MM-DD');

    if (!!selectedStartDate && selectedStartDate !== todayDate) {
      return {};
    }
    if (!current) {
      // allow all times if no value is selected
      return {};
    }

    const now = dayjs();
    const isBeforeCurrentDate = current.isBefore(now, 'day');
    const isSameCurrentDate = current.isSame(now, 'day');
    if (isBeforeCurrentDate) {
      // disable all times if the date is before the current date
      return {
        disabledHours: () => Array.from({ length: 24 })?.map((_, index) => index),
        disabledMinutes: () => Array.from({ length: 60 })?.map((_, index) => index),
        disabledSeconds: () => Array.from({ length: 60 })?.map((_, index) => index)
      };
    } else if (isSameCurrentDate) {
      // disable hours, minutes, and seconds that are before the current time
      let obj = {
        disabledHours: () => Array.from({ length: now.hour() })?.map((_, index) => index),
        disabledMinutes: (selectedHour: number) =>
          selectedHour === now.hour() ? Array.from({ length: now.minute() })?.map((_, index) => index) : [],
        disabledSeconds: (selectedHour: number, selectedMinute: number) =>
          selectedHour === now.hour() && selectedMinute === now.minute()
            ? Array.from({ length: now.second() })?.map((_, index) => index)
            : []
      };
      return obj;
    }

    // allow all times for future dates
    return {};
  };

  //!User fetching
  const { debouncedFetchUserDropdownOptions, userDropdownFetching, userDropdownOptions } = useCustomers();
  const { debouncedFetchProductDropdownOptions, productDropdownFetching, productDropdownOptions } = useProducts();
  // const { modelDropdownOptions } = useModels();
  // const { brandDropdownOptions } = useBrands();
  const { sellerDropdownOptions, debouncedFetchSellerDropdownOptions, sellerDropdownFetching } = useSellers();
  const { debouncedFetchPosDropdownOptions, posDropdownFetching, posDropdownOptions } = usePos();
  const { debouncedFetchOnlineDropdownOptions, onlineDropdownFetching, onlineDropdownOptions } = useOnlineChannels();

  const getvolumeSpecialTreshold = (itemData: any) => {
    let treshHold = itemData?.map((item: any) => {
      let limit = item?.max_limit_set?.toString().length > 1 ? item?.max_limit_set : false;
      let obj = {
        minimum_value: item?.from_Quantity,
        maximum_value: limit ? MAX_LIMIT : item?.to_Quantity,
        max_limit_set: limit,
        discount_value: item?.volume_value || item?.discount_value || 6969
      };
      return obj;
    });
    return treshHold;
  };

  const getNewReward = () => {
    const {
      promotionType,
      discount_type,
      range,
      applies_to_price,
      operator_selection_buy,
      operator_selection_get,
      percent_discount_value,
      max_discount,
      apply_discount_on,
      min_line_items,
      appliesTo,
      threshold_selection,
      subtotal_treshold,
      percent_discount_value_item,
      min_discount_volume,
      // --------------VOLUME--------
      percent_discount_value_volume,
      max_discount_volume,
      isRange,
      // -------------Bundle
      discount_value,
      maximum_discount_value,
      // --------------Shipping Discount
      shipping_discount
    } = promotionForm.getFieldsValue();

    if (promotionType === 'BUNDLE_DISC' && discount_type === 'SPL_PRICE') {
      const getSplPriceConditionData = () => {
        let splData = specialPriceData?.data?.map((item: any) => {
          let obj = {
            category: item?.category,
            identifier_type: 'SKU_ID',
            item_id: item?.sku,
            minimum_quantity: item?.quantity,
            maximum_quantity: null,
            max_discount: null,
            price_ratio: null,
            discount_value: item?.special_price
          };
          return obj;
        });
        return splData;
      };

      let combData = [
        {
          discount_type: 'SPL_PRICE',
          threshold_selection: 'NO_THRESHOLD',
          discount_value: null,
          minimum_value: null,
          cap_value: null,
          applies_to_price: 'NONE',
          max_discount: null,
          apply_discount_on: 'PRODUCTS',
          product_reward: {
            product_info: [
              {
                selection_type: 'BUNDLE',
                operator: 'AND',
                conditions: getSplPriceConditionData()
              }
            ]
          }
        }
      ];

      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }

      return combData;
    } else if (promotionType === 'BUNDLE_DISC' && discount_type === 'PERCENTAGE') {
      const getPercentPriceConditionData = () => {
        let prsData = percentPriceData?.data?.map((item: any) => {
          let obj = {
            category: item?.category,
            identifier_type: 'SKU_ID',
            item_id: item?.sku,
            minimum_quantity: item?.min_quantity || 0,
            maximum_quantity: item?.max_quantity || 0,
            max_discount: 0,
            price_ratio: 0,
            discount_value: 0
          };
          return obj;
        });
        return prsData;
      };

      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: 'NO_THRESHOLD',
          discount_value: discount_value || 0,
          minimum_value: 0,
          cap_value: 0,
          applies_to_price: 'NONE',
          max_discount: maximum_discount_value || MAX_LIMIT,
          apply_discount_on: 'PRODUCTS',
          product_reward: {
            product_info: [
              {
                selection_type: 'BUNDLE',
                operator: 'AND',
                conditions: getPercentPriceConditionData()
              }
            ]
          }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'BUNDLE_DISC' && discount_type === 'FIXED') {
      const getAbsolutePriceConditionData = () => {
        let absData = fixedPriceData?.data?.map((item: any) => {
          let obj = {
            category: item?.category,
            identifier_type: 'SKU_ID',
            item_id: item?.sku,
            minimum_quantity: item?.min_quantity || 0,
            maximum_quantity: item?.max_quantity || 0,
            max_discount: 0,
            price_ratio: 0,
            discount_value: 0
          };
          return obj;
        });
        return absData;
      };
      let combData = [
        {
          discount_type: 'FIXED',
          threshold_selection: 'NO_THRESHOLD',
          discount_value: discount_value || 0,
          minimum_value: 0,
          cap_value: 0,
          applies_to_price: 'NONE',
          max_discount: maximum_discount_value || MAX_LIMIT,
          apply_discount_on: 'PRODUCTS',
          product_reward: {
            product_info: [
              {
                selection_type: 'BUNDLE',
                operator: 'AND',
                conditions: getAbsolutePriceConditionData()
              }
            ]
          }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'VOLUME_DISC' && discount_type === 'SPL_PRICE') {
      const getVolumeSpecialPriceData = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let volData = specialPriceVolumeData?.data?.map((item: any) => {
            let obj = {
              category: item.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku,
              minimum_quantity: Number(item?.min_quantity_item_vol) || 0,
              maximum_quantity: Number(item?.max_quantity_item_vol) || 0,
              max_discount: Number(item?.max_discount_item_vol) || 0,
              price_ratio: 0,
              discount_value: Number(item?.special_price) || 0,
              thresholds: item?.volume_Threshold_qty ? getvolumeSpecialTreshold(item?.volume_Threshold_qty) : null
            };
            return obj;
          });
          return volData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || 0,
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: Number(item?.max_discount) || 0,
              price_ratio: 0,
              discount_value: Number(item.discount_value) || 0,
              unit_current_price: item.unit_price || 0,
              thresholds: item?.volume_Threshold_qty ? getvolumeSpecialTreshold(item?.volume_Threshold_qty) : null
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };
      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: range ? 'QUANTITY' : 'NO_THRESHOLD',
          discount_value: null,
          minimum_value: null,
          cap_value: null,
          applies_to_price: 'NONE',
          max_discount: null,
          apply_discount_on: apply_discount_on,
          product_reward: {
            product_info: [
              {
                selection_type: 'VOLUME',
                operator: 'OR',
                min_line_items: null,
                conditions: getVolumeSpecialPriceData()
              }
            ]
          }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'VOLUME_DISC' && discount_type === 'PERCENTAGE') {
      const getvolumeSpecialTresholdLoad = (itemData: any) => {
        let treshHold = itemData?.map((item: any) => {
          let limit = item?.max_limit_set?.toString().length > 1 ? item?.max_limit_set : false;
          let obj = {
            minimum_value: item?.from_Quantity,
            maximum_value: limit ? MAX_LIMIT : item?.to_Quantity,
            max_limit_set: limit,
            discount_value: item?.volume_value
          };
          return obj;
        });
        return treshHold;
      };

      const getVolumePercentPriceData = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let volData = percentPriceVolumeData?.data?.map((item: any) => {
            let obj = {
              category: item.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku,
              minimum_quantity: Number(item?.min_quantity_item_vol) || 0,
              maximum_quantity: Number(item?.max_quantity_item_vol) || 0,
              max_discount: Number(item?.max_discount_item_vol) || MAX_LIMIT,
              price_ratio: 0,
              discount_value: Number(item?.discount_value) || Number(item?.discount_value_item_vol) || 0,
              thresholds: item?.volume_Threshold_qty ? getvolumeSpecialTresholdLoad(item?.volume_Threshold_qty) : null
            };
            return obj;
          });
          return volData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || 0,
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: Number(item?.max_discount) || 0,
              price_ratio: 0,
              discount_value: Number(item.discount_value) || 0,
              unit_current_price: item.unit_price || 0,
              thresholds: item?.volume_Threshold_qty ? getvolumeSpecialTreshold(item?.volume_Threshold_qty) : null
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };
      let maxData = getVolumePercentPriceData()?.length > 0;
      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: isRange ? 'QUANTITY' : 'NO_THRESHOLD',
          discount_value: Number(percent_discount_value_volume) || 0,
          minimum_value: min_discount_volume || 0,
          cap_value: 0,
          applies_to_price: 'NONE',
          max_discount: maxData ? max_discount_volume || MAX_LIMIT : 0,
          apply_discount_on: apply_discount_on,
          product_reward: {
            product_info: [
              {
                selection_type: 'VOLUME',
                operator: 'OR',
                min_line_items: null, //apply_discount_on === 'CATEGORY' ? min_line_items : null,
                conditions: getVolumePercentPriceData()
              }
            ]
          }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'VOLUME_DISC' && discount_type === 'FIXED') {
      const getVolumeFixedPriceData = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let fixData = fixedPriceVolumeData?.data?.map((item: any) => {
            let obj = {
              category: item.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku,
              minimum_quantity: Number(item?.min_quantity_item_vol) || Number(item?.min_quantity) || 0,
              maximum_quantity: Number(item?.max_quantity_item_vol) || Number(item?.max_quantity) || 0,
              max_discount: Number(item?.max_discount_item_vol) || 0,
              price_ratio: 0,
              discount_value: Number(item?.discount_value_item_vol) || 0,
              thresholds: item?.volume_Threshold_qty ? getvolumeSpecialTreshold(item?.volume_Threshold_qty) : null
            };
            return obj;
          });
          return fixData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || 0,
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: Number(item?.max_discount) || 0,
              price_ratio: 0,
              discount_value: Number(item.discount_value) || 0,
              unit_current_price: item.unit_price || 0,
              thresholds: item?.volume_Threshold_qty ? getvolumeSpecialTreshold(item?.volume_Threshold_qty) : null
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };
      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: isRange ? 'QUANTITY' : 'NO_THRESHOLD',
          discount_value: Number(percent_discount_value_volume),
          minimum_value: min_discount_volume || 0,
          cap_value: 0,
          applies_to_price: 'NONE',
          max_discount: max_discount_volume || MAX_LIMIT,
          apply_discount_on: apply_discount_on,
          product_reward: {
            product_info: [
              {
                selection_type: 'VOLUME',
                operator: 'OR',
                min_line_items: null, //apply_discount_on === 'CATEGORY' ? min_line_items : null,
                conditions: getVolumeFixedPriceData()
              }
            ]
          }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'BXGY' && discount_type === 'SPL_PRICE') {
      const getBXGYRewardOn = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let buyData = bxgyData?.map(entry => entry.buyData).flat();
          let getAllBuyData = buyData?.map((item: any) => {
            let obj = {
              category: item.category,
              identifier_type: 'SKU_ID',
              item_id: item.selected_item,
              minimum_quantity: item.item_quantity || 0,
              maximum_quantity: 0,
              discount_value: item.special_price_BXGY_buy || 0,
              max_discount: 0,
              price_ratio: 0
            };
            return obj;
          });
          return getAllBuyData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || '',
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: null,
              price_ratio: 0,
              discount_value: 0,
              unit_current_price: item.unit_price || 0
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };
      const getBXGYRewarded = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let getData = bxgyData?.map(entry => entry.getData).flat();
          let getAllRewardData = getData?.map((item: any) => {
            let obj = {
              category: item.category,
              identifier_type: 'SKU_ID',
              item_id: item.selected_item,
              minimum_quantity: item.item_quantity || 0,
              maximum_quantity: 0,
              discount_value: 0,
              max_discount: 0,
              price_ratio: 0
            };
            return obj;
          });
          return getAllRewardData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || '',
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: 0,
              price_ratio: 0,
              discount_value: 0,
              unit_current_price: item.unit_price || 0
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };
      let combData = [
        {
          allow_customer_choice: false,
          applies_to_price: applies_to_price,
          apply_discount_on: apply_discount_on,
          cap_value: 0,
          discount_type: discount_type,
          discount_value: Number(percent_discount_value) || 0,
          give_any_item_as_reward: true,
          give_same_item_as_reward: false,
          give_same_item_if_exist: false,
          max_discount: max_discount || MAX_LIMIT,
          minimum_value: 0,
          product_reward: {
            product_info: [
              {
                selection_type: 'REWARD_ON',
                operator: operator_selection_buy || 'OR',
                min_line_items: apply_discount_on === 'CATEGORY' ? min_line_items : null,
                conditions: getBXGYRewarded()
              },
              {
                selection_type: 'REWARDED',
                operator: operator_selection_get || 'OR',
                min_line_items: apply_discount_on === 'CATEGORY' ? min_line_items : null,
                conditions: applies_to_price === 'NONE' ? getBXGYRewardOn() : null
              }
            ]
          }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'BXGY' && discount_type === 'PERCENTAGE') {
      const getBXGYRewardOn = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let buyData = bxgyData?.map(entry => entry.buyData).flat();
          let getAllBuyData = buyData?.map((item: any) => {
            let obj = {
              category: item.category,
              identifier_type: 'SKU_ID',
              item_id: item.selected_item,
              minimum_quantity: item.minimum_Quantity_BXGY_buy || 0,
              maximum_quantity: item.maximum_Quantity_BXGY_buy || 0,
              discount_value: item.percent_discount_value || 0,
              max_discount: 0,
              price_ratio: 0
            };
            return obj;
          });
          return getAllBuyData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || '',
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: null,
              price_ratio: 0,
              discount_value: 0
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };
      const getBXGYRewarded = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let getData = bxgyData?.map(entry => entry.getData).flat();
          let getAllRewardData = getData?.map((item: any) => {
            let obj = {
              category: item.category,
              identifier_type: 'SKU_ID',
              item_id: item.selected_item,
              minimum_quantity: item.item_quantity || 0,
              maximum_quantity: 0,
              discount_value: 0,
              max_discount: 0,
              price_ratio: 0
            };
            return obj;
          });
          return getAllRewardData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || '',
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: 0,
              price_ratio: 0,
              discount_value: 0,
              unit_current_price: item.unit_price || 0
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };
      let combData = [
        {
          allow_customer_choice: false,
          applies_to_price: applies_to_price,
          apply_discount_on: apply_discount_on,
          cap_value: 0,
          discount_type: 'PERCENTAGE',
          discount_value: Number(percent_discount_value) || null,
          give_any_item_as_reward: true,
          give_same_item_as_reward: false,
          give_same_item_if_exist: false,
          max_discount: max_discount || MAX_LIMIT,
          minimum_value: 0,
          product_reward: {
            product_info: [
              {
                selection_type: 'REWARD_ON',
                operator: promotionForm.getFieldsValue().operator_selection_buy || 'OR',
                min_line_items: apply_discount_on === 'CATEGORY' ? min_line_items : null,
                conditions: getBXGYRewarded()
              },
              {
                selection_type: 'REWARDED',
                operator: promotionForm.getFieldsValue().operator_selection_get || 'OR',
                min_line_items: apply_discount_on === 'CATEGORY' ? min_line_items : null,
                conditions: getBXGYRewardOn()
              }
            ]
          }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'BXGY' && discount_type === 'FIXED') {
      const getBXGYRewardOn = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let buyData = bxgyData?.map(entry => entry.buyData).flat();
          let getAllBuyData = buyData?.map((item: any) => {
            let obj = {
              category: item.category,
              identifier_type: 'SKU_ID',
              item_id: item.selected_item,
              minimum_quantity: item.minimum_Quantity_BXGY_buy,
              maximum_quantity: 0,
              discount_value: item.percent_discount_value || 0,
              max_discount: 0,
              price_ratio: 0
            };
            return obj;
          });
          return getAllBuyData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || '',
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: 0,
              price_ratio: 0,
              discount_value: 0,
              unit_current_price: item.unit_price || 0
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };
      const getBXGYRewarded = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let getData = bxgyData?.map(entry => entry.getData).flat();
          let getAllRewardData = getData?.map((item: any) => {
            let obj = {
              category: item.category,
              identifier_type: 'SKU_ID',
              item_id: item.selected_item,
              minimum_quantity: item.item_quantity,
              maximum_quantity: 0,
              discount_value: item?.discount_value || 0,
              max_discount: 0,
              price_ratio: 0
            };
            return obj;
          });
          return getAllRewardData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || '',
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: 0,
              price_ratio: 0,
              discount_value: item?.discount_value || 0,
              unit_current_price: item.unit_price || 0
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };
      let combData = [
        {
          allow_customer_choice: false,
          applies_to_price: applies_to_price,
          apply_discount_on: apply_discount_on,
          cap_value: 0,
          discount_type: discount_type,
          discount_value: Number(percent_discount_value) || null,
          give_any_item_as_reward: true,
          give_same_item_as_reward: false,
          give_same_item_if_exist: false,
          max_discount: max_discount || MAX_LIMIT,
          minimum_value: 0,
          product_reward: {
            product_info: [
              {
                selection_type: 'REWARD_ON',
                operator: promotionForm.getFieldsValue().operator_selection_buy || 'OR',
                min_line_items: apply_discount_on === 'CATEGORY' ? min_line_items : null,
                conditions: getBXGYRewarded()
              },
              {
                selection_type: 'REWARDED',
                operator: promotionForm.getFieldsValue().operator_selection_get || 'OR',
                min_line_items: apply_discount_on === 'CATEGORY' ? min_line_items : null,
                conditions: getBXGYRewardOn()
              }
            ]
          }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'SUB_TTL_DIS' && discount_type === 'PERCENTAGE') {
      let isOrderTotal = appliesTo === 'ORDER_TOTAL';

      const getPercentPriceConditionData = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let prsData = percentPriceSTData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku,
              minimum_quantity: item?.min_quantity || 0,
              maximum_quantity: item?.max_quantity_item || 0,
              max_discount: item?.max_discount_Value_item || 0,
              price_ratio: 0,
              discount_value: item.percent_discount_value_item || 0,
              unit_current_price: item?.unit_price || 0,
              thresholds:
                item?.subtotal_treshold?.map((cv: any) => {
                  let data = { ...cv, discount_value: Number(cv.discount_value) };
                  return data;
                }) || null
            };
            return obj;
          });
          return prsData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || 0,
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: item?.max_discount || item?.max_discount_Value_item || 0,
              price_ratio: 0,
              discount_value: item?.discount_value || 0,
              unit_current_price: item.unit_price || 0,
              thresholds: subtotal_treshold ? { threshold_list: getTresholdData() } : null
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };

      const getTresholdData = () => {
        const thresholdData = subtotal_treshold?.map((item: any) => {
          let data;
          if (item?.max_limit_set) {
            data = {
              ...item,
              discount_value: Number(item.discount_value),
              maximum_value: MAX_LIMIT // Remove maximum_value if max_limit_set is true
            };
          } else {
            data = {
              ...item,
              discount_value: Number(item.discount_value)
            };
          }
          return data;
        });
        return thresholdData;
      };

      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: isOrderTotal ? threshold_selection : 'NO_THRESHOLD',
          thresholds: isOrderTotal ? (subtotal_treshold ? { threshold_list: getTresholdData() } : null) : null,
          discount_value: percent_discount_value_item ? Number(percent_discount_value_item) : 0,
          applies_to_price: 'NONE',
          max_discount: max_discount || 0,
          apply_discount_on: isOrderTotal ? 'ORDER' : apply_discount_on,
          product_reward: isOrderTotal
            ? null
            : {
                product_info: [
                  {
                    selection_type: 'BASIC',
                    operator: 'OR',
                    min_line_items: min_line_items || 0,
                    conditions: getPercentPriceConditionData()
                  }
                ]
              }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'SUB_TTL_DIS' && discount_type === 'FIXED') {
      const getAbsolutePriceConditionData = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let absData = fixedPriceSTData?.data.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku,
              minimum_quantity: item?.min_quantity || 0,
              maximum_quantity: item?.max_quantity_item || 0,
              max_discount: item?.max_quantity || item.max_discount_Value_item || MAX_LIMIT,
              price_ratio: 0,
              discount_value: item?.percent_discount_value_item || item.percent_discount_value || 0,
              unit_current_price: item?.unit_price,
              thresholds:
                item?.subtotal_treshold?.map((cv: any) => {
                  let data = { ...cv, discount_value: Number(cv.discount_value) };
                  return data;
                }) || null
            };
            return obj;
          });
          return absData;
        }
        if (apply_discount_on === 'CATEGORY') {
          let prsData = categoryData?.data?.map((item: any) => {
            let obj = {
              category: item?.category,
              identifier_type: 'SKU_ID',
              item_id: item?.sku || 0,
              minimum_quantity: item?.minimum_quantity || 0,
              maximum_quantity: item?.maximum_quantity || 0,
              max_discount: item?.max_discount || item?.max_discount_Value_item || 0,
              price_ratio: 0,
              discount_value: item?.discount_value || 0,
              unit_current_price: item.unit_price || 0,
              thresholds: subtotal_treshold ? { threshold_list: getTresholdData() } : null
            };
            return obj;
          });
          return prsData;
        }
        return [];
      };

      const getTresholdData = () => {
        const thresholdData = subtotal_treshold?.map((item: any) => {
          let data;
          if (item?.max_limit_set) {
            data = {
              ...item,
              discount_value: Number(item.discount_value),
              maximum_value: MAX_LIMIT // Remove maximum_value if max_limit_set is true
            };
          } else {
            data = {
              ...item,
              discount_value: Number(item.discount_value)
            };
          }
          return data;
        });
        return thresholdData;
      };

      let isOrderTotal = appliesTo === 'ORDER_TOTAL';
      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: isOrderTotal ? threshold_selection : 'NO_THRESHOLD',
          thresholds: isOrderTotal ? (subtotal_treshold ? { threshold_list: getTresholdData() } : null) : null,
          discount_value: Number(percent_discount_value) || 0,
          minimum_value: 0,
          cap_value: 0,
          applies_to_price: 'NONE',
          max_discount: 0,
          apply_discount_on: isOrderTotal ? 'ORDER' : apply_discount_on,
          product_reward: isOrderTotal
            ? null
            : {
                product_info: [
                  {
                    selection_type: 'BASIC',
                    operator: 'OR',
                    min_line_items: min_line_items,
                    conditions: getAbsolutePriceConditionData()
                  }
                ]
              }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    } else if (promotionType === 'SHIPPING_DISC') {
      const getTresholdDataShipDisc = () => {
        const thresholdData = shipping_discount?.map((item: any) => {
          let data;
          let limitSet = item?.max_limit_set?.toString().length > 1 ? item.max_limit_set : false;
          // if (item?.max_limit_set) {
          data = {
            minimum_value: Number(item.from_Quantity),
            maximum_value: !limitSet ? Number(item.to_Quantity) : MAX_LIMIT,
            discount_value: Number(item.discount_Value),
            max_limit_set: limitSet
          };
          return data;
        });
        return thresholdData;
      };
      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: threshold_selection,
          discount_value: discount_value || 0,
          max_discount: max_discount,
          applies_to_price: 'NONE',
          apply_discount_on: 'ORDER',
          product_reward: null,
          thresholds:
            threshold_selection === 'NO_THRESHOLD'
              ? null
              : {
                  threshold_list: getTresholdDataShipDisc()
                }
        }
      ];
      if (!_.isEmpty(promotionFormInitialValues)) {
        combData = combData?.map(item => ({
          ...item,
          discount_id: promotionFormInitialValues.discount[0].discount_id
        }));
      }
      return combData;
    }
    return [];
  };

  const addBundleData = () => {
    const {
      discount_type,
      selected_item,
      product_Name,
      category,
      unit_current_price,
      quantity,
      special_price,
      min_quantity,
      percent_discount_value,
      max_discount,
      max_quantity,
      discount_value, //for Bundle
      maximum_discount_value
    } = promotionForm.getFieldsValue();
    if (discount_type === 'SPL_PRICE') {
      let newData = {
        sku: selected_item,
        productName: product_Name,
        category: category,
        desc: `${selected_item} + ${product_Name}`,
        unit_current_price: unit_current_price || 0,
        quantity: quantity,
        special_price: special_price
      };
      if (
        !!specialPriceData?.data &&
        specialPriceData?.data?.map((item: any) => item.sku === selected_item).includes(true)
      ) {
        promotionForm.setFields([
          {
            name: 'selected_item',
            errors: ['Repeated Item, Please Select other']
          }
        ]);
      } else {
        if (specialPriceCategory.includes(category)) {
        } else {
          setSpecialPriceCategory([...specialPriceCategory, category]);
        }
        let oldData = specialPriceData?.data;
        let combData = {
          data: !oldData ? [newData] : [...oldData, newData]
        };
        setSpecialPriceData(combData);
        displaySuccessNotification({ message: 'Item Added To Selected Item' });
      }
    }
    if (discount_type === 'PERCENTAGE') {
      let newData = {
        sku: selected_item,
        productName: product_Name,
        category: category,
        desc: selected_item,
        unit_current_price: unit_current_price || 0,
        percent_discount_value: percent_discount_value,
        max_discount: max_discount || 0,
        min_quantity: min_quantity,
        max_quantity,
        discount_value,
        maximum_discount_value
      };
      if (
        !!percentPriceData?.data &&
        percentPriceData?.data?.map((item: any) => item.sku === selected_item).includes(true)
      ) {
        promotionForm.setFields([
          {
            name: 'selected_item',
            errors: ['Repeated Item, Please Select other']
          }
        ]);
      } else {
        if (percentPriceCategory.includes(category)) {
        } else {
          setPercentPriceCategory([...percentPriceCategory, category]);
        }
        let oldData = percentPriceData?.data;
        let combData = {
          data: !oldData ? [newData] : [...oldData, newData]
        };
        setPercentPriceData(combData);
        displaySuccessNotification({ message: 'Item Added To Selected Item' });
      }
    }
    if (discount_type === 'FIXED') {
      let newData = {
        sku: selected_item,
        productName: product_Name,
        category: category,
        desc: selected_item,
        unit_current_price: unit_current_price || 0,
        percent_discount_value: percent_discount_value,
        min_quantity: min_quantity,
        max_quantity,
        discount_value,
        maximum_discount_value
      };
      if (
        !!fixedPriceData?.data &&
        fixedPriceData?.data?.map((item: any) => item.sku === selected_item).includes(true)
      ) {
        promotionForm.setFields([
          {
            name: 'selected_item',
            errors: ['Repeated Item, Please Select other']
          }
        ]);
      } else {
        if (fixedPriceCategory.includes(category)) {
        } else {
          setFixedPriceCategory([...fixedPriceCategory, category]);
        }
        let oldData = fixedPriceData?.data;
        let combData = {
          data: !oldData ? [newData] : [...oldData, newData]
        };
        setfixedPriceData(combData);
        displaySuccessNotification({ message: 'Item Added To Selected Item' });
      }
    }
  };

  const addVolumeData = () => {
    const {
      discount_type,
      percent_discount_value_volume,
      max_discount_volume,
      apply_discount_on,
      isRange,
      volume_Threshold_qty,
      selected_item,
      category,
      product_Name,
      quantity,
      special_price,
      min_quantity_item_vol,
      max_quantity_item_vol,
      discount_value_item_vol,
      max_discount_item_vol,
      unit_current_price,
      percent_discount_value,
      min_quantity,
      min_discount_volume
    } = promotionForm.getFieldsValue();

    if (discount_type === 'SPL_PRICE') {
      let newData = {
        sku: selected_item,
        productName: product_Name,
        desc: `${selected_item} + ${product_Name}`,
        unit_current_price: unit_current_price || 0,
        quantity: quantity,
        max_discount_volume,
        isRange,
        volume_Threshold_qty,
        selected_item,
        category,
        product_Name,
        special_price,
        min_quantity_item_vol,
        max_quantity_item_vol,
        discount_value_item_vol,
        max_discount_item_vol,
        min_discount_volume
      };
      if (
        !!specialPriceVolumeData?.data &&
        specialPriceVolumeData?.data?.map((item: any) => item.sku === selected_item).includes(true)
      ) {
        promotionForm.setFields([
          {
            name: 'selected_item',
            errors: ['Repeated Item, Please Select other']
          }
        ]);
      } else {
        if (specialPriceVolumeCategory.includes(category)) {
        } else {
          setSpecialPriceVolumeCategory([...specialPriceVolumeCategory, category]);
        }
        let oldData = specialPriceVolumeData?.data;
        let combData = {
          data: !oldData ? [newData] : [...oldData, newData]
        };
        setSpecialPriceVolumeData(combData);
        displaySuccessNotification({ message: 'Item Added To Selected Item' });
      }
    }
    if (discount_type === 'PERCENTAGE') {
      let newData = {
        sku: selected_item,
        productName: product_Name,
        desc: selected_item,
        min_quantity: min_quantity,
        unit_current_price: unit_current_price || 0,
        percent_discount_value: percent_discount_value,
        max_discount_volume,
        isRange,
        volume_Threshold_qty,
        selected_item,
        category,
        product_Name,
        quantity,
        special_price,
        min_quantity_item_vol,
        max_quantity_item_vol,
        discount_value_item_vol,
        max_discount_item_vol,
        min_discount_volume
      };
      if (
        !!percentPriceVolumeData?.data &&
        percentPriceVolumeData?.data?.map((item: any) => item.sku === selected_item).includes(true)
      ) {
        promotionForm.setFields([
          {
            name: 'selected_item',
            errors: ['Repeated Item, Please Select other']
          }
        ]);
      } else {
        if (percentPriceVolumeCategory.includes(category)) {
        } else {
          setPercentPriceVolumeCategory([...percentPriceVolumeCategory, category]);
        }
        let oldData = percentPriceVolumeData?.data;
        let combData = {
          data: !oldData ? [newData] : [...oldData, newData]
        };
        setPercentPriceVolumeData(combData);
        displaySuccessNotification({ message: 'Item Added To Selected Item' });
      }
    }
    if (discount_type === 'FIXED') {
      let newData = {
        sku: selected_item,
        productName: product_Name,
        desc: selected_item,
        unit_current_price: unit_current_price || 0,
        percent_discount_value: percent_discount_value,
        min_quantity: min_quantity,
        max_discount_volume,
        isRange,
        volume_Threshold_qty,
        selected_item,
        category,
        product_Name,
        quantity,
        special_price,
        min_quantity_item_vol,
        max_quantity_item_vol,
        discount_value_item_vol,
        max_discount_item_vol,
        min_discount_volume
      };
      if (
        !!fixedPriceVolumeData?.data &&
        fixedPriceVolumeData?.data?.map((item: any) => item.sku === selected_item).includes(true)
      ) {
        promotionForm.setFields([
          {
            name: 'selected_item',
            errors: ['Repeated Item, Please Select other']
          }
        ]);
      } else {
        if (fixedPriceVolumeCategory.includes(category)) {
        } else {
          setFixedPriceVolumeCategory([...fixedPriceVolumeCategory, category]);
        }
        let oldData = fixedPriceVolumeData?.data;
        let combData = {
          data: !oldData ? [newData] : [...oldData, newData]
        };
        setFixedPriceVolumeData(combData);
        displaySuccessNotification({ message: 'Item Added To Selected Item' });
      }
    }
  };

  const addSubTotalDiscount = () => {
    const {
      discount_type,
      selected_item,
      product_Name,
      category,
      unit_current_price,
      special_price,
      percent_discount_value,
      quantity,
      min_quantity,
      max_discount,
      subtotal_treshold,
      max_quantity_item,
      max_discount_Value_item,
      percent_discount_value_item
    } = promotionForm.getFieldsValue();
    if (discount_type === 'PERCENTAGE') {
      let newData = {
        sku: selected_item,
        productName: product_Name,
        category: category,
        desc: selected_item,
        unit_current_price: unit_current_price || 0,
        max_discount: max_discount || 0,
        min_quantity: min_quantity,
        subtotal_treshold: subtotal_treshold,
        max_quantity_item: max_quantity_item,
        percent_discount_value_item: percent_discount_value_item || 0,
        max_discount_Value_item: max_discount_Value_item ? max_discount_Value_item : MAX_LIMIT
      };
      if (
        !!percentPriceSTData?.data &&
        percentPriceSTData?.data?.map((item: any) => item.sku === selected_item).includes(true)
      ) {
        promotionForm.setFields([
          {
            name: 'selected_item',
            errors: ['Repeated Item, Please Select other']
          }
        ]);
      } else {
        if (percentPriceSTCategory.includes(category)) {
        } else {
          setPercentPriceSTCategory([...percentPriceSTCategory, category]);
        }
        let oldData = percentPriceSTData?.data;
        let combData = {
          data: !oldData ? [newData] : [...oldData, newData]
        };
        setPercentPriceSTData(combData);
        displaySuccessNotification({ message: 'Item Added To Selected Item' });
      }
    }
    if (discount_type === 'FIXED') {
      let newData = {
        sku: selected_item,
        productName: product_Name,
        category: category,
        desc: selected_item,
        unit_current_price: unit_current_price || 0,
        percent_discount_value: percent_discount_value,
        min_quantity: min_quantity,
        subtotal_treshold: subtotal_treshold,
        max_quantity_item: max_quantity_item,
        percent_discount_value_item: percent_discount_value_item || 0,
        max_discount_Value_item: max_discount_Value_item ? max_discount_Value_item : MAX_LIMIT
      };
      if (
        !!fixedPriceSTData?.data &&
        fixedPriceSTData?.data?.map((item: any) => item.sku === selected_item).includes(true)
      ) {
        promotionForm.setFields([
          {
            name: 'selected_item',
            errors: ['Repeated Item, Please Select other']
          }
        ]);
      } else {
        if (fixedPriceSTCategory.includes(category)) {
        } else {
          setFixedPriceSTCategory([...fixedPriceSTCategory, category]);
        }
        let oldData = fixedPriceSTData?.data;
        let combData = {
          data: !oldData ? [newData] : [...oldData, newData]
        };
        setfixedPriceSTData(combData);
        displaySuccessNotification({ message: 'Item Added To Selected Item' });
      }
    }
  };

  const addDataToTable = () => {
    const { promotionType } = promotionForm.getFieldsValue();
    if (promotionType === 'BUNDLE_DISC') {
      addBundleData();
    }
    if (promotionType === 'VOLUME_DISC') {
      addVolumeData();
    }
    if (promotionType === 'SUB_TTL_DIS') {
      addSubTotalDiscount();
    }
    setActiveKey([]);
  };

  const handleOnAddItem = async () => {
    setLoading(true);
    try {
      // Get all values from the form to determine how many items are in `volume_Threshold_qty`
      const values = promotionForm.getFieldsValue();
      const volumeThresholds = values.volume_Threshold_qty || [];

      // Create an array of fields to validate
      const fieldsToValidate: (string | (string | number)[])[] = [
        'discount_type',
        'category',
        'selected_item',
        'product_Name',
        'unit_current_price',
        'quantity',
        'special_price',
        'min_quantity',
        'percent_discount_value',
        'max_quantity',
        'max_discount_Value_item',
        'percent_discount_value_item',
        'min_quantity_item_vol',
        'max_quantity_item_vol',
        'discount_value_item_vol',
        'max_discount_item_vol',
        'percent_discount_value_volume',
        'max_discount_volume',
        'discount_value', //for Bundle
        'maximum_discount_value',
        'max_quantity_item'
      ];

      // Add each Form.List field to the validation array
      volumeThresholds.forEach((_: any, index: any) => {
        fieldsToValidate.push(['volume_Threshold_qty', index, 'from_Quantity']);
        fieldsToValidate.push(['volume_Threshold_qty', index, 'to_Quantity']);
      });

      // Validate all specified fields together
      await promotionForm.validateFields(fieldsToValidate);

      addDataToTable();
    } catch (error) {
      console.log('addDataToTable', error);
      // displayErrorNotifications([{ message: 'Something Went Wrong !' }]);
    }
    setLoading(false);
  };

  const handleOnAddCategory = async () => {
    setLoading(true);
    try {
      const fieldsToValidate: (string | (string | number)[])[] = [
        'category',
        'minimum_quantity',
        'maximum_quantity',
        'discount_type',
        'special_price',
        'percent_discount_value',
        'max_discount',
        'discount_value',
        'max_quantity_item',
        'max_discount_Value_item',
        'maximum_discount_value_category'
      ];

      await promotionForm.validateFields(fieldsToValidate);
      handleOnAddCategoryData();
    } catch (error) {
      console.log('handleOnAddCategoryData', error);
      // displayErrorNotifications([{ message: 'Something Went Wrong !' }]);
    }
    setLoading(false);
  };

  const handleOnAddCategoryData = () => {
    const {
      discount_type,
      category,
      minimum_quantity,
      maximum_quantity,
      percent_discount_value,
      max_discount,
      volume_Threshold_qty,
      discount_value,
      max_discount_Value_item
    } = promotionForm.getFieldsValue();
    let newData = {
      category,
      minimum_quantity,
      maximum_quantity,
      percent_discount_value,
      max_discount,
      volume_Threshold_qty,
      discount_value,
      max_discount_Value_item,
      item_id: !!categoryData.data ? Number(categoryData?.data.length + 1) : 1
    };
    if (discount_type === 'PERCENTAGE') {
      let oldData = categoryData?.data;
      let combData = {
        data: !oldData ? [newData] : [...oldData, newData]
      };
      setCategoryData(combData);
    }
    if (discount_type === 'FIXED') {
      let oldData = categoryData?.data;
      let combData = {
        data: !oldData ? [newData] : [...oldData, newData]
      };
      setCategoryData(combData);
    }
    if (discount_type === 'SPL_PRICE') {
      let oldData = categoryData?.data;
      let combData = {
        data: !oldData ? [newData] : [...oldData, newData]
      };
      setCategoryData(combData);
    }
    displaySuccessNotification({ message: 'Category Added Successfully' });
  };

  const getBuyItems = (buyItems: any, getItems: any) => {
    const newEntry = {
      id: nextId,
      buyData: getItems, //Interchanged after Changes Informed
      getData: buyItems //Interchanged after Changes Informed
    };
    setBxgyData(prevData => [...prevData, newEntry]);
    setNextId(prevId => prevId + 1);
  };

  const addBXGYDataToTable = (values: any) => {
    getBuyItems(values.BXGY_buy, values.BXGY_get);
    clearFields();
  };

  const clearFields = () => {
    // Define the type for the fieldsToClear object
    const fieldsToClear: any = {};

    // Clear BXGY_buy fields
    const BXGY_buy = promotionForm.getFieldValue('BXGY_buy') || [];
    BXGY_buy.forEach((_: any, index: number) => {
      fieldsToClear[`BXGY_buy[${index}].selected_item`] = undefined;
      fieldsToClear[`BXGY_buy[${index}].category`] = undefined;
      fieldsToClear[`BXGY_buy[${index}].product_Name`] = undefined;
      fieldsToClear[`BXGY_buy[${index}].item_quantity`] = undefined;
      fieldsToClear[`BXGY_buy[${index}].unit_current_price`] = undefined;
    });

    // Clear BXGY_get fields
    const BXGY_get = promotionForm.getFieldValue('BXGY_get') || [];
    BXGY_get.forEach((_: any, index: number) => {
      fieldsToClear[`BXGY_get[${index}].selected_item`] = undefined;
      fieldsToClear[`BXGY_get[${index}].category`] = undefined;
      fieldsToClear[`BXGY_get[${index}].product_Name`] = undefined;
      fieldsToClear[`BXGY_get[${index}].item_quantity`] = undefined;
      fieldsToClear[`BXGY_get[${index}].unit_current_price`] = undefined;
    });

    // Set fields to cleared values
    promotionForm.setFieldsValue(fieldsToClear);
  };

  const handleOnAddBXGY = async () => {
    try {
      const values = promotionForm.getFieldsValue();
      const BXGY_buy = values.BXGY_buy || [];
      const BXGY_get = values.BXGY_get || [];
      const fieldsToValidate: (string | (string | number)[])[] = ['operator_selection'];

      BXGY_buy.forEach((_: any, index: number) => {
        fieldsToValidate.push(['BXGY_buy', index, 'selected_item']);
        fieldsToValidate.push(['BXGY_buy', index, 'category']);
        fieldsToValidate.push(['BXGY_buy', index, 'product_Name']);
        fieldsToValidate.push(['BXGY_buy', index, 'item_quantity']);
        fieldsToValidate.push(['BXGY_buy', index, 'unit_current_price']);
      });

      BXGY_get.forEach((_: any, index: number) => {
        fieldsToValidate.push(['BXGY_get', index, 'selected_item']);
        fieldsToValidate.push(['BXGY_get', index, 'category']);
        fieldsToValidate.push(['BXGY_get', index, 'product_Name']);
        fieldsToValidate.push(['BXGY_get', index, 'item_quantity']);
        fieldsToValidate.push(['BXGY_get', index, 'unit_current_price']);
        fieldsToValidate.push(['BXGY_get', index, 'special_price_BXGY_buy']);
        fieldsToValidate.push(['BXGY_get', index, 'minimum_Quantity_BXGY_buy']);
        fieldsToValidate.push(['BXGY_get', index, 'maximum_Quantity_BXGY_buy']);
        fieldsToValidate.push(['BXGYBXGY_get_buy', index, 'percent_discount_value']);
      });
      await promotionForm.validateFields(fieldsToValidate);

      addBXGYDataToTable(values);
    } catch (error) {
      console.log('addBXGYDataToTable', error);
      // displayErrorNotifications([{ message: 'Something Went Wrong !' }]);
    }
  };

  const onClickExpandSpecialPrice = (key: any) => {
    setActiveKey(key);
    let filterData = specialPriceData?.data?.filter((item: any) => {
      if (item?.category === key[0]) {
        return item;
      }
    });
    setSpecialPriceFilterData(filterData);
  };

  const onClickExpandSpecialPriceST = (key: any) => {
    setActiveKey(key);
    let filterData = specialPriceSTData?.data?.filter((item: any) => {
      if (item?.category === key[0]) {
        return item;
      }
    });
    setSpecialPriceSTFilterData(filterData);
  };

  const onClickExpandVolumeSpecialPrice = (key: any) => {
    setActiveKey(key);
    let filterData = specialPriceVolumeData?.data?.filter((item: any) => {
      if (item?.category === key[0]) {
        return item;
      }
    });
    setSpecialPriceVolumeFilterData(filterData);
  };

  const onClickExpandVolumePercentPrice = (key: any) => {
    setActiveKey(key);
    let filterData = percentPriceVolumeData?.data?.filter((item: any) => {
      if (item?.category === key[0]) {
        return item;
      }
    });
    setPercentPriceVolumeFilterData(filterData);
  };

  const onClickExpandVolumePercentPriceST = (key: any) => {
    setActiveKey(key);
    let filterData = percentPriceSTData?.data?.filter((item: any) => {
      if (item?.category === key[0]) {
        return item;
      }
    });
    setPercentPriceSTFilterData(filterData);
  };

  const onClickExpandVolumeFixedPriceST = (key: any) => {
    setActiveKey(key);
    let filterData = fixedPriceSTData?.data?.filter((item: any) => {
      if (item?.category === key[0]) {
        return item;
      }
    });
    setfixedPriceSTFilterData(filterData);
  };

  const onClickExpandVolumeFixedPrice = (key: any) => {
    setActiveKey(key);
    let filterData = fixedPriceVolumeData?.data?.filter((item: any) => {
      if (item?.category === key[0]) {
        return item;
      }
    });
    setFixedPriceVolumeFilterData(filterData);
  };

  const onClickExpandFixedPrice = (key: any) => {
    setActiveKey(key);
    let filterData = fixedPriceData?.data?.filter((item: any) => {
      if (item?.category === key[0]) {
        return item;
      }
    });
    setfixedPriceFilterData(filterData);
  };

  const onClickExpandPrecentPrice = (key: any) => {
    setActiveKey(key);
    let filterData = percentPriceData?.data?.filter((item: any) => {
      if (item?.category === key[0]) {
        return item;
      }
    });
    setPercentPriceFilterData(filterData);
  };

  const otherTableRenderer = (data: any) => {
    const { discount_type, promotionType, apply_discount_on } = promotionForm.getFieldsValue();
    return (
      <>
        {discount_type === 'SPL_PRICE' && (
          <Table
            className="mt-2"
            bordered
            columns={ExpandVolumeRowColumnspecialPrice}
            dataSource={data?.volume_Threshold_qty || []}
            pagination={false}
          />
        )}
        {discount_type === 'PERCENTAGE' && (
          <Table
            className="mt-2"
            bordered
            columns={ExpandVolumeRowColumnpercentPrice}
            dataSource={data?.volume_Threshold_qty || []}
            pagination={false}
          />
        )}
        {discount_type === 'FIXED' && (
          <Table
            className="mt-2"
            bordered
            columns={ExpandVolumeRowColumnfixedPrice}
            dataSource={data?.volume_Threshold_qty || []}
            pagination={false}
          />
        )}
      </>
    );
  };

  const volumeExpandTable = (data: any) => {
    const { discount_type, promotionType, apply_discount_on, volume_Threshold_qty } = promotionForm.getFieldsValue();

    let combdata = {
      volume_Threshold_qty: data?.volume_Threshold_qty
    };
    return <>{otherTableRenderer(combdata)}</>;
  };

  const expandedRowRender = (data: any) => {
    const { promotionType, apply_discount_on } = promotionForm.getFieldsValue();
    let isVolumeCategory = promotionType === 'VOLUME_DISCOUNT' && apply_discount_on === 'CATEGORY';
    if (promotionType === 'SUB_TTL_DIS') {
      return (
        <>
          <Table
            className="mt-2"
            bordered
            columns={ExpandVolumeRowColumnpercentPrice}
            dataSource={data?.subtotal_treshold || []}
            pagination={false}
          />
        </>
      );
    } else {
      return !isVolumeCategory ? (
        <section className="pl-8">{volumeExpandTable(data)}</section>
      ) : (
        <section className="pl-8">{otherTableRenderer(data)}</section>
      );
    }
  };
  //THE UI
  const subTotalDiscountUIRenderer = (discount_type: any, appliesTo: any) => {
    return (
      <>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Select discount type"
              name={'discount_type'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                size="large"
                disabled={disableFields()}
                options={[
                  { label: 'Perc Discount', value: 'PERCENTAGE' },
                  { label: 'Absolute Discount', value: 'FIXED' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          {appliesTo !== 'ORDER_TOTAL' && (
            <>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Apply Discount On"
                  name={'apply_discount_on'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Select
                    placeholder="Select"
                    disabled={disableFields()}
                    size="large"
                    options={[
                      { label: 'Products', value: 'PRODUCTS' },
                      { label: 'Category', value: 'CATEGORY' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.apply_discount_on !== curValues.apply_discount_on;
                }}
              >
                {form => {
                  const { apply_discount_on } = form.getFieldsValue();
                  return (
                    apply_discount_on === 'CATEGORY' && (
                      <Col xs={12} md={6}>
                        <Form.Item
                          label="Minimum Line Items"
                          name={'min_line_items'}
                          rules={[
                            { required: true, message: "This field can't be empty" },
                            getFormattedNumeric2DecimalPointValidatorForInput({
                              message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                            })
                          ]}
                        >
                          <InputNumber min={1} size="large" placeholder="Minimum Line Items" className="w-full" />
                        </Form.Item>
                      </Col>
                    )
                  );
                }}
              </Form.Item>
            </>
          )}

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.threshold_selection !== curValues.threshold_selection;
            }}
          >
            {({ getFieldValue }) => {
              const threshold = getFieldValue('threshold_selection');
              if (threshold === 'MOV' || threshold === 'MOQ') {
                return null;
              }
              return (
                <>
                  {discount_type === 'PERCENTAGE' && appliesTo === 'ORDER_TOTAL' && (
                    <>
                      <Col xs={12} md={6}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.apply_discount_on !== curValues.apply_discount_on
                          }
                        >
                          {({ getFieldValue }) => {
                            return (
                              <Form.Item
                                label="Discount Value"
                                name="percent_discount_value_item"
                                rules={[
                                  { required: true, message: "This field can't be empty" },
                                  getFormattedNumeric2DecimalPointValidatorForInput({
                                    message:
                                      'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                  }),
                                  {
                                    validator: async (_: any, value: any) => {
                                      const discountType = getFieldValue('discount_type');
                                      if (discountType === 'PERCENTAGE' && value > 100) {
                                        return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                      }
                                      return Promise.resolve();
                                    }
                                  }
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Discount Value"
                                  onBlur={formatToTwoDecimalPlaces}
                                  disabled={disableFields()}
                                  // suffix={<Tooltip title="Percent">%</Tooltip>}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label="Maximum Discount"
                          name={'max_discount'}
                          rules={[
                            { required: true, message: "This field can't be empty" },
                            getFormattedNumeric2DecimalPointValidatorForInput({
                              message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                            })
                          ]}
                        >
                          <InputNumber
                            min={1}
                            disabled={disableFields()}
                            size="large"
                            placeholder="Maximum Discount"
                            className="w-full"
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {discount_type === 'FIXED' && appliesTo === 'ORDER_TOTAL' && (
                    <>
                      <Col xs={12} md={6}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.apply_discount_on !== curValues.apply_discount_on
                          }
                        >
                          {({ getFieldValue }) => {
                            const applyDiscountOn = getFieldValue('apply_discount_on');
                            const isCategorySelected = applyDiscountOn === 'CATEGORY';
                            return (
                              <Form.Item
                                label="Discount Value"
                                name="percent_discount_value"
                                rules={[
                                  { required: !isCategorySelected, message: "This field can't be empty" },
                                  getFormattedNumeric2DecimalPointValidatorForInput({
                                    message:
                                      'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                  }),
                                  {
                                    validator: async (_: any, value: any) => {
                                      const discountType = getFieldValue('discount_type');
                                      if (discountType === 'PERCENTAGE' && value > 100) {
                                        return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                      }
                                      return Promise.resolve();
                                    }
                                  }
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Discount Value"
                                  onBlur={formatToTwoDecimalPlaces}
                                  disabled={disableFields()}
                                  // suffix={<Tooltip title="Percent">%</Tooltip>}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </>
              );
            }}
          </Form.Item>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.appliesTo !== curValues.appliesTo;
          }}
        >
          {form => {
            return (
              appliesTo !== 'LINE_ITEMS' && (
                <>
                  <Row gutter={12}>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Threshold Selection"
                        name={'threshold_selection'}
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Select
                          allowClear
                          placeholder="Select"
                          size="large"
                          options={getTresholdOptionsforSubTotal()}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return (
                        prevValues.threshold_selection !== curValues.threshold_selection ||
                        prevValues.subtotal_treshold !== curValues.subtotal_treshold
                      );
                    }}
                  >
                    {({ getFieldValue, setFieldsValue }) => {
                      const thresholdSelection = getFieldValue('threshold_selection');
                      const subtotalThreshold = getFieldValue('subtotal_treshold') || [];

                      if (
                        thresholdSelection &&
                        thresholdSelection !== 'NO_THRESHOLD' &&
                        subtotalThreshold.length === 0
                      ) {
                        setFieldsValue({
                          subtotal_treshold: [{ minimum_value: null, max_limit_set: false }]
                        });
                      }

                      let previousMaxValue = 0;
                      if (subtotalThreshold?.length > 0) {
                        const lastItem = subtotalThreshold[subtotalThreshold.length - 1];
                        previousMaxValue = lastItem.maximum_value || 0;
                      }

                      return (
                        thresholdSelection &&
                        thresholdSelection !== 'NO_THRESHOLD' && (
                          <Form.List name="subtotal_treshold">
                            {(fields, { add, remove }) => (
                              <>
                                {/* <Typography.Title level={4}>SubTotal Threshold:</Typography.Title> */}
                                {fields?.map(({ key, name, fieldKey, ...restField }, index) => {
                                  const maxLimitSet = getFieldValue(['subtotal_treshold', name, 'max_limit_set']);
                                  const isLastItem = index === fields.length - 1;
                                  const currentPreviousMaxValue =
                                    index > 0
                                      ? getFieldValue(['subtotal_treshold', fields[index - 1].name, 'maximum_value'])
                                      : 0;

                                  return (
                                    <div key={key} {...restField}>
                                      <Row gutter={12}>
                                        <Col xs={12} md={6}>
                                          <Form.Item
                                            label="Minimum Value"
                                            name={[name, 'minimum_value']}
                                            rules={[
                                              { required: true, message: "This field can't be empty" },
                                              {
                                                validator: (_, value) => {
                                                  if (value >= currentPreviousMaxValue) {
                                                    return Promise.resolve();
                                                  }
                                                  return Promise.reject(
                                                    new Error(
                                                      `Minimum Value must be greater than ${currentPreviousMaxValue}`
                                                    )
                                                  );
                                                }
                                              },
                                              getFormattedNumeric2DecimalPointValidatorForInput({
                                                message:
                                                  'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                              })
                                            ]}
                                          >
                                            <InputNumber
                                              min={currentPreviousMaxValue}
                                              size="large"
                                              placeholder="Minimum Value"
                                              className="w-full"
                                            />
                                          </Form.Item>
                                        </Col>
                                        {!maxLimitSet && (
                                          <Col xs={12} md={6}>
                                            <Form.Item
                                              label="Maximum Value"
                                              name={[name, 'maximum_value']}
                                              rules={[
                                                { required: true, message: "This field can't be empty" },
                                                {
                                                  validator: (_, value) => {
                                                    const minValue = getFieldValue([
                                                      'subtotal_treshold',
                                                      name,
                                                      'minimum_value'
                                                    ]);
                                                    if (value >= minValue) {
                                                      return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                      new Error('Maximum Value must be greater than Minimum Value')
                                                    );
                                                  }
                                                },
                                                getFormattedNumeric2DecimalPointValidatorForInput({
                                                  message:
                                                    'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                })
                                              ]}
                                            >
                                              <InputNumber
                                                min={1}
                                                size="large"
                                                placeholder="Maximum Value"
                                                className="w-full"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}
                                        <Col xs={12} md={6}>
                                          <Form.Item
                                            label="Discount Value"
                                            name={[name, 'discount_value']}
                                            rules={[
                                              { required: true, message: "This field can't be empty" },
                                              getFormattedNumeric2DecimalPointValidatorForInput({
                                                message:
                                                  'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                              }),
                                              {
                                                validator: async (_: any, value: any) => {
                                                  const discountType = getFieldValue('discount_type');
                                                  if (discountType === 'PERCENTAGE' && value > 100) {
                                                    return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                                  }
                                                  return Promise.resolve();
                                                }
                                              }
                                            ]}
                                          >
                                            <Input
                                              size="large"
                                              placeholder="Discount Value"
                                              onBlur={formatToTwoDecimalPlaces}
                                            />
                                          </Form.Item>
                                        </Col>
                                        {isLastItem && (
                                          <Col xs={12} md={3} className="mt-8">
                                            <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                              <Checkbox>Max Limit Set</Checkbox>
                                            </Form.Item>
                                          </Col>
                                        )}
                                        <Col xs={12} md={2}>
                                          <div className="mx-4 h-full flex justify-center items-center">
                                            <DeleteOutlined
                                              className="text-red-500 text-xl cursor-pointer"
                                              role="button"
                                              title="Remove"
                                              onClick={() => remove(name)}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                })}
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => add({ minimum_value: previousMaxValue, max_limit_set: false })}
                                    className="mt-2"
                                    disabled={fields.some(field =>
                                      getFieldValue(['subtotal_treshold', field.name, 'max_limit_set'])
                                    )}
                                  >
                                    Add
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        )
                      );
                    }}
                  </Form.Item>
                </>
              )
            );
          }}
        </Form.Item>

        {appliesTo !== 'ORDER_TOTAL' && (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.apply_discount_on !== curValues.apply_discount_on;
            }}
          >
            {form => {
              const { apply_discount_on } = form.getFieldsValue();
              return apply_discount_on === 'CATEGORY' ? (
                <>
                  <Card>
                    {!viewOnly && (
                      <>
                        <Row gutter={12}>
                          <Col xs={12} md={6}>
                            <Form.Item
                              label="Select Category"
                              name={'category'}
                              rules={[{ required: true, message: "This field can't be empty" }]}
                            >
                              <Select
                                placeholder="Select"
                                allowClear
                                size="large"
                                options={categoryProductOptions || []}
                              ></Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={12}>
                          <Col xs={12} md={6}>
                            <Form.Item
                              label="Minimum Quantity"
                              name={'minimum_quantity'}
                              rules={[
                                { required: true, message: "This field can't be empty" },
                                getFormattedNumeric2DecimalPointValidatorForInput({
                                  message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                })
                              ]}
                            >
                              <InputNumber min={1} size="large" placeholder="Minimum Quantity" className="w-full" />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Item
                              label="Maximum Quantity"
                              name={'maximum_quantity'}
                              rules={[
                                { required: true, message: "This field can't be empty" },
                                getFormattedNumeric2DecimalPointValidatorForInput({
                                  message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                })
                              ]}
                            >
                              <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Item
                              label="Discount Value"
                              name={'discount_value'}
                              rules={[
                                {
                                  required: true,
                                  message: "This field can't be empty"
                                },
                                {
                                  // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                  validator: async (_, value) => {
                                    const discountType = promotionForm.getFieldValue('discount_type');
                                    if (discountType === 'PERCENTAGE' && value > 100) {
                                      return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                    }
                                    return Promise.resolve();
                                  }
                                },
                                getFormattedNumeric2DecimalPointValidatorForInput({
                                  message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                })
                              ]}
                            >
                              <InputNumber min={1} size="large" placeholder="Discount Value" className="w-full" />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Item
                              label="Maximum Discount Value"
                              name={'max_discount_Value_item'}
                              rules={[
                                { required: false, message: "This field can't be empty" },
                                getFormattedNumeric2DecimalPointValidatorForInput({
                                  message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                })
                              ]}
                            >
                              <InputNumber
                                min={1}
                                size="large"
                                placeholder="Maximum Discount Value"
                                className="w-full"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row justify={'start'}>
                          <Col xs={24} md={4}>
                            <Button onClick={handleOnAddCategory} icon={<PlusOutlined />} type="primary">
                              <BoldButtonLabel labelText="Add Category" />
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                    <section className="mt-4">
                      <Table
                        className="mt-2"
                        bordered
                        columns={categoryColumns}
                        dataSource={categoryData?.data || []}
                        pagination={false}
                        scroll={{ x: 1000 }}
                      />
                    </section>
                  </Card>
                </>
              ) : (
                <Card>
                  {!viewOnly && (
                    <>
                      <Typography.Title level={4}>Add Item</Typography.Title>
                      <Row gutter={12} className="mt-4">
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Select Category"
                            name={'category'}
                            rules={[{ required: true, message: "This field can't be empty" }]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              size="large"
                              disabled={isCategoryDisabled}
                              options={categoryProductOptions || []}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Select Item"
                            name={'selected_item'}
                            rules={[
                              validationHelpers.getRequiredValidator({
                                message: 'This field is required'
                              })
                            ]}
                          >
                            <Select
                              allowClear
                              showSearch
                              className="w-full"
                              size="large"
                              onSearch={async searchTerm => {
                                let category = promotionForm.getFieldsValue().category;
                                if (searchTerm) await debouncedFetchProductDropdownOptions(searchTerm, category);
                              }}
                              onSelect={(curData: any, nextData: any) => {
                                const item_Price = nextData?.itemData?.unit_price || '';
                                const product_name = nextData?.itemData?.product_name || '';
                                const category = nextData?.itemData?.category || '';
                                promotionForm.setFieldsValue({
                                  unit_current_price: item_Price,
                                  product_Name: product_name,
                                  category: category
                                });
                                setIsCategoryDisabled(true);
                              }}
                              onClear={() => {
                                setIsCategoryDisabled(false);
                                promotionForm.setFieldsValue({
                                  category: null,
                                  product_Name: null,
                                  unit_current_price: null
                                });
                              }}
                              filterOption={false}
                              placeholder="Search Item"
                              notFoundContent={productDropdownFetching ? <Spin size="small" /> : <>No Data Found</>}
                              options={productDropdownOptions}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Product Name"
                            name={'product_Name'}
                            rules={[{ required: true, message: "This field can't be empty" }]}
                          >
                            <Input size="large" placeholder="Product Name" disabled={true} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Unit Price"
                            name={'unit_current_price'}
                            rules={[{ required: true, message: "This field can't be empty" }]}
                          >
                            <Input size="large" placeholder="Unit Price" disabled={true} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Minimum Quantity"
                            name={'min_quantity'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              }),
                              {
                                validator: async (_, value) => {
                                  const limitPerUser = Number(promotionForm.getFieldValue('max_quantity_item'));
                                  let crv = Number(value);
                                  if (crv > limitPerUser) {
                                    return Promise.reject(
                                      new Error('Minimum Quantity is Greater then Maximum Quantity')
                                    );
                                  }
                                  return Promise.resolve();
                                }
                              }
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Minimum Quantity" className="w-full" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Maximum Quantity"
                            name={'max_quantity_item'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              }),
                              {
                                validator: async (_, value) => {
                                  const limitPerUser = Number(promotionForm.getFieldValue('min_quantity'));
                                  let crv = Number(value);
                                  if (crv < limitPerUser) {
                                    return Promise.reject(
                                      new Error('Minimum Quantity is Greater then Maximum Quantity')
                                    );
                                  }
                                  return Promise.resolve();
                                }
                              }
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Discount Value"
                            name={'percent_discount_value_item'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              {
                                // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                validator: async (_, value) => {
                                  const discountType = promotionForm.getFieldValue('discount_type');
                                  if (discountType === 'PERCENTAGE' && value > 100) {
                                    return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                  }
                                  return Promise.resolve();
                                }
                              },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Discount Value" className="w-full" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Maximum Discount Value"
                            name={'max_discount_Value_item'}
                            rules={[
                              { required: false, message: "This field can't be empty" },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Maximum Discount Value" className="w-full" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify={'start'} className={'mb-2'}>
                        <Col xs={24} md={4}>
                          <Button onClick={handleOnAddItem} icon={<PlusOutlined />} type="primary">
                            <BoldButtonLabel labelText="Add" />
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  {/* TABLE - SubTotal */}
                  <>
                    {discount_type === 'SPL_PRICE' && specialPriceSTCategory?.length > 0 && (
                      <Card>
                        <Row gutter={16}>
                          <Col xs={12} md={4}>
                            <Card bordered className="">
                              <Statistic
                                title="Total Category Selected"
                                value={specialPriceSTCategory.length}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                              />
                            </Card>
                          </Col>
                          <Col xs={12} md={4}>
                            <Card bordered className="">
                              <Statistic
                                title="Total Items Selected"
                                value={specialPriceSTData?.data?.length}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                              />
                            </Card>
                          </Col>
                          <Col xs={12} md={4}>
                            <Card bordered className="">
                              <Statistic
                                title="Offer Price"
                                value={getTotalSpecialPriceforST()}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                              />
                            </Card>
                          </Col>
                        </Row>
                        <Row gutter={12} className="mt-4">
                          <Col xs={24} md={24}>
                            <Card>
                              <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                              <Collapse accordion activeKey={activeKey} onChange={onClickExpandSpecialPriceST}>
                                {specialPriceSTCategory?.map((item: any) => {
                                  return (
                                    <>
                                      <Panel header={item} key={item}>
                                        <section className="mt-4">
                                          <Table
                                            bordered
                                            expandable={{
                                              expandedRowRender,
                                              rowExpandable: record => record?.subtotal_treshold
                                            }}
                                            className="mt-4"
                                            dataSource={specialPriceSTFilterData || []}
                                            columns={SpecialPriceSTColumns(
                                              specialPriceSTEditRecord,
                                              setSpecialPriceSTEditRecord,
                                              handleSaveSpecialST
                                            )}
                                            scroll={{ x: 1000 }}
                                            pagination={false}
                                          ></Table>
                                        </section>
                                      </Panel>
                                    </>
                                  );
                                })}
                              </Collapse>
                            </Card>
                          </Col>
                        </Row>
                      </Card>
                    )}
                    {discount_type === 'PERCENTAGE' && percentPriceSTCategory?.length > 0 && (
                      <Card>
                        <Row gutter={16}>
                          <Col xs={12} md={4}>
                            <Card bordered className="">
                              <Statistic
                                title="Total Category Selected"
                                value={percentPriceSTCategory.length}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                              />
                            </Card>
                          </Col>
                          <Col xs={12} md={4}>
                            <Card bordered className="">
                              <Statistic
                                title="Total Items Selected"
                                value={percentPriceSTData?.data?.length}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                              />
                            </Card>
                          </Col>
                        </Row>
                        <Row gutter={12} className="mt-4">
                          <Col xs={24} md={24}>
                            <Card>
                              <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                              <Collapse accordion activeKey={activeKey} onChange={onClickExpandVolumePercentPriceST}>
                                {percentPriceSTCategory?.map((item: any) => {
                                  return (
                                    <Panel header={item} key={item}>
                                      <section className="mt-4">
                                        <Table
                                          bordered
                                          expandable={{
                                            expandedRowRender,
                                            rowExpandable: record => record?.subtotal_treshold
                                          }}
                                          className="mt-4"
                                          dataSource={percentPriceSTFilterData || []}
                                          columns={PercentPriceSTColumns(
                                            percentPriceSTEditRecord,
                                            setPercentPriceSTEditRecord,
                                            handleSavePercentST
                                          )}
                                          scroll={{ x: 1000 }}
                                          pagination={false}
                                        ></Table>
                                      </section>
                                    </Panel>
                                  );
                                })}
                              </Collapse>
                            </Card>
                          </Col>
                        </Row>
                      </Card>
                    )}
                    {discount_type === 'FIXED' && fixedPriceSTCategory?.length > 0 && (
                      <Card>
                        <Row gutter={16}>
                          <Col xs={12} md={4}>
                            <Card bordered className="">
                              <Statistic
                                title="Total Category Selected"
                                value={fixedPriceSTCategory.length}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                              />
                            </Card>
                          </Col>
                          <Col xs={12} md={4}>
                            <Card bordered className="">
                              <Statistic
                                title="Total Items Selected"
                                value={fixedPriceSTData?.data?.length}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                              />
                            </Card>
                          </Col>
                        </Row>
                        <Row gutter={12} className="mt-4">
                          <Col xs={24} md={24}>
                            <Card>
                              <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                              <Collapse accordion activeKey={activeKey} onChange={onClickExpandVolumeFixedPriceST}>
                                {fixedPriceSTCategory?.map((item: any) => {
                                  return (
                                    <Panel header={item} key={item}>
                                      <section className="mt-4">
                                        <Table
                                          bordered
                                          expandable={{
                                            expandedRowRender,
                                            rowExpandable: record => record?.subtotal_treshold
                                          }}
                                          className="mt-4"
                                          dataSource={fixedPriceSTFilterData || []}
                                          columns={FixedPriceColumnsST(
                                            FixedPriceSTEditRecord,
                                            setFixedPriceSTEditRecord,
                                            handleSaveFixedST
                                          )}
                                          scroll={{ x: 1000 }}
                                          pagination={false}
                                        ></Table>
                                      </section>
                                    </Panel>
                                  );
                                })}
                              </Collapse>
                            </Card>
                          </Col>
                        </Row>
                      </Card>
                    )}
                  </>
                </Card>
              );
            }}
          </Form.Item>
        )}
      </>
    );
  };

  const VolumeUIRenderer = (discount_type: any, promotionType: any) => {
    return (
      <>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Select discount type"
              name={'discount_type'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                size="large"
                allowClear
                disabled={disableFields()}
                options={[
                  { label: 'Perc Discount', value: 'PERCENTAGE' },
                  { label: 'Absolute Discount', value: 'FIXED' },
                  { label: 'Special Price', value: 'SPL_PRICE' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Apply Discount On"
              name={'apply_discount_on'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                disabled={disableFields()}
                size="large"
                options={[
                  { label: 'Products', value: 'PRODUCTS' },
                  { label: 'Category', value: 'CATEGORY' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item name={'isRange'} valuePropName="checked">
              <Checkbox value={'range'} onClick={handleOnSelectRange} disabled={disableFields()}>
                Volume Threshold in range?
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return (
              prevValues.volume_Threshold_qty !== curValues.volume_Threshold_qty ||
              prevValues.isRange !== curValues.isRange
            );
          }}
        >
          {({ getFieldValue, setFieldsValue }) => {
            const volumeThresholdQty = getFieldValue('volume_Threshold_qty') || [];
            const maxLimitSetChecked = volumeThresholdQty.some((field: any) => field.max_limit_set);
            const isRange = getFieldValue('isRange');

            if (!isRange) {
              return null;
            }

            let previousMaxValue = 0;
            if (volumeThresholdQty?.length > 0) {
              const lastItem = volumeThresholdQty[volumeThresholdQty.length - 1];
              previousMaxValue = lastItem.to_Quantity || 0;
            }

            return (
              !viewOnly && (
                <Form.List name="volume_Threshold_qty">
                  {(fields, { add, remove }) => (
                    <>
                      <Typography.Title level={4}>Volume Threshold:</Typography.Title>
                      {fields?.map(({ key, name, fieldKey, ...restField }, index) => {
                        const maxLimitSet = getFieldValue(['volume_Threshold_qty', name, 'max_limit_set']);
                        const currentPreviousMaxValue =
                          index > 0
                            ? getFieldValue(['volume_Threshold_qty', fields[index - 1].name, 'to_Quantity'])
                            : 0;

                        return (
                          <div key={key} {...restField}>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="From Quantity"
                                  name={[name, 'from_Quantity']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      validator: (_, value) => {
                                        if (value >= currentPreviousMaxValue) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(
                                          new Error(`Minimum Value must be greater than ${currentPreviousMaxValue}`)
                                        );
                                      }
                                    },
                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                      message:
                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                    })
                                  ]}
                                >
                                  <InputNumber
                                    min={currentPreviousMaxValue}
                                    size="large"
                                    placeholder="From Quantity"
                                    className="w-full"
                                  />
                                </Form.Item>
                              </Col>
                              {!maxLimitSet && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label="To Quantity"
                                    name={[name, 'to_Quantity']}
                                    rules={[
                                      { required: true, message: "This field can't be empty" },
                                      {
                                        validator: (_, value) => {
                                          const minValue = getFieldValue([
                                            'volume_Threshold_qty',
                                            name,
                                            'from_Quantity'
                                          ]);
                                          if (value >= minValue) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(
                                            new Error('Maximum Value must be greater than Minimum Value')
                                          );
                                        }
                                      },
                                      getFormattedNumeric2DecimalPointValidatorForInput({
                                        message:
                                          'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                      })
                                    ]}
                                  >
                                    <InputNumber
                                      min={1}
                                      size="large"
                                      placeholder="To Quantity"
                                      className="w-full"
                                      value={
                                        maxLimitSet
                                          ? 999999
                                          : getFieldValue(['volume_Threshold_qty', name, 'to_Quantity'])
                                      }
                                      disabled={maxLimitSet}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Discount Value"
                                  name={[name, 'volume_value']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      validator: async (_: any, value: any) => {
                                        const discountType = getFieldValue('discount_type');
                                        if (discountType === 'PERCENTAGE' && value > 100) {
                                          return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                        }
                                        return Promise.resolve();
                                      }
                                    },
                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                      message:
                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                    })
                                  ]}
                                >
                                  <InputNumber min={1} size="large" placeholder="Value" className="w-full" />
                                </Form.Item>
                              </Col>
                              {index === fields.length - 1 && (
                                <Col xs={12} md={3} className="mt-8">
                                  <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                    <Checkbox
                                      onChange={e => {
                                        const checked = e.target.checked;
                                        setFieldsValue({
                                          [`volume_Threshold_qty.${name}.to_Quantity`]: checked ? 999999 : null
                                        });
                                      }}
                                    >
                                      Max Limit Set
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                              )}
                              {fields.length > 1 && (
                                <Col xs={12} md={2}>
                                  <div className="mx-4 h-full flex justify-center items-center">
                                    <DeleteOutlined
                                      className="text-red-500 text-xl cursor-pointer"
                                      role="button"
                                      title="Remove"
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => add({ from_Quantity: previousMaxValue, max_limit_set: false })}
                          className="mt-2"
                          disabled={maxLimitSetChecked} // Disable button if any max_limit_set is true
                        >
                          Add Quantity
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              )
            );
          }}
        </Form.Item>

        {/* Volume Table */}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.trigger !== curValues.trigger || prevValues.isRange !== curValues.isRange;
          }}
        >
          {form => {
            const { apply_discount_on, discount_type, isRange } = form.getFieldsValue();
            return apply_discount_on === 'CATEGORY' ? (
              <>
                <Card>
                  {!viewOnly && (
                    <>
                      <Row gutter={12}>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Select Category"
                            name={'category'}
                            rules={[{ required: true, message: "This field can't be empty" }]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              size="large"
                              options={categoryProductOptions || []}
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        {!isRange && (
                          <>
                            <Col xs={12} md={6}>
                              <Form.Item
                                label="Minimum Quantity"
                                name={'minimum_quantity'}
                                rules={[
                                  { required: true, message: "This field can't be empty" },
                                  getFormattedNumeric2DecimalPointValidatorForInput({
                                    message:
                                      'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                  })
                                ]}
                              >
                                <InputNumber min={1} size="large" placeholder="Minimum Quantity" className="w-full" />
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                label="Maximum Quantity"
                                name={'maximum_quantity'}
                                rules={[
                                  { required: true, message: "This field can't be empty" },
                                  getFormattedNumeric2DecimalPointValidatorForInput({
                                    message:
                                      'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                  })
                                ]}
                              >
                                <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                label="Discount Value"
                                name={'discount_value'}
                                rules={[
                                  { required: true, message: "This field can't be empty" },
                                  {
                                    // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                    validator: async (_, value) => {
                                      const discountType = promotionForm.getFieldValue('discount_type');
                                      if (discountType === 'PERCENTAGE' && value > 100) {
                                        return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                      }
                                      return Promise.resolve();
                                    }
                                  },
                                  getFormattedNumeric2DecimalPointValidatorForInput({
                                    message:
                                      'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                  })
                                ]}
                              >
                                <InputNumber min={1} size="large" placeholder="Discount Value" className="w-full" />
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                label="Maximum Discount Value"
                                name={'max_discount'}
                                rules={[
                                  { required: false, message: "This field can't be empty" },
                                  getFormattedNumeric2DecimalPointValidatorForInput({
                                    message:
                                      'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                  })
                                ]}
                              >
                                <InputNumber
                                  min={1}
                                  size="large"
                                  placeholder="Maximum Discount Value"
                                  className="w-full"
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row justify={'start'}>
                        <Col xs={24} md={4}>
                          <Button onClick={handleOnAddCategory} icon={<PlusOutlined />} type="primary">
                            <BoldButtonLabel labelText="Add Category" />
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  <section className="mt-4">
                    <Table
                      expandable={{
                        expandedRowRender,
                        rowExpandable: record => {
                          return record.volume_Threshold_qty;
                        }
                      }}
                      className="mt-2"
                      bordered
                      columns={volumeCategoryColumns}
                      dataSource={categoryData?.data || []}
                      pagination={false}
                      scroll={{ x: 1000 }}
                      rowKey={(record: any) => {
                        return record.category;
                      }}
                    />
                  </section>
                </Card>
              </>
            ) : (
              <>
                {!viewOnly && (
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.isRange !== curValues.isRange;
                    }}
                  >
                    {form => {
                      const { isRange } = form.getFieldsValue();
                      return (
                        <>
                          <Card>
                            <Typography.Title level={4}>Add Item:</Typography.Title>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Select Category"
                                  name={'category'}
                                  rules={[{ required: true, message: "This field can't be empty" }]}
                                >
                                  <Select
                                    placeholder="Select"
                                    allowClear
                                    size="large"
                                    disabled={isCategoryDisabled}
                                    options={categoryProductOptions || []}
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Select Item"
                                  name={'selected_item'}
                                  rules={[
                                    validationHelpers.getRequiredValidator({
                                      message: 'This field is required'
                                    })
                                  ]}
                                >
                                  <Select
                                    allowClear
                                    showSearch
                                    className="w-full"
                                    size="large"
                                    onSearch={async searchTerm => {
                                      let category = promotionForm.getFieldsValue().category;
                                      if (searchTerm) await debouncedFetchProductDropdownOptions(searchTerm, category);
                                    }}
                                    onSelect={(curData: any, nextData: any) => {
                                      const item_Price = nextData?.itemData?.unit_price || '';
                                      const product_name = nextData?.itemData?.product_name || '';
                                      const category = nextData?.itemData?.category || '';
                                      promotionForm.setFieldsValue({
                                        unit_current_price: item_Price,
                                        product_Name: product_name,
                                        category: category
                                      });
                                      setIsCategoryDisabled(true);
                                    }}
                                    onClear={() => {
                                      setIsCategoryDisabled(false);
                                      promotionForm.setFieldsValue({
                                        category: null,
                                        product_Name: null,
                                        unit_current_price: null
                                      });
                                    }}
                                    filterOption={false}
                                    placeholder="Search Item"
                                    notFoundContent={
                                      productDropdownFetching ? <Spin size="small" /> : <>No Data Found</>
                                    }
                                    options={productDropdownOptions}
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Product Name"
                                  name={'product_Name'}
                                  rules={[{ required: true, message: "This field can't be empty" }]}
                                >
                                  <Input size="large" placeholder="Product Name" disabled={true} />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Unit Price"
                                  name={'unit_current_price'}
                                  rules={[{ required: true, message: "This field can't be empty" }]}
                                >
                                  <Input size="large" placeholder="Unit Price" disabled={true} />
                                </Form.Item>
                              </Col>
                              {discount_type !== 'SPL_PRICE' && !isRange && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label="Minimum Quantity"
                                    name={'min_quantity_item_vol'}
                                    rules={[
                                      { required: true, message: "This field can't be empty" },
                                      validationHelpers.getNumericValidatorForInput({})
                                    ]}
                                  >
                                    <Input size="large" placeholder="Minimum Quantity" disabled={false} />
                                  </Form.Item>
                                </Col>
                              )}
                              {discount_type !== 'SPL_PRICE' && !isRange && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label="Maximum Quantity"
                                    name={'max_quantity_item_vol'}
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field can't be empty"
                                      },
                                      validationHelpers.getNumericValidatorForInput({})
                                    ]}
                                  >
                                    <Input size="large" placeholder="Maximum Quantity" disabled={false} />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                            <Row gutter={12}>
                              {discount_type === 'SPL_PRICE' && !isRange && (
                                <>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      label="Quantity"
                                      name={'quantity'}
                                      rules={[{ required: true, message: "This field can't be empty" }]}
                                    >
                                      <Input size="large" placeholder="Quantity" disabled={false} />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      label="Special Prices"
                                      name={'special_price'}
                                      rules={[
                                        { required: true, message: "This field can't be empty" },
                                        validationHelpers.getNumericValidatorForInput({})
                                      ]}
                                    >
                                      <Input size="large" placeholder="Special Price" disabled={false} />
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row gutter={12}>
                              {discount_type !== 'SPL_PRICE' && !isRange && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label="Discount Value"
                                    name={'discount_value_item_vol'}
                                    rules={[
                                      { required: true, message: "This field can't be empty" },
                                      validationHelpers.getNumericValidatorForInput({}),
                                      {
                                        validator: async (_: any, value: any) => {
                                          const discountType = promotionForm.getFieldValue('discount_type');
                                          if (discountType === 'PERCENTAGE' && value > 100) {
                                            return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                          }
                                          return Promise.resolve();
                                        }
                                      },
                                      getFormattedNumeric2DecimalPointValidatorForInput({
                                        message:
                                          'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                      })
                                    ]}
                                  >
                                    <Input size="large" placeholder="Discount Value" disabled={false} />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                            <Row gutter={12}>
                              {discount_type === 'PERCENTAGE' && !isRange && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label="Maximum Discount Value"
                                    name={'max_discount_item_vol'}
                                    rules={[
                                      { required: false, message: "This field can't be empty" },
                                      validationHelpers.getNumericValidatorForInput({})
                                    ]}
                                  >
                                    <Input size="large" placeholder="Maximum Discount Value" disabled={false} />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                            <Row justify={'start'}>
                              <Col xs={24} md={4}>
                                <Button onClick={handleOnAddItem} icon={<PlusOutlined />} type="primary">
                                  <BoldButtonLabel labelText="Add" />
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        </>
                      );
                    }}
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.Item>
      </>
    );
  };

  const renderItemCategoryVolumeUI = (discount_type: any, range: any) => {
    return (
      <>
        {/* Only Table */}
        {discount_type === 'SPL_PRICE' && specialPriceVolumeCategory?.length > 0 && (
          <>
            <Row gutter={16} className="mt-4">
              <Col xs={12} md={4}>
                <Card bordered className="">
                  <Statistic
                    title="Total Category Selected"
                    value={specialPriceVolumeCategory.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card bordered className="">
                  <Statistic
                    title="Total Items Selected"
                    value={specialPriceVolumeData?.data?.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={24}>
                <Card>
                  <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                  <Collapse accordion activeKey={activeKey} onChange={onClickExpandVolumeSpecialPrice}>
                    {specialPriceVolumeCategory?.map((item: any) => {
                      return (
                        <Panel header={item} key={item}>
                          <section className="mt-4">
                            <Table
                              expandable={{
                                expandedRowRender,
                                rowExpandable: record => record?.volume_Threshold_qty
                              }}
                              bordered
                              className="mt-4"
                              dataSource={specialPriceVolumeFilterData || []}
                              columns={SpecialPriceVolumeColumns}
                              scroll={{ x: 1000 }}
                              pagination={false}
                              rowKey={(record: any) => record.sku}
                            ></Table>
                          </section>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {discount_type === 'PERCENTAGE' && percentPriceVolumeCategory?.length > 0 && (
          <>
            <Row gutter={16} className="mt-4">
              <Col xs={12} md={4}>
                <Card bordered>
                  <Statistic
                    title="Total Category Selected"
                    value={percentPriceVolumeCategory.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card bordered className="">
                  <Statistic
                    title="Total Items Selected"
                    value={percentPriceVolumeData?.data?.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={24}>
                <Card>
                  <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                  <Collapse accordion activeKey={activeKey} onChange={onClickExpandVolumePercentPrice}>
                    {percentPriceVolumeCategory?.map((item: any) => {
                      return (
                        <Panel header={item} key={item}>
                          <section className="mt-4">
                            <Table
                              bordered
                              expandable={{
                                expandedRowRender,
                                rowExpandable: record => record?.volume_Threshold_qty
                              }}
                              className="mt-4"
                              dataSource={percentPriceVolumeFilterData || []}
                              columns={PercentPriceVolumeColumns}
                              scroll={{ x: 1000 }}
                              pagination={false}
                              rowKey={(record: any) => record.sku}
                            ></Table>
                          </section>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {discount_type === 'FIXED' && fixedPriceVolumeCategory?.length > 0 && (
          <>
            <Row gutter={16} className="mt-4">
              <Col xs={12} md={4}>
                <Card bordered>
                  <Statistic
                    title="Total Category Selected"
                    value={fixedPriceVolumeCategory.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card bordered className="">
                  <Statistic
                    title="Total Items Selected"
                    value={fixedPriceVolumeData?.data?.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={24}>
                <Card>
                  <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                  <Collapse accordion activeKey={activeKey} onChange={onClickExpandVolumeFixedPrice}>
                    {fixedPriceVolumeCategory?.map((item: any) => {
                      return (
                        <Panel header={item} key={item}>
                          <section className="mt-4">
                            <Table
                              bordered
                              expandable={{
                                expandedRowRender,
                                rowExpandable: record => record?.volume_Threshold_qty
                              }}
                              className="mt-4"
                              dataSource={fixedPriceVolumeFilterData || []}
                              columns={FixedPriceVolumeColumns}
                              scroll={{ x: 1000 }}
                              pagination={false}
                              rowKey={(record: any) => record.sku}
                            ></Table>
                          </section>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const bundleUIRenderer = (discount_type: any) => {
    return (
      <>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Select discount type"
              name={'discount_type'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                size="large"
                allowClear
                disabled={disableFields()}
                options={[
                  { label: 'Perc Discount', value: 'PERCENTAGE' },
                  { label: 'Absolute Discount', value: 'FIXED' },
                  { label: 'Special Price', value: 'SPL_PRICE' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          {discount_type !== 'SPL_PRICE' && (
            <>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Discount Value"
                  name={'discount_value'}
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    {
                      // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                      validator: async (_, value) => {
                        const discountType = promotionForm.getFieldValue('discount_type');
                        if (discountType === 'PERCENTAGE' && value > 100) {
                          return Promise.reject(new Error('Percentage cannot exceed 100.'));
                        }
                        return Promise.resolve();
                      }
                    },
                    getFormattedNumeric2DecimalPointValidatorForInput({
                      message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                    })
                  ]}
                >
                  <InputNumber
                    min={1}
                    size="large"
                    disabled={disableFields()}
                    placeholder="Discount Value"
                    className="w-full"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Maximum Discount Value"
                  name={'maximum_discount_value'}
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    getFormattedNumeric2DecimalPointValidatorForInput({
                      message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                    })
                  ]}
                >
                  <InputNumber
                    min={1}
                    size="large"
                    disabled={disableFields()}
                    placeholder="Maximum Discount Value"
                    className="w-full"
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        {!viewOnly && (
          <Card>
            <Typography.Title level={4}>Add Item:</Typography.Title>
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Select Category"
                  name={'category'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Select
                    placeholder="Select"
                    disabled={isCategoryDisabled}
                    allowClear
                    size="large"
                    options={categoryProductOptions || []}
                  ></Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Select Item"
                  name={'selected_item'}
                  rules={[
                    validationHelpers.getRequiredValidator({
                      message: 'This field is required'
                    })
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    className="w-full"
                    size="large"
                    onSearch={async searchTerm => {
                      let category = promotionForm.getFieldsValue().category;
                      if (searchTerm) await debouncedFetchProductDropdownOptions(searchTerm, category);
                    }}
                    onSelect={(curData: any, nextData: any) => {
                      const item_Price = nextData?.itemData?.unit_price || '';
                      const product_name = nextData?.itemData?.product_name || '';
                      const category = nextData?.itemData?.category || '';
                      promotionForm.setFieldsValue({
                        unit_current_price: item_Price,
                        product_Name: product_name,
                        category: category
                      });
                      setIsCategoryDisabled(true);
                    }}
                    onClear={() => {
                      setIsCategoryDisabled(false);
                      promotionForm.setFieldsValue({
                        category: null,
                        product_Name: null,
                        unit_current_price: null
                      });
                    }}
                    filterOption={false}
                    placeholder="Search Item"
                    notFoundContent={productDropdownFetching ? <Spin size="small" /> : <>No Data Found</>}
                    options={productDropdownOptions}
                  ></Select>
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item
                  label="Product Name"
                  name={'product_Name'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Input size="large" placeholder="Product Name" disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Unit Price"
                  name={'unit_current_price'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Input size="large" placeholder="Unit Price" disabled={true} />
                </Form.Item>
              </Col>
              {discount_type !== 'SPL_PRICE' && (
                <>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Minimum Quantity"
                      name={'min_quantity'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        getFormattedNumeric2DecimalPointValidatorForInput({
                          message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                        })
                      ]}
                    >
                      <InputNumber min={1} size="large" placeholder="Minimum Quantity" className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Maximum Quantity"
                      name={'max_quantity'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        getFormattedNumeric2DecimalPointValidatorForInput({
                          message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                        })
                      ]}
                    >
                      <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
                    </Form.Item>
                  </Col>
                </>
              )}
              {discount_type === 'SPL_PRICE' && (
                <>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Minimum Quantity"
                      name={'quantity'}
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Input size="large" placeholder="Minimum Quantity" disabled={false} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Special Prices"
                      name={'special_price'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                    >
                      <Input size="large" placeholder="Special Price" disabled={false} />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row justify={'start'}>
              <Col xs={24} md={4}>
                <Button onClick={handleOnAddItem} icon={<PlusOutlined />} type="primary">
                  <BoldButtonLabel labelText="Add" />
                </Button>
              </Col>
            </Row>
          </Card>
        )}

        {/* bundle Table */}
        {discount_type === 'SPL_PRICE' && specialPriceCategory?.length > 0 && (
          <>
            <Row gutter={16} className="mt-4">
              <Col xs={12} md={4}>
                <Card bordered className="">
                  <Statistic
                    title="Total Category Selected"
                    value={specialPriceCategory.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card bordered className="">
                  <Statistic
                    title="Total Items Selected"
                    value={specialPriceData?.data?.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card bordered className="">
                  <Statistic
                    title="Offer Price"
                    value={getTotalSpecialPrice()}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={24}>
                <Card>
                  <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                  <Collapse accordion activeKey={activeKey} onChange={onClickExpandSpecialPrice}>
                    {specialPriceCategory?.map((item: any) => {
                      return (
                        <Panel header={item} key={item}>
                          <section className="mt-4">
                            <Table
                              bordered
                              className="mt-4"
                              dataSource={specialPriceFilterData || []}
                              columns={SpecialPriceColumns(
                                specialPriceEditRecord,
                                setSpecialPriceEditRecord,
                                handleSaveSpecial
                              )}
                              scroll={{ x: 1000 }}
                              pagination={false}
                            ></Table>
                          </section>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {discount_type === 'FIXED' && fixedPriceCategory?.length > 0 && (
          <>
            <Row gutter={16} className="mt-4">
              <Col xs={12} md={4}>
                <Card bordered>
                  <Statistic
                    title="Total Category Selected"
                    value={fixedPriceCategory.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card bordered className="">
                  <Statistic
                    title="Total Items Selected"
                    value={fixedPriceData?.data?.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={24}>
                <Card>
                  <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                  <Collapse accordion activeKey={activeKey} onChange={onClickExpandFixedPrice}>
                    {fixedPriceCategory?.map((item: any) => {
                      return (
                        <Panel header={item} key={item}>
                          <section className="mt-4">
                            <Table
                              bordered
                              className="mt-4"
                              dataSource={fixedPriceFilterData || []}
                              columns={FixedPriceColumns(
                                FixedPriceEditRecord,
                                setFixedPriceEditRecord,
                                handleSaveFixed
                              )}
                              scroll={{ x: 1000 }}
                              pagination={false}
                            ></Table>
                          </section>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {discount_type === 'PERCENTAGE' && percentPriceCategory?.length > 0 && (
          <>
            <Row gutter={16} className="mt-4">
              <Col xs={12} md={4}>
                <Card bordered>
                  <Statistic
                    title="Total Category Selected"
                    value={percentPriceCategory.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card bordered className="">
                  <Statistic
                    title="Total Items Selected"
                    value={percentPriceData?.data?.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={24}>
                <Card>
                  <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                  <Collapse accordion activeKey={activeKey} onChange={onClickExpandPrecentPrice}>
                    {percentPriceCategory?.map((item: any) => {
                      return (
                        <Panel header={item} key={item}>
                          <section className="mt-4">
                            <Table
                              bordered
                              className="mt-4"
                              dataSource={percentPriceFilterData || []}
                              columns={PercentPriceColumns(
                                percentPriceEditRecord,
                                setPercentPriceEditRecord,
                                handleSavePercent
                              )}
                              scroll={{ x: 1000 }}
                              pagination={false}
                            ></Table>
                          </section>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const bxgyUIRenderer = (discount_type: any, applies_to_price: any) => {
    return (
      <>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Select discount type"
              name={'discount_type'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                size="large"
                allowClear
                disabled={disableFields()}
                options={[
                  { label: 'Perc Discount', value: 'PERCENTAGE' },
                  { label: 'Absolute Discount', value: 'FIXED' },
                  { label: 'Special Price', value: 'SPL_PRICE' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Applies to Price"
              name={'applies_to_price'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                size="large"
                disabled={disableFields()}
                options={[
                  { label: 'None', value: 'NONE' },
                  { label: 'Lowest', value: 'LOWEST' },
                  { label: 'Highest', value: 'HIGHEST' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          {discount_type === 'PERCENTAGE' && (
            <>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Discount Value"
                  name="percent_discount_value"
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    getFormattedNumeric2DecimalPointValidatorForInput({
                      message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                    }),
                    {
                      // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                      validator: async (_, value) => {
                        const discountType = promotionForm.getFieldValue('discount_type');
                        if (discountType === 'PERCENTAGE' && value > 100) {
                          return Promise.reject(new Error('Percentage cannot exceed 100.'));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Discount Value"
                    onBlur={formatToTwoDecimalPlaces}
                    disabled={disableFields()}
                    // suffix={<Tooltip title="Percent">%</Tooltip>}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Maximum Discount"
                  name={'max_discount'}
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    getFormattedNumeric2DecimalPointValidatorForInput({
                      message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                    })
                  ]}
                >
                  <InputNumber
                    min={1}
                    disabled={disableFields()}
                    size="large"
                    placeholder="Maximum Discount"
                    className="w-full"
                  />
                </Form.Item>
              </Col>
            </>
          )}
          {discount_type === 'FIXED' && (
            <>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Discount Value"
                  name="percent_discount_value"
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    getFormattedNumeric2DecimalPointValidatorForInput({
                      message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                    }),
                    {
                      // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                      validator: async (_, value) => {
                        const discountType = promotionForm.getFieldValue('discount_type');
                        if (discountType === 'PERCENTAGE' && value > 100) {
                          return Promise.reject(new Error('Percentage cannot exceed 100.'));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Discount Value"
                    onBlur={formatToTwoDecimalPlaces}
                    // suffix={<Tooltip title="Percent">%</Tooltip>}
                    disabled={disableFields()}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col xs={12} md={6}>
            <Form.Item
              label="Apply Discount On"
              name={'apply_discount_on'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                disabled={disableFields()}
                size="large"
                options={[
                  { label: 'Products', value: 'PRODUCTS' },
                  { label: 'Category', value: 'CATEGORY' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.apply_discount_on !== curValues.apply_discount_on;
          }}
        >
          {form => {
            const { apply_discount_on } = form.getFieldsValue();
            return apply_discount_on === 'CATEGORY' ? (
              <>
                <Card>
                  {!viewOnly && (
                    <>
                      <Row gutter={12}>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Minimum Line Items"
                            name={'min_line_items'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Select Category"
                            name={'category'}
                            rules={[{ required: true, message: "This field can't be empty" }]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              size="large"
                              options={categoryProductOptions || []}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Minimum Quantity"
                            name={'minimum_quantity'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Minimum Quantity" className="w-full" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Maximum Quantity"
                            name={'maximum_quantity'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify={'start'}>
                        <Col xs={24} md={4}>
                          <Button onClick={handleOnAddCategory} icon={<PlusOutlined />} type="primary">
                            <BoldButtonLabel labelText="Add Category" />
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  <section className="mt-4">
                    <Table
                      className="mt-2"
                      bordered
                      columns={categoryColumns}
                      dataSource={categoryData?.data || []}
                      pagination={false}
                      scroll={{ x: 1000 }}
                    />
                  </section>
                </Card>
              </>
            ) : (
              <>
                {' '}
                <Card>
                  {!viewOnly && (
                    <>
                      <Typography.Title level={4}>Buy Item(s)</Typography.Title>
                      <Row gutter={12} className="mt-4">
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Selection Type For Items"
                            name={'operator_selection_buy'}
                            rules={[{ required: true, message: "This field can't be empty" }]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              size="large"
                              options={[
                                { label: 'Buy All Item(s)', value: 'AND' },
                                { label: 'Buy Any Item(s)', value: 'OR' }
                              ]}
                              onSelect={(item: any) => {
                                if (item === 'AND') {
                                  promotionForm.setFieldsValue({
                                    operator_selection_get: 'AND'
                                  });
                                } else {
                                  promotionForm.setFieldsValue({
                                    operator_selection_get: 'OR'
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.List
                        name={'BXGY_buy'}
                        rules={[
                          {
                            validator: async (_, BXGY_buy) => {
                              if (!BXGY_buy || BXGY_buy.length < 1) {
                                return Promise.reject(new Error('You must have at-least 1 items'));
                              }
                            }
                          }
                        ]}
                      >
                        {(buyFields, { add: addBuy, remove }, { errors }) => (
                          <>
                            <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
                            {buyFields?.map(({ key, name, fieldKey, ...restField }) => (
                              <Card key={key} {...restField} className="mb-2 bg-[#f0f8ff]">
                                <div className="flex flex-row-reverse">
                                  <Button
                                    type="link"
                                    className="text-red-600"
                                    onClick={() => remove(name)}
                                    disabled={bxgyData?.length > 0}
                                  >
                                    <DeleteOutlined className="text-red-500 text-xl" title="Delete" />
                                  </Button>
                                </div>
                                <Row gutter={12}>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      label="Select Category"
                                      name={[name, 'category']}
                                      rules={[{ required: true, message: "This field can't be empty" }]}
                                    >
                                      <Select
                                        placeholder="Select"
                                        allowClear
                                        size="large"
                                        options={categoryProductOptions || []}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      label="Select Item"
                                      name={[name, 'selected_item']}
                                      rules={[
                                        validationHelpers.getRequiredValidator({
                                          message: 'This field is required'
                                        }),
                                        {
                                          validator: async (_, value) => {
                                            const formValues = promotionForm.getFieldsValue(true);
                                            const isDuplicate = formValues.BXGY_buy.filter(
                                              (item: any) => item.selected_item === value
                                            );
                                            if (isDuplicate.length >= 2) {
                                              return Promise.reject(new Error('Item is already selected.'));
                                            }
                                            return Promise.resolve();
                                          }
                                        }
                                      ]}
                                    >
                                      <Select
                                        allowClear
                                        showSearch
                                        className="w-full"
                                        size="large"
                                        onSearch={async searchTerm => {
                                          let category = promotionForm.getFieldsValue().BXGY_buy[name]?.category || '';
                                          if (searchTerm)
                                            await debouncedFetchProductDropdownOptions(searchTerm, category);
                                        }}
                                        onSelect={(selectedItem: any, nextData: any) => {
                                          const formValues = promotionForm.getFieldsValue(true);
                                          const item_Price = nextData?.itemData?.unit_price || '';
                                          const product_name = nextData?.itemData?.product_name || '';
                                          const category = nextData?.itemData?.category || '';
                                          formValues.BXGY_buy.forEach((item: any, index: number) => {
                                            if (item.selected_item === selectedItem) {
                                              item.category = category;
                                              item.product_Name = product_name;
                                              item.unit_current_price = item_Price;
                                            }
                                          });
                                          // Set the updated form values
                                          promotionForm.setFieldsValue({ BXGY_buy: formValues.BXGY_buy });
                                        }}
                                        filterOption={false}
                                        placeholder="Search Item"
                                        notFoundContent={
                                          productDropdownFetching ? <Spin size="small" /> : <>No Data Found</>
                                        }
                                        options={productDropdownOptions}
                                      ></Select>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      label="Product Name"
                                      name={[name, 'product_Name']}
                                      rules={[{ required: true, message: "This field can't be empty" }]}
                                    >
                                      <Input size="large" placeholder="Product Name" disabled={true} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={12}>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      label="Quantity"
                                      name={[name, 'item_quantity']}
                                      rules={[
                                        { required: true, message: "This field can't be empty" },
                                        getFormattedNumeric2DecimalPointValidatorForInput({
                                          message:
                                            'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                        })
                                      ]}
                                    >
                                      <InputNumber min={1} className="w-full" size="large" placeholder="Quantity" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      label="Unit Price"
                                      name={[name, 'unit_current_price']}
                                      rules={[{ required: true, message: "This field can't be empty" }]}
                                    >
                                      <Input size="large" placeholder="Unit Price" disabled={true} />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6} className="mt-8"></Col>
                                </Row>
                              </Card>
                            ))}
                            <Form.Item>
                              <Button type="primary" onClick={() => addBuy()} disabled={bxgyData?.length > 0}>
                                Add Reward Product
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.applies_to_price !== curValues.applies_to_price
                        }
                      >
                        {({ getFieldValue }) => {
                          const applies_price = getFieldValue('applies_to_price');
                          return (
                            applies_price === 'NONE' &&
                            !viewOnly && (
                              <>
                                <Typography.Title level={4}>Get Item(s)</Typography.Title>
                                <Row gutter={12} className="mt-4">
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      label="Selection Type For Rewards"
                                      name={'operator_selection_get'}
                                      rules={[{ required: true, message: "This field can't be empty" }]}
                                    >
                                      <Select
                                        placeholder="Select"
                                        allowClear
                                        size="large"
                                        options={[
                                          { label: 'Get All Item(s)', value: 'AND' },
                                          { label: 'Get Any Item(s)', value: 'OR' }
                                        ]}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Form.List
                                  name={'BXGY_get'}
                                  rules={[
                                    {
                                      validator: async (_, BXGY_get) => {
                                        if (!BXGY_get || BXGY_get.length < 1) {
                                          return Promise.reject(new Error('You must have at-least 1 items '));
                                        }
                                      }
                                    }
                                  ]}
                                >
                                  {(getFields, { add: addGet, remove }, { errors }) => (
                                    <>
                                      <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
                                      {getFields?.map(({ key, name, fieldKey, ...restField }) => (
                                        <Card key={key} {...restField} className="mb-2 bg-[#f0f8ff]">
                                          <div className="flex flex-row-reverse">
                                            <Button
                                              type="link"
                                              className="text-red-600"
                                              onClick={() => remove(name)}
                                              disabled={bxgyData?.length > 0}
                                            >
                                              <DeleteOutlined className="text-red-500 text-xl" title="Delete" />
                                            </Button>
                                          </div>
                                          <Row gutter={12}>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                label="Select Category"
                                                name={[name, 'category']}
                                                rules={[{ required: true, message: "This field can't be empty" }]}
                                              >
                                                <Select
                                                  placeholder="Select"
                                                  allowClear
                                                  size="large"
                                                  options={categoryProductOptions || []}
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                label="Select Item"
                                                name={[name, 'selected_item']}
                                                rules={[
                                                  validationHelpers.getRequiredValidator({
                                                    message: 'This field is required'
                                                  }),
                                                  {
                                                    validator: async (_, value) => {
                                                      const formValues = promotionForm.getFieldsValue(true);
                                                      const isDuplicate = formValues.BXGY_get.filter(
                                                        (item: any) => item.selected_item === value
                                                      );
                                                      if (isDuplicate.length >= 2) {
                                                        return Promise.reject(new Error('Item is already selected.'));
                                                      }
                                                      return Promise.resolve();
                                                    }
                                                  }
                                                ]}
                                              >
                                                <Select
                                                  allowClear
                                                  showSearch
                                                  className="w-full"
                                                  size="large"
                                                  onSearch={async searchTerm => {
                                                    let category =
                                                      promotionForm.getFieldsValue().BXGY_get[name]?.category || '';
                                                    if (searchTerm)
                                                      await debouncedFetchProductDropdownOptions(searchTerm, category);
                                                  }}
                                                  onSelect={(curData: any, nextData: any) => {
                                                    const formValues = promotionForm.getFieldsValue(true);
                                                    const item_Price = nextData?.itemData?.unit_price || '';
                                                    const product_name = nextData?.itemData?.product_name || '';
                                                    const category = nextData?.itemData?.category || '';
                                                    formValues.BXGY_get.forEach((item: any, index: number) => {
                                                      if (item.selected_item === curData) {
                                                        item.category = category;
                                                        item.product_Name = product_name;
                                                        item.unit_current_price = item_Price;
                                                      }
                                                    });
                                                    promotionForm.setFieldsValue({ BXGY_get: formValues.BXGY_get });
                                                  }}
                                                  filterOption={false}
                                                  placeholder="Search Item"
                                                  notFoundContent={
                                                    productDropdownFetching ? <Spin size="small" /> : <>No Data Found</>
                                                  }
                                                  options={productDropdownOptions}
                                                ></Select>
                                              </Form.Item>
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                label="Product Name"
                                                name={[name, 'product_Name']}
                                                rules={[{ required: true, message: "This field can't be empty" }]}
                                              >
                                                <Input size="large" placeholder="Product Name" disabled={true} />
                                              </Form.Item>
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                label="Minimum Quantity"
                                                name={[name, 'minimum_Quantity_BXGY_buy']}
                                                rules={[
                                                  { required: true, message: "This field can't be empty" },
                                                  getFormattedNumeric2DecimalPointValidatorForInput({
                                                    message:
                                                      'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                  })
                                                ]}
                                              >
                                                <InputNumber
                                                  min={1}
                                                  className="w-full"
                                                  size="large"
                                                  placeholder="Minimum Quantity"
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                          <Row gutter={12}>
                                            {discount_type === 'SPL_PRICE' && (
                                              <>
                                                <Col xs={12} md={6}>
                                                  <Form.Item
                                                    label="Special Price"
                                                    name={[name, 'special_price_BXGY_buy']}
                                                    rules={[
                                                      { required: true, message: "This field can't be empty" },
                                                      getFormattedNumeric2DecimalPointValidatorForInput({
                                                        message:
                                                          'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                      })
                                                    ]}
                                                  >
                                                    <InputNumber
                                                      min={1}
                                                      className="w-full"
                                                      size="large"
                                                      placeholder="Quantity"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              </>
                                            )}

                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                label="Unit Price"
                                                name={[name, 'unit_current_price']}
                                                rules={[{ required: true, message: "This field can't be empty" }]}
                                              >
                                                <Input size="large" placeholder="Unit Price" disabled={true} />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        </Card>
                                      ))}
                                      <Form.Item>
                                        <Button type="primary" onClick={() => addGet()} disabled={bxgyData?.length > 0}>
                                          Add Rewarded Product
                                        </Button>
                                      </Form.Item>
                                    </>
                                  )}
                                </Form.List>
                              </>
                            )
                          );
                        }}
                      </Form.Item>
                      <Row justify={'start'}>
                        <Col xs={24} md={4}>
                          <Button
                            onClick={() => {
                              handleOnAddBXGY();
                            }}
                            disabled={bxgyData?.length > 0}
                            icon={<PlusOutlined />}
                            type="primary"
                          >
                            <BoldButtonLabel labelText="Add BXGY" />
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  {bxgyData?.length > 0 && (
                    <Row gutter={12} className="mt-4">
                      <Col xs={24} md={24}>
                        <Card>
                          <Typography.Title level={4}>Selected Item(s):</Typography.Title>
                          {bxgyData?.map((itemData: any) => {
                            return (
                              <div
                                style={{
                                  height: 'fit-content',
                                  width: '100%',
                                  background: '#9b9b9b29',
                                  padding: '20px',
                                  borderRadius: '20px',
                                  marginBottom: '15px'
                                }}
                              >
                                <Typography.Title level={5}>
                                  {promotionForm.getFieldsValue().operator_selection_buy === 'AND'
                                    ? 'Buy All Item(s)'
                                    : 'Buy Any Item(s)'}
                                  {applies_to_price !== 'NONE'
                                    ? ` And Get an item of ${applies_to_price} Price from below List`
                                    : ``}
                                </Typography.Title>
                                <div className="flex flex-row-reverse">
                                  <Button
                                    type="link"
                                    className="text-red-600"
                                    onClick={() => handleOnDeleteBXGYItem(itemData)}
                                  >
                                    <DeleteOutlined className="text-red-500 text-xl" title="Delete" />
                                  </Button>
                                </div>
                                <Table
                                  className="mt-2"
                                  bordered
                                  columns={BXGYBuyCols}
                                  dataSource={itemData.getData || []}
                                  pagination={false}
                                  scroll={{ x: 1000 }}
                                />
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues.applies_to_price !== curValues.applies_to_price
                                  }
                                >
                                  {({ getFieldValue }) => {
                                    const applies_price = getFieldValue('applies_to_price');
                                    return (
                                      applies_price === 'NONE' && (
                                        <>
                                          <Typography.Title level={5} className="mt-4">
                                            {promotionForm.getFieldsValue().operator_selection_get === 'AND'
                                              ? 'Get All Item(s)'
                                              : 'Get Any Item(s)'}
                                          </Typography.Title>
                                          {discount_type === 'SPL_PRICE' && (
                                            <Table
                                              className="mt-2"
                                              bordered
                                              columns={BXGY_Special_Price_Columns}
                                              dataSource={itemData.buyData || []}
                                              pagination={false}
                                              scroll={{ x: 1000 }}
                                            />
                                          )}
                                          {discount_type === 'PERCENTAGE' && (
                                            <Table
                                              className="mt-2"
                                              bordered
                                              columns={BXGY_Percentage_Price_Columns}
                                              dataSource={itemData.buyData || []}
                                              pagination={false}
                                              scroll={{ x: 1000 }}
                                            />
                                          )}
                                          {discount_type === 'FIXED' && (
                                            <Table
                                              className="mt-2"
                                              bordered
                                              columns={BXGY_Fixed_Price_Columns}
                                              dataSource={itemData.buyData || []}
                                              pagination={false}
                                              scroll={{ x: 1000 }}
                                            />
                                          )}
                                        </>
                                      )
                                    );
                                  }}
                                </Form.Item>
                              </div>
                            );
                          })}
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Card>
              </>
            );
          }}
        </Form.Item>
      </>
    );
  };

  const shippingDiscountUIRenderer = () => {
    return (
      <div>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Select discount type"
              name={'discount_type'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                size="large"
                disabled={disableFields()}
                options={[
                  { label: 'Perc Discount', value: 'PERCENTAGE' },
                  { label: 'Absolute Discount', value: 'FIXED' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Threshold Selection"
              name={'threshold_selection'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                allowClear
                placeholder="Select"
                size="large"
                options={[
                  { label: 'None', value: 'NO_THRESHOLD' },
                  { label: 'MOV', value: 'MOV' },
                  { label: 'MOQ', value: 'MOQ' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.threshold_selection !== curValues.threshold_selection;
            }}
          >
            {form => {
              const { threshold_selection } = form.getFieldsValue();
              return (
                threshold_selection === 'NO_THRESHOLD' && (
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="discount_value"
                      label="Discount Value"
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        {
                          validator: async (_: any, value: any) => {
                            const discountType = promotionForm.getFieldValue('discount_type');
                            if (discountType === 'PERCENTAGE' && value > 100) {
                              return Promise.reject(new Error('Percentage cannot exceed 100.'));
                            }
                            return Promise.resolve();
                          }
                        },
                        getFormattedNumeric2DecimalPointValidatorForInput({
                          message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                        })
                      ]}
                    >
                      <InputNumber min={1} className="w-full" size="large" placeholder="Discount Value"></InputNumber>
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>

          <Col xs={24} md={6}>
            <Form.Item
              name="max_discount"
              label="Maximum Discount"
              rules={[
                { required: true, message: "This field can't be empty" },
                getFormattedNumeric2DecimalPointValidatorForInput({
                  message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                })
              ]}
            >
              <InputNumber min={1} className="w-full" size="large" placeholder="Maximum Discount"></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.threshold_selection !== curValues.threshold_selection;
          }}
        >
          {({ getFieldValue }) => {
            const threshold = getFieldValue('threshold_selection');
            const shipping_discount = getFieldValue('shipping_discount');

            if (threshold === 'NO_THRESHOLD') {
              return null;
            }

            let previousMaxValue = 0;
            if (shipping_discount?.length > 0) {
              const lastItem = shipping_discount[shipping_discount.length - 1];
              previousMaxValue = lastItem.to_Quantity || 0;
            }

            return (
              <Form.List name="shipping_discount">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <Typography.Title level={4}>Shipping Discount:</Typography.Title>
                      {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                        const isLastField = index === fields.length - 1;
                        const maxLimitSetChecked = getFieldValue(['shipping_discount', name, 'max_limit_set']);
                        const currentPreviousMaxValue =
                          index > 0 ? getFieldValue(['shipping_discount', fields[index - 1].name, 'to_Quantity']) : 0;

                        return (
                          <div key={key} {...restField}>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Minimum Value"
                                  name={[name, 'from_Quantity']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      validator: (_, value) => {
                                        if (value >= currentPreviousMaxValue) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(
                                          new Error(`Minimum Value must be greater than ${currentPreviousMaxValue}`)
                                        );
                                      }
                                    },
                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                      message:
                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                    })
                                  ]}
                                >
                                  <InputNumber
                                    min={currentPreviousMaxValue}
                                    size="large"
                                    placeholder="From Quantity"
                                    className="w-full"
                                  />
                                </Form.Item>
                              </Col>
                              {!maxLimitSetChecked && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label="Maximuum Value"
                                    name={[name, 'to_Quantity']}
                                    rules={[
                                      { required: true, message: "This field can't be empty" },
                                      {
                                        validator: (_, value) => {
                                          const minValue = getFieldValue(['shipping_discount', name, 'from_Quantity']);
                                          if (value >= minValue) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(
                                            new Error('Maximum Value must be greater than Minimum Value')
                                          );
                                        }
                                      },
                                      getFormattedNumeric2DecimalPointValidatorForInput({
                                        message:
                                          'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                      })
                                    ]}
                                  >
                                    <InputNumber min={1} size="large" placeholder="To Quantity" className="w-full" />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Discount Value"
                                  name={[name, 'discount_Value']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                      validator: async (_, value) => {
                                        const discountType = promotionForm.getFieldValue('discount_type');
                                        if (discountType === 'PERCENTAGE' && value > 100) {
                                          return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                        }
                                        return Promise.resolve();
                                      }
                                    },
                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                      message:
                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                    })
                                  ]}
                                >
                                  <InputNumber min={1} size="large" placeholder="Value" className="w-full" />
                                </Form.Item>
                              </Col>
                              {isLastField && (
                                <Col xs={12} md={3} className="mt-8">
                                  <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                    <Checkbox>Max Limit Set</Checkbox>
                                  </Form.Item>
                                </Col>
                              )}
                              {fields.length > 1 && (
                                <Col xs={12} md={2}>
                                  <div className="mx-4 h-full flex justify-center items-center">
                                    <DeleteOutlined
                                      className="text-red-500 text-xl cursor-pointer"
                                      role="button"
                                      title="Remove"
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => add({ from_Quantity: previousMaxValue, max_limit_set: false })}
                          className="mt-2"
                          disabled={getFieldValue(['shipping_discount', fields.length - 1, 'max_limit_set'])} // Disable button if max_limit_set is true
                        >
                          Add Quantity
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            );
          }}
        </Form.Item>
      </div>
    );
  };

  const handleOnDeleteBXGYItem = (itemData: any) => {
    let filterData = bxgyData.filter(item => item.id !== itemData.id);
    setBxgyData(filterData);
  };

  const CategoryModalOfSTD = (record: any) => {
    categoryEditForm.setFieldsValue({
      category_index: record.item_id,
      category: record.category,
      minimum_quantity: record.minimum_quantity,
      maximum_quantity: record.maximum_quantity,
      percent_discount_value_item: record.discount_value,
      max_discount_Value_item: record.max_discount || record?.max_discount_Value_item
    });
    setCategoryEditModal(true);
  };

  const CategoryModalOfVolume = (record: any) => {
    let treshold = !record?.volume_Threshold_qty ? null : record?.volume_Threshold_qty;
    categoryEditVolumeForm.setFieldsValue({
      category_index: record.item_id,
      category: record.category,
      minimum_quantity: record.minimum_quantity,
      maximum_quantity: record.maximum_quantity,
      percent_discount_value_item: record.discount_value,
      max_discount_Value_item: record.max_discount,
      volume_treshold_Perc_edit: treshold
    });
    setCategoryEditModalVolume(true);
  };

  const SpecialPriceVolumeModalEdit = (record: any) => {
    specialPriceVolumeModalForm.setFieldsValue({
      sku: record.sku,
      unit_current_price: record.unit_current_price,
      quantity: record.quantity,
      special_discount_value_edit: record.special_price,
      min_discount_volume: record.min_quantity_item_vol,
      max_discount_item_vol: record.max_quantity_item_vol,
      discount_value_item_vol: record.discount_value_item_vol,
      max_discount_volume: record.max_discount_item_vol,
      volume_Threshold_qty_edit: record.volume_Threshold_qty
    });
    setSpecialPriceVolumeModal(true);
  };

  const PercentPriceVolumeModalEdit = (record: any) => {
    let getModifiedTreshold = record?.volume_Threshold_qty?.map((item: any) => {
      let obj = {
        ...item,
        discount_value: item.volume_value
      };
      return obj;
    });
    percentPriceVolumeModalForm.setFieldsValue({
      sku: record.sku,
      unit_current_price: record.unit_current_price,
      volume_Threshold_qty_edit: getModifiedTreshold,
      min_quantity_item_vol: record?.min_quantity || record?.min_quantity_item_vol,
      max_quantity_item_vol: record?.max_quantity_item_vol,
      discount_value_item_vol: record.discount_value_item_vol,
      max_discount_item_vol: record.max_discount_item_vol || MAX_LIMIT,
      discount_value: record?.volume_value
    });
    setPercentPriceVolumeModal(true);
  };

  const FixedPriceVolumeModalEdit = (record: any) => {
    fixedPriceVolumeModalForm.setFieldsValue({
      sku: record.sku,
      unit_current_price: record.unit_current_price,
      percent_discount_value: record?.discount_value_item_vol || record.percent_discount_value,
      min_quantity: record.min_quantity || record?.min_quantity_item_vol,
      max_quantity: record?.max_quantity_item_vol || record.max_quantity,
      volume_Threshold_qty_edit: record.volume_Threshold_qty
    });
    setFixedPriceVolumeModal(true);
  };

  const SubTotalEditModal = (record: any) => {
    let treshold = !record?.subtotal_treshold ? null : record?.subtotal_treshold;
    percentPriceSubTotalForm.setFieldsValue({
      sku: record.sku,
      unit_current_price: record.unit_current_price,
      min_quantity: record.min_quantity,
      max_quantity_item: record.max_quantity_item,
      percent_discount_value_item: record.percent_discount_value_item,
      max_discount_Value_item: record.max_discount_Value_item,
      subtotal_treshold_edit: treshold
    });
    setStItemEditModal(true);
  };

  const FixedEditModal = (record: any) => {
    let treshold = !record?.subtotal_treshold ? null : record?.subtotal_treshold;
    fixedSubTotalForm.setFieldsValue({
      sku: record.sku,
      unit_current_price: record.unit_current_price,
      min_quantity: record.min_quantity,
      max_quantity: record?.max_quantity_item,
      max_discount: record?.max_discount_Value_item,
      discount_value: record?.percent_discount_value || record?.percent_discount_value_item,
      subtotal_treshold_Perc_edit: treshold
    });
    setStPercentItemEditModal(true);
  };

  const SpecialPriceEditModal = (record: any) => {
    let treshold = !record?.subtotal_treshold ? null : record?.subtotal_treshold;
    specialSubTotalForm.setFieldsValue({
      sku: record.sku,
      unit_current_price: record.unit_current_price,
      min_quantity: record.minimum_qty,
      subtotal_treshold_Perc_edit: treshold
    });
    setStSpecialItemEditModal(true);
  };

  const SpecialPriceEditBundleModal = (record: any) => {
    specialPriceEditBundleForm.setFieldsValue({
      sku: record?.sku,
      quantity: record?.quantity,
      special_price: record?.special_price
    });
    setCategoryEditModalBundle(true);
  };

  const PercentPriceEditBundleModal = (record: any) => {
    percentageEditBundleForm.setFieldsValue({
      sku: record?.sku,
      min_quantity: record?.min_quantity,
      max_quantity: record?.max_quantity,
      discount_value: record?.discount_value,
      maximum_discount_value: record?.maximum_discount_value
    });
    setCategoryPercentEditModalBundle(true);
  };

  const FixedPriceEditBundleModal = (record: any) => {
    FixedEditBundleForm.setFieldsValue({
      sku: record?.sku,
      min_quantity: record?.min_quantity,
      max_quantity: record?.max_quantity,
      discount_value: record?.discount_value,
      maximum_discount_value: record?.maximum_discount_value
    });
    setCategoryEditFixedModalBundle(true);
  };

  const handleOnUpdatePercentPriceSpecialModalForm = () => {
    const {
      sku,
      quantity,
      special_discount_value_edit,
      min_discount_volume,
      volume_Threshold_qty_edit,
      max_discount_volume,
      discount_value_item_vol,
      max_discount_item_vol
    } = specialPriceVolumeModalForm.getFieldsValue();
    const updatedData = specialPriceVolumeData?.data?.map((item: any) => {
      if (item.sku === sku) {
        return {
          ...item,
          quantity: quantity,
          special_price: special_discount_value_edit,
          min_quantity_item_vol: min_discount_volume,
          max_quantity_item_vol: max_discount_item_vol,
          discount_value_item_vol: discount_value_item_vol,
          max_discount_item_vol: max_discount_volume,
          volume_Threshold_qty: volume_Threshold_qty_edit
        };
      }
      return item;
    });
    let combData = {
      data: updatedData
    };
    displaySuccessNotification({ message: `${sku} Updated!` });
    setSpecialPriceVolumeData(combData);
    setSpecialPriceVolumeModal(false);
    setActiveKey([]);
  };

  const handleOnUpdatePercentPriceVolumeModalForm = () => {
    const {
      sku,
      percent_discount_value_edit,
      volume_Threshold_qty_edit,
      min_quantity_item_vol,
      max_quantity_item_vol,
      discount_value_item_vol,
      max_discount_item_vol
    } = percentPriceVolumeModalForm.getFieldsValue();
    const updatedData = percentPriceVolumeData?.data?.map((item: any) => {
      if (item.sku === sku) {
        return {
          ...item,
          min_quantity_item_vol: min_quantity_item_vol,
          max_quantity_item_vol: max_quantity_item_vol,
          discount_value_item_vol: discount_value_item_vol,
          max_discount_item_vol: max_discount_item_vol,
          volume_Threshold_qty: volume_Threshold_qty_edit?.map((item: any) => {
            let obj = {
              ...item,
              volume_value: item.discount_value
            };
            return obj;
          })
        };
      }
      return item;
    });
    let combData = {
      data: updatedData
    };
    displaySuccessNotification({ message: `${sku} Updated!` });
    setPercentPriceVolumeData(combData);
    setPercentPriceVolumeModal(false);
    setActiveKey([]);
  };

  const handleOnUpdateFixedPriceVolumeModalForm = () => {
    const { sku, percent_discount_value, min_quantity, max_quantity, volume_Threshold_qty_edit } =
      fixedPriceVolumeModalForm.getFieldsValue();
    const updatedData = fixedPriceVolumeData?.data?.map((item: any) => {
      if (item.sku === sku) {
        return {
          ...item,
          min_quantity: Number(min_quantity),
          max_quantity: Number(max_quantity),
          percent_discount_value: percent_discount_value,
          volume_Threshold_qty: volume_Threshold_qty_edit
        };
      }
      return item;
    });
    let combData = {
      data: updatedData
    };
    setFixedPriceVolumeData(combData);
    displaySuccessNotification({ message: `${sku} Updated!` });
    setFixedPriceVolumeModal(false);
    setActiveKey([]);
  };

  const handleOnUpdatePercentPriceSTModalForm = () => {
    const {
      sku,
      min_quantity,
      max_quantity_item,
      percent_discount_value_item,
      max_discount_Value_item,
      subtotal_treshold_edit
    } = percentPriceSubTotalForm.getFieldsValue();
    const updatedData = percentPriceSTData?.data?.map((item: any) => {
      if (item.sku === sku) {
        return {
          ...item,
          min_quantity: min_quantity,
          max_quantity_item: max_quantity_item,
          percent_discount_value_item: percent_discount_value_item,
          max_discount_Value_item: max_discount_Value_item,
          subtotal_treshold: subtotal_treshold_edit
        };
      }
      return item;
    });
    let combData = {
      data: updatedData
    };
    setPercentPriceSTData(combData);
    displaySuccessNotification({ message: `${sku} Updated!` });
    setStItemEditModal(false);
    setActiveKey([]);
  };

  const handleOnUpdateFixedSTModalForm = () => {
    const { sku, min_quantity, subtotal_treshold_Perc_edit, max_quantity, discount_value, max_discount } =
      fixedSubTotalForm.getFieldsValue();
    const updatedData = fixedPriceSTData?.data?.map((item: any) => {
      if (item.sku === sku) {
        return {
          ...item,
          min_quantity: min_quantity,
          subtotal_treshold: subtotal_treshold_Perc_edit,
          max_quantity_item: max_quantity,
          max_discount_Value_item: max_discount,
          percent_discount_value: discount_value
        };
      }
      return item;
    });
    let combData = {
      data: updatedData
    };
    setfixedPriceSTData(combData);
    displaySuccessNotification({ message: `${sku} Updated!` });
    setStPercentItemEditModal(false);
    setActiveKey([]);
  };

  const handleOnUpdateSpecialSTModalForm = () => {
    const { sku, min_quantity, subtotal_treshold_Perc_edit } = specialSubTotalForm.getFieldsValue();
    const updatedData = specialPriceSTData?.data?.map((item: any) => {
      if (item.sku === sku) {
        return {
          ...item,
          quantity: min_quantity,
          subtotal_treshold: subtotal_treshold_Perc_edit
        };
      }
      return item;
    });
    let combData = {
      data: updatedData
    };
    setSpecialPriceSTData(combData);
    displaySuccessNotification({ message: `${sku} Updated!` });
    setStSpecialItemEditModal(false);
    setActiveKey([]);
  };

  const handleOnCategoryEditModal = () => {
    const { category_index, minimum_quantity, maximum_quantity, percent_discount_value_item, max_discount_Value_item } =
      categoryEditForm.getFieldsValue();
    const updatedData = categoryData?.data?.map((item: any) => {
      if (item.item_id === category_index) {
        return {
          ...item,
          minimum_quantity: minimum_quantity,
          maximum_quantity: maximum_quantity,
          discount_value: percent_discount_value_item,
          max_discount: max_discount_Value_item,
          max_discount_Value_item: max_discount_Value_item
        };
      }
      return item;
    });
    let combData = {
      data: updatedData
    };
    setCategoryData(combData);
    displaySuccessNotification({ message: `${category_index} Updated!` });
    setCategoryEditModal(false);
  };

  const handleOnCategoryEditModalVolume = () => {
    const {
      category_index,
      minimum_quantity,
      maximum_quantity,
      percent_discount_value_item,
      max_discount_Value_item,
      volume_treshold_Perc_edit
    } = categoryEditVolumeForm.getFieldsValue();
    const updatedData = categoryData?.data?.map((item: any) => {
      if (item.item_id === category_index) {
        return {
          ...item,
          minimum_quantity: minimum_quantity,
          maximum_quantity: maximum_quantity,
          discount_value: percent_discount_value_item,
          max_discount: max_discount_Value_item,
          volume_Threshold_qty: volume_treshold_Perc_edit
        };
      }
      return item;
    });
    let combData = {
      data: updatedData
    };
    setCategoryData(combData);
    displaySuccessNotification({ message: `Index of ${category_index} Category Updated!` });
    setCategoryEditModalVolume(false);
  };

  const handleOnSpecialPriceEditModalBundle = () => {
    const { sku, quantity, special_price } = specialPriceEditBundleForm.getFieldsValue();

    const updateData = specialPriceData?.data?.map((item: any) => {
      if (item.sku === sku) {
        return {
          ...item,
          quantity: quantity,
          special_price: special_price
        };
      }
      return item;
    });
    let combData = {
      data: updateData
    };
    setSpecialPriceData(combData);
    setActiveKey([]);
    setCategoryEditModalBundle(false);
    displaySuccessNotification({ message: 'Item Updated!!' });
  };

  const handleOnPercentEditModalBundle = () => {
    const { sku, min_quantity, max_quantity, discount_value, maximum_discount_value } =
      percentageEditBundleForm.getFieldsValue();

    const updateData = fixedPriceData?.data?.map((item: any) => {
      if (item.sku === sku) {
        return {
          ...item,
          min_quantity: min_quantity,
          max_quantity: max_quantity,
          discount_value: discount_value,
          maximum_discount_value: maximum_discount_value
        };
      }
      return item;
    });
    let combData = {
      data: updateData
    };
    setfixedPriceData(combData);
    setActiveKey([]);
    setCategoryPercentEditModalBundle(false);
    displaySuccessNotification({ message: 'Item Updated!!' });
  };

  const handleOnFixedEditModalBundle = () => {
    const { sku, min_quantity, max_quantity, discount_value, maximum_discount_value } =
      FixedEditBundleForm.getFieldsValue();

    const updateData = percentPriceData?.data?.map((item: any) => {
      if (item.sku === sku) {
        return {
          ...item,
          min_quantity: min_quantity,
          max_quantity: max_quantity,
          discount_value: discount_value,
          maximum_discount_value: maximum_discount_value
        };
      }
      return item;
    });
    let combData = {
      data: updateData
    };
    setPercentPriceData(combData);
    setActiveKey([]);
    setCategoryEditFixedModalBundle(false);
    displaySuccessNotification({ message: 'Item Updated!!' });
  };

  const disableFields = () => {
    let disable = false;
    if (
      categoryData?.data?.length > 0 ||
      specialPriceCategory?.length > 0 ||
      fixedPriceCategory?.length > 0 ||
      percentPriceCategory?.length > 0 ||
      specialPriceSTCategory?.length > 0 ||
      percentPriceSTCategory?.length > 0 ||
      fixedPriceSTCategory?.length > 0 ||
      specialPriceVolumeCategory.length > 0 ||
      percentPriceVolumeCategory.length > 0 ||
      fixedPriceVolumeCategory?.length > 0 ||
      bxgyData?.length > 0
    ) {
      disable = true;
    }
    return disable;
  };

  const disableSaveButton = () => {
    let disable = false;
    const { promotionType, appliesTo } = promotionForm.getFieldsValue();
    if (promotionType === 'SHIPPING_DISC') {
      return;
    } else if (promotionType === 'SUB_TTL_DIS' && appliesTo === 'ORDER_TOTAL') {
      return;
    } else {
      disable = !disableFields();
    }
    return disable;
  };

  const maxUsageFieldDisable = () => {
    var disable = false;
    const { use_max_limit } = promotionForm.getFieldsValue();
    if (use_max_limit) {
      disable = true;
    } else {
      disable = false;
    }
    return disable;
  };

  const getTresholdOptionsforSubTotal = () => {
    const { appliesTo, promotion_type } = promotionForm.getFieldsValue();
    let options: any = [];
    if (appliesTo === 'ORDER_TOTAL') {
      let data = [
        { label: 'None', value: 'NO_THRESHOLD' },
        { label: 'MOV', value: 'MOV' },
        { label: 'MOQ', value: 'MOQ' }
      ];
      data.forEach(item => {
        options.push(item);
      });
      return options;
    }
    if (appliesTo === 'LINE_ITEMS') {
      let data = [{ label: 'None', value: 'NO_THRESHOLD' }];
      data.forEach(item => {
        options.push(item);
      });
      return options;
    }
    let data = [
      { label: 'None', value: 'NO_THRESHOLD' },
      { label: 'MOV', value: 'MOV' },
      { label: 'MOQ', value: 'MOQ' },
      { label: 'QTY', value: 'QUANTITY' }
    ];
    data.forEach(item => {
      options.push(item);
    });
    return options;
  };

  const handleOnSelectRange = (e: any) => {
    if (e.target.checked) {
      promotionForm.setFieldsValue({
        volume_Threshold_qty: [
          {
            max_limit_set: false
          }
        ]
      });
    } else {
      promotionForm.setFieldsValue({
        volume_Threshold_qty: null
      });
    }
  };

  return (
    <Form form={promotionForm} scrollToFirstError layout="vertical" disabled={viewOnly}>
      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Title"
              name="title"
              rules={getTitleValidator({
                requiredMessage: 'This field is required',
                maxLength: 150,
                maxLengthMessage: 'Title cannot be more than 150 characters'
                // allowedSymbolsMessage: 'Title can only contain letters, numbers, spaces, and the "@" symbol'
              })}
            >
              <Input size="large" placeholder="Title" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="Status" name="status">
              <Input size="large" defaultValue={'Open'} placeholder="Status" disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Trigger"
              name="trigger"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select
                onChange={triggerSelectionHandler}
                placeholder="Select an option"
                size="large"
                disabled={true}
                options={[
                  { label: 'Automatic', value: 'AUTOMATIC' },
                  { label: 'Manual', value: 'MANUAL' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Promotion Type"
              name="promotionType"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select
                disabled={true}
                placeholder="Select an option"
                size="large"
                optionFilterProp="value"
                options={promotionTypeList}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Country"
              name="country"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select
                onChange={changeCountryHandler}
                placeholder="Select an option"
                size="large"
                options={countryListOptions}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Time Zone"
              name="timeZone"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select placeholder="Select an option" size="large" options={timeZoneList}></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Currency"
              name="currency"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select placeholder="Select an option" size="large" options={currencyTypeList}></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Form.Item noStyle shouldUpdate>
            {form => {
              const { trigger, promotionType } = form.getFieldsValue();
              return (
                trigger === 'MANUAL' &&
                promotionType !== 'LOYT_REWARD' && (
                  <Col xs={24} md={6} className="mt-8">
                    <Form.Item name="next_order_applicable" valuePropName="checked">
                      <Checkbox value={'order_applicable'}>Next Order Applicable ?</Checkbox>
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {form => {
              const { trigger, next_order_applicable } = form.getFieldsValue();
              return (
                trigger === 'MANUAL' &&
                next_order_applicable && (
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="no_of_next_orders"
                      label="Number Of Next Orders"
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        getFormattedNumeric2DecimalPointValidatorForInput({
                          message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                        })
                      ]}
                    >
                      <InputNumber
                        min={1}
                        className="w-full"
                        size="large"
                        placeholder="Number of Next Order"
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>
        </Row>
      </div>
      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Typography.Title level={4}>Qualifying Conditions</Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Applies to Class"
              name="appliesTo"
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select item values"
                size="large"
                allowClear
                disabled={
                  promotionForm.getFieldsValue().promotionType === 'VOLUME_DISC' ||
                  promotionForm.getFieldsValue().promotionType === 'BUNDLE_DISC' ||
                  promotionForm.getFieldsValue().promotionType === 'BXGY' ||
                  promotionForm.getFieldsValue().promotionType === 'SHIPPING_DISC' ||
                  promotionForm.getFieldsValue().promotionType === 'LOYT_REWARD' ||
                  viewOnly
                }
                onChange={option => {
                  if (option === 'LOYT_POINTS') {
                    promotionForm.setFieldsValue({ rewardType: 'POINTS' });
                  } else {
                    promotionForm.setFieldsValue({ rewardType: 'DISCOUNT' });
                  }
                }}
                options={
                  promotionForm.getFieldsValue().promotionType === 'SUB_TTL_DIS'
                    ? appliesToClassForSubTotal
                    : appliesToClassForAll
                }
              ></Select>
            </Form.Item>
          </Col>

          <Form.Item noStyle shouldUpdate>
            {form => {
              const { appliesTo } = form.getFieldsValue();
              return (
                appliesTo === 'LOYT_POINTS' && (
                  <>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Reward Loyalty Name"
                        name="reward_name"
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Input placeholder="Enter reward loyalty name" size="large"></Input>
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Reward Loyalty Points"
                        name="reward_value"
                        rules={[
                          { required: true, message: "This field can't be empty" },
                          getFormattedNumeric2DecimalPointValidatorForInput({
                            message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                          })
                        ]}
                      >
                        <InputNumber
                          className="w-full"
                          min={1}
                          placeholder="Enter reward loyalty points"
                          size="large"
                        ></InputNumber>
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>

          <Col xs={12} md={6}>
            <Form.Item noStyle shouldUpdate>
              {form => {
                const { appliesTo } = form.getFieldsValue();
                const isDisabled = ['LOYT_POINTS', 'ORDER_TOTAL', 'LINE_ITEMS', 'SHIPPING', 'PAYMENTS'].includes(
                  appliesTo
                );
                return (
                  <Form.Item
                    label="Reward Type"
                    name="rewardType"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      disabled={isDisabled}
                      placeholder="Select"
                      size="large"
                      options={[
                        { label: 'Products', value: 'PRODUCT' },
                        { label: 'Discount', value: 'DISCOUNT' },
                        { label: 'Volume Discount', value: 'VOLUME_DISCOUNT' },
                        { label: 'Loyalty Points', value: 'POINTS' },
                        { label: 'Bundle Discount', value: 'BUNDLE_DISCOUNT' }
                      ]}
                    ></Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        {/* REDESIGNED PRODUCT SELECTION */}
        <Form.Item noStyle shouldUpdate>
          {form => {
            const { promotionType } = form.getFieldsValue();
            return promotionType !== 'BXGY' ? (
              <section>
                <Row>
                  <Col xs={24}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => {
                        return prevValues.productSelection_RC !== curValues.productSelection_RC;
                      }}
                    >
                      {form => {
                        const { productSelection_RC } = form.getFieldsValue();
                        return productSelection_RC === 'SPECIFIC' ? (
                          <div>
                            <span className="">Product Attribute Filters</span>
                            <div className="my-4">
                              <Form.List name="critereas">
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                      <React.Fragment key={key}>
                                        <Row>
                                          <Col xs={12}>
                                            <Divider orientation="left" plain>
                                              <span className="text-lg">Attribute - {index + 1} </span>
                                            </Divider>
                                          </Col>
                                        </Row>

                                        <Row gutter={12}>
                                          <Col xs={12} md={6}>
                                            <Form.Item noStyle shouldUpdate>
                                              {form => {
                                                const attributes = [
                                                  { label: 'SKU', value: 'SKU' },
                                                  { label: 'Category', value: 'CATEGORY' },
                                                  { label: 'Brand', value: 'BRAND' },
                                                  { label: 'Model', value: 'MODEL' }
                                                ];

                                                const selectedTypes = (form.getFieldsValue()?.critereas || [])?.map(
                                                  (criterea: any) => {
                                                    return criterea?.attribute_type;
                                                  }
                                                );

                                                const filteredAttributes = attributes.filter(attr => {
                                                  return !selectedTypes.includes(attr.value);
                                                });

                                                return (
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, 'attribute_type']}
                                                    label="Attribute type"
                                                    rules={[{ required: true, message: 'Attribute Type is required' }]}
                                                  >
                                                    <Select
                                                      size="large"
                                                      placeholder="Select Attribute type"
                                                      options={filteredAttributes}
                                                    ></Select>
                                                  </Form.Item>
                                                );
                                              }}
                                            </Form.Item>
                                          </Col>

                                          <Form.Item noStyle shouldUpdate>
                                            {form => {
                                              const isDataPresent = form.getFieldsValue()?.critereas?.length;

                                              const attributeType = isDataPresent
                                                ? form.getFieldsValue()?.critereas[index]?.attribute_type
                                                : '';

                                              const inputMap: Record<string, any> = {
                                                SKU: {
                                                  name: 'sku',
                                                  label: 'SKUs',
                                                  component: (
                                                    <Select
                                                      mode="multiple"
                                                      allowClear
                                                      size="large"
                                                      onSearch={async searchTerm => {
                                                        if (searchTerm)
                                                          await debouncedFetchProductDropdownOptions(searchTerm);
                                                      }}
                                                      filterOption={false}
                                                      placeholder="Search products"
                                                      notFoundContent={
                                                        productDropdownFetching ? (
                                                          <Spin size="small" />
                                                        ) : (
                                                          <>No Data Found</>
                                                        )
                                                      }
                                                      options={productDropdownOptions}
                                                    ></Select>
                                                  )
                                                },
                                                CATEGORY: {
                                                  name: 'category',
                                                  label: 'Category',
                                                  component: (
                                                    <Select
                                                      mode="tags"
                                                      allowClear
                                                      options={[]}
                                                      size="large"
                                                      placeholder="Select / Enter Category"
                                                    ></Select>
                                                  )
                                                },
                                                BRAND: {
                                                  name: 'brand',
                                                  label: 'Brands',
                                                  component: (
                                                    <Select
                                                      mode="tags"
                                                      allowClear
                                                      options={[]}
                                                      size="large"
                                                      placeholder="Select / Enter Brands"
                                                    ></Select>
                                                  )
                                                },
                                                MODEL: {
                                                  name: 'model',
                                                  label: 'Model',
                                                  component: (
                                                    <Select
                                                      mode="tags"
                                                      allowClear
                                                      options={[]}
                                                      size="large"
                                                      placeholder="Select / Enter Models"
                                                    ></Select>
                                                  )
                                                }
                                              };

                                              const componentInfo = inputMap[attributeType];
                                              return (
                                                componentInfo && (
                                                  <Col xs={12} md={6}>
                                                    <Form.Item
                                                      {...restField}
                                                      name={[name, componentInfo?.name]}
                                                      label={componentInfo?.label}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: `${componentInfo?.label} is required`
                                                        }
                                                      ]}
                                                    >
                                                      {componentInfo?.component}
                                                    </Form.Item>
                                                  </Col>
                                                )
                                              );
                                            }}
                                          </Form.Item>

                                          <div className="w-full md:w-[50px] md:mx-8 flex justify-center items-center">
                                            <DeleteOutlined
                                              className="text-red-500 text-xl"
                                              title={`Delete Attribute ${index + 1}`}
                                              onClick={() => remove(name)}
                                            />
                                          </div>
                                        </Row>
                                      </React.Fragment>
                                    ))}
                                    <Form.Item>
                                      <Row justify={'start'}>
                                        <Col xs={24} md={4}>
                                          <Button onClick={() => add()} icon={<PlusOutlined />}>
                                            <BoldButtonLabel labelText="Add Attribute" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </div>
                          </div>
                        ) : null;
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </section>
            ) : null;
          }}
        </Form.Item>
        <Typography.Title level={5}>Redeemable Channel</Typography.Title>
        <Row gutter={12}>
          <Col xs={24} className="mb-2">
            <Typography.Text className="font-semibold text-sm">Available On</Typography.Text>
          </Col>
          <Col xs={12} md={2}>
            <Form.Item
              name="isAvailablePos"
              valuePropName="checked"
              dependencies={['isAvailableOnline']} // Dependency to ensure both fields are watched
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isAvailableOnline = getFieldValue('isAvailableOnline');
                    if (isAvailableOnline || value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Select at least one option (E Com or STORE).'));
                  }
                })
              ]}
            >
              <Checkbox>ECom</Checkbox>
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.isAvailablePos !== curValues.isAvailablePos;
            }}
          >
            {form => {
              const { isAvailablePos } = form.getFieldsValue();
              return (
                isAvailablePos && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label=""
                      name="chooseEcomType"
                      rules={[
                        {
                          required: false,
                          message: 'Field Is Required'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select an option"
                        size="large"
                        options={[
                          { label: 'All', value: 'All' },
                          { label: 'Specific', value: 'Specific' }
                        ]}
                      ></Select>
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return (
                prevValues.isAvailablePos !== curValues.isAvailablePos ||
                prevValues.chooseEcomType !== curValues.chooseEcomType
              );
            }}
          >
            {form => {
              const { isAvailablePos } = form.getFieldsValue();
              const { chooseEcomType } = form.getFieldsValue();
              return isAvailablePos && chooseEcomType === 'Specific' ? (
                <Col xs={24} md={6}>
                  <Form.Item label="" name={'posList'}>
                    <Select
                      mode="multiple"
                      allowClear
                      size="large"
                      placeholder="Choose Specific Channel Name"
                      filterOption={false}
                      options={ecomOptions}
                      // onSearch={async searchTerm => {
                      //   if (searchTerm) await debouncedFetchPosDropdownOptions(searchTerm);
                      // }}
                      // notFoundContent={posDropdownFetching ? <Spin size="small" /> : null}
                    ></Select>
                  </Form.Item>
                </Col>
              ) : null;
            }}
          </Form.Item>
        </Row>
        <Row gutter={12}>
          <Col xs={12} md={2}>
            <Form.Item
              name="isAvailableOnline"
              valuePropName="checked"
              dependencies={['isAvailablePos']} // Dependency to ensure both fields are watched
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isAvailablePos = getFieldValue('isAvailablePos');
                    if (isAvailablePos || value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Select at least one option (E Com or STORE).'));
                  }
                })
              ]}
            >
              <Checkbox>Store</Checkbox>
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.isAvailablePos !== curValues.isAvailablePos;
            }}
          >
            {form => {
              const { isAvailablePos } = form.getFieldsValue();
              return (
                isAvailablePos && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label=""
                      name="chooseStoreType"
                      rules={[
                        {
                          required: false,
                          message: 'Field Is Required'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select an option"
                        size="large"
                        options={[
                          { label: 'All', value: 'All' },
                          { label: 'Specific', value: 'Specific' }
                        ]}
                      ></Select>
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return (
                prevValues.isAvailableOnline !== curValues.isAvailableOnline ||
                prevValues.chooseStoreType !== curValues.chooseStoreType
              );
            }}
          >
            {form => {
              const { isAvailableOnline, chooseStoreType } = form.getFieldsValue();
              return isAvailableOnline && chooseStoreType === 'Specific' ? (
                <Col xs={24} md={6}>
                  <Form.Item label="" name={'onlineList'}>
                    <Select
                      mode="multiple"
                      allowClear
                      size="large"
                      placeholder="Choose Specific Store Name"
                      filterOption={false}
                      options={storeOptions}
                    ></Select>
                  </Form.Item>
                </Col>
              ) : null;
            }}
          </Form.Item>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {form => {
            const { appliesTo } = form.getFieldsValue();
            return appliesTo !== 'ORDER_TOTAL' ? (
              <>
                <Typography.Title level={5}>Exclusion Condition</Typography.Title>
                <Row gutter={12}>
                  <Col xs={24} md={6}>
                    <Form.Item label="Excluded Products" name={'excluded_products'}>
                      <Select
                        mode="multiple"
                        allowClear
                        size="large"
                        onSearch={async searchTerm => {
                          if (searchTerm) await debouncedFetchProductDropdownOptions(searchTerm);
                        }}
                        filterOption={false}
                        placeholder="Excluded products"
                        notFoundContent={productDropdownFetching ? <Spin size="small" /> : <>No Data Found</>}
                        options={productDropdownOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : null;
          }}
        </Form.Item>

        <Typography.Title level={5}>Restriction Condition</Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Only For New User"
              name="onlyForNewUser"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select
                placeholder="Select an option"
                size="large"
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        {/* Removing IT Because Not Working */}
        {/* <Form.Item noStyle shouldUpdate>
          {form => {
            const { appliesTo } = form.getFieldsValue();

            return appliesTo !== 'ORDER_TOTAL' ? (
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    name={'seller_Selection'}
                    label="Seller Selection"
                    rules={[{ required: false, message: 'Seller selection is required' }]}
                  >
                    <Select
                      options={[
                        { label: 'All', value: 'ALL' },
                        { label: 'Specific', value: 'SPECIFIC' }
                      ]}
                      size="large"
                      placeholder="Select seller selection"
                    ></Select>
                  </Form.Item>
                </Col>
                <Form.Item noStyle shouldUpdate>
                  {form => {
                    const { seller_Selection } = form.getFieldsValue();
                    return seller_Selection === 'SPECIFIC' ? (
                      <Col xs={12} md={6}>
                        <Form.Item label="Seller" name="specific_seller">
                          <Select
                            mode="multiple"
                            filterOption={false}
                            allowClear
                            onSearch={async searchTerm => {
                              if (searchTerm) {
                                await debouncedFetchSellerDropdownOptions(searchTerm);
                              }
                            }}
                            notFoundContent={sellerDropdownFetching ? <Spin size="small" /> : <>No Data Found</>}
                            size="large"
                            placeholder="Search Sellers"
                            options={sellerDropdownOptions}
                          ></Select>
                        </Form.Item>
                      </Col>
                    ) : null;
                  }}
                </Form.Item>
              </Row>
            ) : null;
          }}
        </Form.Item> */}
      </div>
      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Typography.Title level={5}>Usage</Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Limit per user"
              name="limitPerUser"
              rules={[
                { required: true, message: "This field can't be empty" },
                validationHelpers.getNumericValidatorForInput({}),
                {
                  // Custom validator to check if limitPerUser is greater than maxUsage
                  validator: async (_, value) => {
                    const maxUsage = Number(promotionForm.getFieldValue('maxUsage'));
                    let currentValue = Number(value);
                    if (currentValue > maxUsage) {
                      return Promise.reject(new Error('Limit per user cannot exceed Max Usage'));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input size="large" placeholder="Limit per user" />
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return (
                prevValues.use_max_limit !== curValues.use_max_limit ||
                prevValues.maxUsage !== curValues.maxUsage ||
                prevValues.limitPerUser !== curValues.limitPerUser
              );
            }}
          >
            {({ getFieldValue }) => {
              const MaxLimit = getFieldValue('use_max_limit');
              if (MaxLimit) {
                return null;
              }
              return (
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Max Usage"
                    name="maxUsage"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({}),
                      {
                        // Custom validator to check if maxUsage is greater than limitPerUser
                        validator: async (_, value) => {
                          const limitPerUser = Number(promotionForm.getFieldValue('limitPerUser'));
                          let lpu = Number(value);
                          if (lpu < limitPerUser) {
                            return Promise.reject(
                              new Error('Max Usage should be greater than or equal to Limit per user')
                            );
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Max Usage"
                      disabled={maxUsageFieldDisable() || MaxLimit || viewOnly}
                    />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>
          <Col xs={12} md={3} className="mt-8">
            <Form.Item valuePropName="checked" name={'use_max_limit'}>
              <Checkbox
                onChange={(e: any) => {
                  if (e.target.checked) {
                    promotionForm.setFieldsValue({ maxUsage: MAX_LIMIT });
                  } else {
                    promotionForm.setFieldsValue({ maxUsage: null });
                  }
                }}
              >
                Use Max Limit
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Typography.Title level={5}>Schedule</Typography.Title>

        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Date"
              name="dateRangeInSchedule"
              rules={[{ required: true, message: 'Fill start and end date' }]}
            >
              <DatePicker.RangePicker
                onChange={value => {
                  promotionForm.setFieldsValue({
                    timeRangeInSchedule_startTime: null,
                    timeRangeInSchedule_endTime: null
                  });
                  if (!promotionForm.getFieldsValue()?.dateRangeInDiscount?.length)
                    promotionForm.setFieldsValue({ dateRangeInDiscount: value });
                }}
                size="large"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            {/* Start Times */}
            <Form.Item
              label="Start Time"
              name="timeRangeInSchedule_startTime"
              rules={[{ required: true, message: 'Fill start time' }]}
            >
              <TimePicker
                className="w-[100%]"
                onChange={value => {
                  promotionForm.setFieldsValue({ timeRangeInSchedule_endTime: '' });
                  if (!promotionForm.getFieldsValue()?.timeRangeInDiscount_StartTime?.length)
                    promotionForm.setFieldsValue({ timeRangeInDiscount_StartTime: value });
                }}
                onBlur={e => {
                  if (e.target.value === '') {
                    promotionForm.setFieldsValue({ timeRangeInDiscount_StartTime: '' });
                    promotionForm.setFieldsValue({ timeRangeInSchedule_endTime: '' });
                    return;
                  }
                  let startingTime = dayjs(e.target.value, 'HH:mm');
                  promotionForm.setFieldsValue({ timeRangeInSchedule_startTime: startingTime });
                  promotionForm.setFieldsValue({ timeRangeInSchedule_endTime: '' });
                }}
                size="large"
                format={'HH:mm'}
                disabledTime={disabledNewTime}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            {/* End Times */}
            <Form.Item
              label="End Time"
              name="timeRangeInSchedule_endTime"
              rules={[
                { required: true, message: 'Fill end time' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const startTime = getFieldValue('timeRangeInSchedule_startTime');
                    const dateRange = getFieldValue('dateRangeInSchedule');
                    if (dateRange && startTime && value) {
                      const startDate = dayjs(dateRange[0]).format('YYYY-MM-DD');
                      const endDate = dayjs(dateRange[1]).format('YYYY-MM-DD');
                      const isSameDay = startDate === endDate;

                      if (isSameDay && dayjs(value).isBefore(dayjs(startTime))) {
                        return Promise.reject(new Error('End Time must be greater than Start Time on the same day'));
                      }
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <TimePicker
                className="w-[100%]"
                onChange={value => {
                  if (!promotionForm.getFieldsValue()?.timeRangeInDiscount_EndTime?.length)
                    promotionForm.setFieldsValue({ timeRangeInDiscount_EndTime: value });
                }}
                // onBlur={e => {
                //   if (e.target.value === '') {
                //     promotionForm.setFieldsValue({ timeRangeInSchedule_endTime: '' });
                //     return;
                //   }
                //   let endTime = dayjs(e.target.value, 'HH:mm');
                //   promotionForm.setFieldsValue({ timeRangeInSchedule_endTime: endTime });
                // }}
                size="large"
                format={'HH:mm'}
                disabledTime={disabledNewTime}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <div style={{ marginBottom: '15px' }}>
            <Radio.Group onChange={handleValidTimeFrame} value={validTimeFrame}>
              <Radio value={1}>Valid in this timeframe only</Radio>
              <Radio value={2}>Valid on particular days only</Radio>
            </Radio.Group>
          </div>
        </Row>
        {validTimeFrame === 2 && <Typography.Title level={5}>Schedule Filter</Typography.Title>}
        {validTimeFrame === 2 && (
          <Row gutter={12}>
            <div
              style={{
                height: 'fit-content',
                width: '100%',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Form.Item name="daily_val" valuePropName="checked">
                <Checkbox value={'daily_val'}>Daily</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.daily_val !== curValues.daily_val;
                }}
              >
                {form => {
                  const { daily_val } = form.getFieldsValue();
                  return daily_val ? (
                    <Row gutter={12}>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label="Time"
                          name="timeRangeInScheduleFilter"
                          rules={[
                            { required: false, message: 'Fill start time and end time' },

                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const startTime = getFieldValue('timeRangeInSchedule_startTime');
                                const endTime = getFieldValue('timeRangeInSchedule_endTime');

                                if (!value || !startTime || !endTime) {
                                  return Promise.resolve(); // Skip validation if no values are present
                                }

                                const [rangeStart, rangeEnd] = value;
                                if (rangeStart.isBefore(startTime) || rangeEnd.isAfter(endTime)) {
                                  return Promise.reject(
                                    new Error('Time range must be within the start and end times of Scheduled')
                                  );
                                }

                                return Promise.resolve();
                              }
                            })
                          ]}
                        >
                          <RangePicker size="large" format={'HH:mm'} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item name="week_val" valuePropName="checked">
                <Checkbox value={'week_val'}>Weekly</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.week_val !== curValues.week_val;
                }}
              >
                {form => {
                  const { week_val } = form.getFieldsValue();
                  return week_val ? (
                    <Row gutter={12}>
                      <Form.Item name="weekValues">
                        <Checkbox.Group>
                          {weeks?.map((item: any) => {
                            return (
                              <Col>
                                <Checkbox value={item.value}>{item.label}</Checkbox>
                              </Col>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </Row>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item name="month_val" valuePropName="checked">
                <Checkbox value={'month_val'}>Monthly</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.month_val !== curValues.month_val;
                }}
              >
                {form => {
                  const { month_val } = form.getFieldsValue();
                  return month_val ? (
                    <Form.Item name={'monthValues'}>
                      <Checkbox.Group>
                        <Row gutter={12}>
                          {months?.map((item: any) => {
                            return <Checkbox value={item.value}>{item.label}</Checkbox>;
                          })}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
              {/* <Form.Item name="seasonal_val" valuePropName="checked">
                <Checkbox value={'seasonal_val'}>Seasonaly</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.seasonal_val !== curValues.seasonal_val;
                }}
              >
                {form => {
                  const { seasonal_val } = form.getFieldsValue();
                  return seasonal_val ? (
                    <Form.Item name={'seasonalValues'}>
                      <Checkbox.Group>
                        <Row gutter={12}>
                          {seasons?.map((item: any) => {
                            return <Checkbox value={item.value}>{item.label}</Checkbox>;
                          })}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item> */}
              <Form.Item name="special_day" valuePropName="checked">
                <Checkbox value={'special_day'}>Special Day</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.special_day !== curValues.special_day;
                }}
              >
                {form => {
                  const { special_day } = form.getFieldsValue();
                  return special_day ? (
                    <Form.Item name={'specialdays'}>
                      <Row gutter={12}>
                        <Checkbox.Group>
                          {special_day_list?.map((item: any) => {
                            return <Checkbox value={item.value}>{item.label}</Checkbox>;
                          })}
                        </Checkbox.Group>
                      </Row>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </div>
          </Row>
        )}
      </div>
      {/* REWARDS */}
      <Form.Item noStyle shouldUpdate>
        {form => {
          const { appliesTo, promotionType, discount_type, applies_to_price, range } = form.getFieldsValue();
          return (
            appliesTo !== 'LOYT_POINTS' && (
              <>
                <Typography.Title level={4}>Rewards</Typography.Title>
                <div
                  style={{
                    height: 'fit-content',
                    width: '100%',
                    background: '#9b9b9b29',
                    padding: '20px',
                    borderRadius: '20px',
                    marginBottom: '15px'
                  }}
                >
                  <Form.Item noStyle shouldUpdate>
                    {form => {
                      const { discount_type, promotionType, range, applies_to_price, appliesTo } =
                        form.getFieldsValue();
                      return (
                        <>
                          {promotionType === 'BUNDLE_DISC' && bundleUIRenderer(discount_type)}
                          {promotionType === 'BXGY' && bxgyUIRenderer(discount_type, applies_to_price)}
                          {promotionType === 'SUB_TTL_DIS' && subTotalDiscountUIRenderer(discount_type, appliesTo)}
                          {promotionType === 'SHIPPING_DISC' && shippingDiscountUIRenderer()}
                          {promotionType === 'VOLUME_DISC' && (
                            <>
                              {VolumeUIRenderer(discount_type, promotionType)}
                              {renderItemCategoryVolumeUI(discount_type, range)}
                            </>
                          )}
                        </>
                      );
                    }}
                  </Form.Item>
                </div>
              </>
            )
          );
        }}
      </Form.Item>
      <Typography.Title level={4}>Additional Configuration</Typography.Title>
      <Form.Item shouldUpdate noStyle>
        {form => {
          const { appliesTo } = form.getFieldsValue();
          return appliesTo !== 'LOYT_POINTS' ? (
            <div
              style={{
                height: 'fit-content',
                width: '100%',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Row gutter={12}>
                {appliesTo !== 'ORDER_TOTAL' && (
                  <Col xs={12} md={8}>
                    <Form.Item name="applies_to_product_in_sale" valuePropName="checked">
                      <Checkbox>Applies to products in sale?</Checkbox>
                    </Form.Item>
                  </Col>
                )}

                <Col xs={12} md={8}>
                  <Form.Item name="combine_with_other_promo" valuePropName="checked">
                    <Checkbox>Combine with other promotions?</Checkbox>
                  </Form.Item>
                </Col>

                {appliesTo !== 'ORDER_TOTAL' && (
                  <Col xs={12} md={8}>
                    <Form.Item name="ignore_price_lock" valuePropName="checked">
                      <Checkbox>Ignore price lock?</Checkbox>
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {promotionForm.getFieldsValue().trigger !== 'AUTOMATIC' && (
                <Row gutter={12}>
                  <Col xs={12} md={8}>
                    <Typography.Title level={5}>Visible On PopUp</Typography.Title>
                    <div className="flex">
                      <Radio.Group onChange={onChangeRadio} value={radioValue}>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={2}>No</Radio>
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          ) : null;
        }}
      </Form.Item>
      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              label="User Type Selection"
              name="for_generate"
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder=" Type"
                size="large"
                onChange={() => {
                  //!Reset all fields depended on this field
                  promotionForm.setFieldsValue({
                    customerRestriction: null,
                    filters: [],
                    userSelection: null,
                    specific_buyer: []
                  });
                }}
                options={[
                  { label: 'Anonymous user', value: 'ANONYMOUS' },
                  { label: 'Registered user', value: 'REGISTERED_USER' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.for_generate !== curValues.for_generate;
            }}
          >
            {form => {
              const { for_generate } = form.getFieldsValue();
              return for_generate === 'REGISTERED_USER' ? (
                <Col xs={12} md={8}>
                  <Form.Item
                    label="Buyer Selection"
                    name="customerRestriction"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      placeholder="Select"
                      onChange={() => {
                        //!Reset all fields depended on this field
                        promotionForm.setFieldsValue({
                          userSelection: null,
                          specific_buyer: [],
                          filters: []
                        });
                      }}
                      size="large"
                      options={[
                        { label: 'Everyone', value: 'ALL' },
                        { label: 'Specific', value: 'SPECIFIC' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              ) : null;
            }}
          </Form.Item>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return (
                  prevValues.for_generate !== curValues.for_generate ||
                  prevValues.customerRestriction !== curValues.customerRestriction
                );
              }}
            >
              {form => {
                const { for_generate, customerRestriction } = form.getFieldsValue();
                return for_generate === 'REGISTERED_USER' && customerRestriction === 'ALL' ? (
                  <Row>
                    <Col xs={24}>
                      <div>
                        <span className="text-lg font-medium">Filters</span>
                        <div className="my-4">
                          <Form.List name="filters">
                            {(fields, { add, remove }) => {
                              const filterOptionsAvailable = filterRespose.some(option => {
                                const filters = form.getFieldValue('filters') || [];
                                const selectedTypes = filters?.map((f: any) => f?.filter_type);
                                return !selectedTypes.includes(option.value);
                              });
                              return (
                                <>
                                  {fields.map(({ key, name, ...restField }, index) => (
                                    <React.Fragment key={key}>
                                      <Row>
                                        <Col xs={12}>
                                          <Divider orientation="left" plain>
                                            <span className="text-lg">Filter - {index + 1}</span>
                                          </Divider>
                                        </Col>
                                      </Row>

                                      <Row gutter={12}>
                                        <Col xs={12} md={6}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'filter_type']}
                                            label="Filter type"
                                            rules={[{ required: true, message: 'Filter Type is required' }]}
                                          >
                                            <Select
                                              size="large"
                                              allowClear
                                              placeholder="Select Filter type"
                                              options={filterRespose.filter(option => {
                                                const filters = form.getFieldValue('filters') || [];
                                                const selectedTypes = filters?.map((f: any) => f?.filter_type);
                                                return !selectedTypes.includes(option.value);
                                              })}
                                              onChange={(value: any) => {
                                                form.setFieldsValue({
                                                  filters: fields.map((field, i) => ({
                                                    ...form.getFieldValue(['filters', i]),
                                                    filter_type:
                                                      i === index
                                                        ? value
                                                        : form.getFieldValue(['filters', i])?.filter_type
                                                  }))
                                                });
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Form.Item noStyle shouldUpdate>
                                          {form => {
                                            const filters: any[] = form.getFieldsValue()?.filters || [];
                                            const attributeType: any = filters[index]?.filter_type;

                                            const inputMap: Record<string, any> = {
                                              TIER: {
                                                name: 'customerTireRestriction',
                                                label: 'Tier Restriction',
                                                component: (
                                                  <Select
                                                    mode="multiple"
                                                    allowClear
                                                    placeholder="Select"
                                                    filterOption={false}
                                                    size="large"
                                                    options={[
                                                      { label: 'Everyone', value: 'EVERYONE' },
                                                      { label: 'Standard', value: 'Standard' },
                                                      { label: 'Gold', value: 'Gold' },
                                                      { label: 'Silver', value: 'Silver' },
                                                      { label: 'Bronze', value: 'Bronze' }
                                                    ]}
                                                  />
                                                )
                                              },
                                              SEGMENT: {
                                                name: 'segment',
                                                label: 'Segment',
                                                component: (
                                                  <Select
                                                    placeholder="Select / Enter"
                                                    mode="tags"
                                                    size="large"
                                                    options={[]}
                                                  />
                                                )
                                              },
                                              RFM_CATEGORY: {
                                                name: 'RFM_CATEGORY',
                                                label: 'RFM Category',
                                                component: (
                                                  <Select
                                                    placeholder="Select / Enter"
                                                    allowClear
                                                    mode="multiple"
                                                    size="large"
                                                    options={categoryOptions}
                                                  />
                                                )
                                              }
                                            };

                                            const componentInfo = inputMap[attributeType];

                                            return (
                                              componentInfo && (
                                                <Col xs={12} md={6}>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, componentInfo.name]}
                                                    label={componentInfo.label}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: `${componentInfo.label} is required`
                                                      }
                                                    ]}
                                                  >
                                                    {componentInfo.component}
                                                  </Form.Item>
                                                </Col>
                                              )
                                            );
                                          }}
                                        </Form.Item>

                                        {fields.length > 1 && (
                                          <div className="w-full md:w-[50px] md:mx-8 flex justify-center items-center">
                                            <DeleteOutlined
                                              className="text-red-500 text-xl"
                                              title={`Delete Filter ${index + 1}`}
                                              onClick={() => remove(name)}
                                            />
                                          </div>
                                        )}
                                      </Row>
                                    </React.Fragment>
                                  ))}
                                  <Form.Item>
                                    <Row justify={'start'}>
                                      <Col xs={24} md={4}>
                                        <Button
                                          onClick={() => add()}
                                          icon={<PlusOutlined />}
                                          disabled={!filterOptionsAvailable || viewOnly}
                                        >
                                          <BoldButtonLabel labelText="Add Filter" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </>
                              );
                            }}
                          </Form.List>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : null;
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return (
              prevValues.for_generate !== curValues.for_generate ||
              prevValues.customerRestriction !== curValues.customerRestriction
            );
          }}
        >
          {form => {
            const { for_generate, customerRestriction } = form.getFieldsValue();
            return for_generate === 'REGISTERED_USER' && customerRestriction === 'SPECIFIC' ? (
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="User"
                    name={'specific_buyer'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      size="large"
                      filterOption={false}
                      placeholder="Search buyer"
                      notFoundContent={userDropdownFetching ? <Spin size="small" /> : <>No Data Found</>}
                      onSearch={async searchTerm => {
                        if (searchTerm) await debouncedFetchUserDropdownOptions(searchTerm);
                      }}
                      options={userDropdownOptions}
                    ></Select>
                  </Form.Item>
                </Col>
                {/* <Col xs={12} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.userSelection !== curValues.userSelection;
                    }}
                  >
                    {form => {
                      const { userSelection } = form.getFieldsValue();
                      return userSelection === 'SPECIFIC' ? (
                        <Form.Item
                          label="User"
                          name={'specific_buyer'}
                          rules={[{ required: true, message: "This field can't be empty" }]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            size="large"
                            filterOption={false}
                            placeholder="Search buyer"
                            notFoundContent={userDropdownFetching ? <Spin size="small" /> : null}
                            onSearch={async searchTerm => {
                              if (searchTerm) await debouncedFetchUserDropdownOptions(searchTerm);
                            }}
                            options={userDropdownOptions}
                          ></Select>
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>
                </Col> */}
              </Row>
            ) : null;
          }}
        </Form.Item>
        {/*! out of scope */}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.for_generate !== curValues.for_generate;
          }}
        >
          {form => {
            const { for_generate } = form.getFieldsValue();
            return for_generate === 'STAFF' ? (
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Staff Selection"
                    name="sellerSelectionforCoupon"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Select
                      placeholder=" Type"
                      size="large"
                      options={[
                        { label: 'All', value: 'ALL' },
                        { label: 'Specific', value: 'SPECIFIC' },
                        { label: 'None', value: 'NONE' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.sellerSelectionforCoupon !== curValues.sellerSelectionforCoupon;
                    }}
                  >
                    {form => {
                      const { sellerSelectionforCoupon } = form.getFieldsValue();
                      return sellerSelectionforCoupon === 'SPECIFIC' ? (
                        <Form.Item label="User" name={'sellerUserforCoupon'}>
                          <Select mode="multiple" allowClear size="large" placeholder="User" options={staff}></Select>
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>
            ) : null;
          }}
        </Form.Item>
      </div>
      {!isUpdate && (
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.trigger !== curValues.trigger;
          }}
        >
          {form => {
            const { trigger } = form.getFieldsValue();
            return trigger === 'MANUAL' ? (
              <div
                style={{
                  height: 'fit-content',
                  width: '100%',
                  background: '#9b9b9b29',
                  padding: '20px',
                  borderRadius: '20px',
                  marginBottom: '15px'
                }}
              >
                <Typography.Title level={5}>Generate</Typography.Title>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'coupon_usage_limit'}
                      label="Coupon Usage Limit"
                      rules={[
                        { message: 'coupon usage limit Field Required', required: true },
                        getFormattedNumeric2DecimalPointValidatorForInput({
                          message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                        })
                      ]}
                    >
                      <InputNumber
                        min={1}
                        className="w-full"
                        size="large"
                        placeholder="coupon usage limit"
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                </Row>

                <Typography.Title level={5}>Discount Code</Typography.Title>
                <Row gutter={12}>
                  <Col xs={12} md={6} style={{ margin: '0px 0px 8px 4px' }}>
                    <Radio.Group onChange={onChangeRadioS_M} value={singleRadio}>
                      <Radio value={1}>Single</Radio>
                      <Radio value={2}>Multiple</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'code_gen'}
                      label=""
                      rules={[
                        { message: 'Reward name is required', required: true }
                        // { max: 40, message: 'Max limit is 40 characters' }
                      ]}
                    >
                      <Input disabled={false} placeholder="<enter>" size="large" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Button size="large" onClick={handleGenerateRandomString}>
                      Autogenerate Code
                    </Button>
                  </Col>
                </Row>
                {singleRadio == Number(2) ? (
                  <Row gutter={12}>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Quantity To Generate"
                        name="quantityToGenerate"
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Input size="large" placeholder="" disabled={false} />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}

                <Typography.Title level={5}>Valid Until</Typography.Title>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Date"
                      name="dateRangeInDiscount"
                      rules={[{ required: true, message: 'Fill start and end date' }]}
                    >
                      <DatePicker.RangePicker size="large" disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Start Time"
                      name="timeRangeInDiscount_StartTime"
                      rules={[{ required: true, message: 'Fill start time and end time' }]}
                    >
                      <TimePicker className="w-[100%]" size="large" format={'HH:mm'} disabledTime={disabledNewTime} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="End Time"
                      name="timeRangeInDiscount_EndTime"
                      rules={[{ required: true, message: 'Fill start time and end time' }]}
                    >
                      <TimePicker className="w-[100%]" size="large" format={'HH:mm'} disabledTime={disabledNewTime} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ) : null;
          }}
        </Form.Item>
      )}
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            label="Priority"
            name="priority"
            rules={[
              { required: true, message: "This field can't be empty" },
              validationHelpers.getNumericValidatorForInput({})
            ]}
          >
            <Input size="large" placeholder="Enter Priority" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="mt-4">
        <Col xs={12} md={6}>
          <Button
            size="large"
            onClick={() => {
              const backUrl = searchParams.get('backUrl');

              if (backUrl) navigate(backUrl);
              else navigate(`/promotion-engine/promotions`);
            }}
            type="default"
            block
            disabled={false}
          >
            <BoldButtonLabel labelText="Back"></BoldButtonLabel>
          </Button>
        </Col>

        {isUpdate && !viewOnly && (
          <Col xs={12} md={6}>
            <Button size="large" type="primary" block onClick={handleFormSaveHandler}>
              <BoldButtonLabel labelText="Update"></BoldButtonLabel>
            </Button>
          </Col>
        )}

        {!isUpdate && !viewOnly && (
          <>
            <Col xs={12} md={6}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    size="large"
                    type="primary"
                    block
                    onClick={handleFormSaveHandler}
                    disabled={disableSaveButton()}
                  >
                    <BoldButtonLabel labelText="Save"></BoldButtonLabel>
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block onClick={scheduleDiscount}>
                <BoldButtonLabel labelText="Schedule Discount"></BoldButtonLabel>
              </Button>
            </Col>{' '}
          </>
        )}
      </Row>
      <Modal
        centered
        open={specialpriceVolumeModal}
        onCancel={() => {
          setSpecialPriceVolumeModal(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Item 1</Typography.Title>
          </div>
        }
      >
        <Form
          form={specialPriceVolumeModalForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnUpdatePercentPriceSpecialModalForm();
          }}
        >
          <Row gutter={24}>
            <Col xs={12} md={6}>
              <Form.Item label="SKU" name={'sku'} rules={[{ required: true, message: "This field can't be empty" }]}>
                <Input size="large" placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Unit Price Ref"
                name={'unit_current_price'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Unit Price" disabled={true} />
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.volume_Threshold_qty_edit !== curValues.volume_Threshold_qty_edit
              }
            >
              {({ getFieldValue }) => {
                const volumeThresholdExists = getFieldValue('volume_Threshold_qty_edit')?.length > 0;
                return (
                  <>
                    {!volumeThresholdExists && (
                      <>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Quantity"
                            name={'quantity'}
                            rules={[{ required: true, message: "This field can't be empty" }]}
                          >
                            <Input size="large" placeholder="Quantity" disabled={false} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Special Price"
                            name={'special_discount_value_edit'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              validationHelpers.getNumeric2DecimalPointValidatorForInput({})
                            ]}
                          >
                            <Input size="large" placeholder="Discount Value" disabled={false} />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {volumeThresholdExists && (
                      <div
                        style={{
                          height: 'fit-content',
                          width: '100%',
                          background: '#e1eded',
                          padding: '20px',
                          borderRadius: '20px',
                          marginBottom: '15px'
                        }}
                      >
                        <Typography.Title level={4}>Threshold </Typography.Title>
                        <Form.List name="volume_Threshold_qty_edit">
                          {(fields, { add, remove }) => (
                            <>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.volume_Threshold_qty_edit !== curValues.volume_Threshold_qty_edit
                                }
                              >
                                {({ getFieldValue, setFieldsValue }) => {
                                  const maxLimitSetChecked = fields.some(({ name }) =>
                                    getFieldValue(['volume_Threshold_qty_edit', name, 'max_limit_set'])
                                  );

                                  return (
                                    <>
                                      {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                                        const maxLimitSet = getFieldValue([
                                          'volume_Threshold_qty_edit',
                                          name,
                                          'max_limit_set'
                                        ]);
                                        const isLastItem = index === fields.length - 1;

                                        return (
                                          <div key={key} {...restField}>
                                            <Row gutter={28}>
                                              <Col xs={12} md={6}>
                                                <Form.Item
                                                  name={[name, 'from_Quantity']}
                                                  label="From Quantity"
                                                  rules={[
                                                    { message: 'Field Is Required', required: true },
                                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                                      message:
                                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                    })
                                                  ]}
                                                >
                                                  <InputNumber
                                                    min={1}
                                                    className="w-full"
                                                    size="large"
                                                    placeholder="From Quantity (Min Threshold val)"
                                                  />
                                                </Form.Item>
                                              </Col>

                                              {!maxLimitSet && (
                                                <Col xs={12} md={6}>
                                                  <Form.Item
                                                    name={[name, 'to_Quantity']}
                                                    label="To Quantity"
                                                    rules={[
                                                      { message: 'Field Is Required', required: true },
                                                      getFormattedNumeric2DecimalPointValidatorForInput({
                                                        message:
                                                          'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                      })
                                                    ]}
                                                  >
                                                    <InputNumber
                                                      min={1}
                                                      className="w-full"
                                                      size="large"
                                                      placeholder="To Quantity (Max Threshold val)"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              )}

                                              <Col xs={12} md={6}>
                                                <Form.Item
                                                  name={[name, 'discount_value']}
                                                  label="Value"
                                                  rules={[
                                                    { message: 'Field Is Required', required: true },
                                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                                      message:
                                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                    })
                                                  ]}
                                                >
                                                  <InputNumber
                                                    min={1}
                                                    className="w-full"
                                                    size="large"
                                                    placeholder="Value"
                                                  />
                                                </Form.Item>
                                              </Col>

                                              {isLastItem && (
                                                <Col xs={12} md={3} className="mt-8">
                                                  <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                                    <Checkbox
                                                      onChange={e => {
                                                        const checked = e.target.checked;
                                                        if (checked) {
                                                          setFieldsValue({
                                                            [`volume_Threshold_qty_edit.${name}.to_Quantity`]: 999999
                                                          });
                                                        }
                                                      }}
                                                    >
                                                      Max Limit Set
                                                    </Checkbox>
                                                  </Form.Item>
                                                </Col>
                                              )}

                                              {fields.length > 1 && (
                                                <Col xs={12} md={2}>
                                                  <div className="mx-4 h-full flex justify-center items-center">
                                                    <DeleteOutlined
                                                      className="text-red-500 text-xl cursor-pointer"
                                                      role="button"
                                                      title="Remove"
                                                      onClick={() => remove(name)}
                                                    />
                                                  </div>
                                                </Col>
                                              )}
                                            </Row>
                                          </div>
                                        );
                                      })}

                                      <Form.Item>
                                        <Button type="primary" onClick={() => add()} disabled={maxLimitSetChecked}>
                                          Add Threshold
                                        </Button>
                                      </Form.Item>
                                    </>
                                  );
                                }}
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </div>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>

          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        centered
        open={percentPriceVolumeModal}
        onCancel={() => {
          setPercentPriceVolumeModal(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Item 2</Typography.Title>
          </div>
        }
      >
        <Form
          form={percentPriceVolumeModalForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnUpdatePercentPriceVolumeModalForm();
          }}
        >
          <Row gutter={24}>
            <Col xs={12} md={6}>
              <Form.Item label="SKU" name={'sku'} rules={[{ required: true, message: "This field can't be empty" }]}>
                <Input size="large" placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Unit Price Ref"
                name={'unit_current_price'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Unit Price" disabled={true} />
              </Form.Item>
            </Col>
            {/* Conditional rendering based on the volume_Threshold_qty_edit field */}
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.volume_Threshold_qty_edit !== curValues.volume_Threshold_qty_edit
              }
            >
              {({ getFieldValue }) => {
                const volumeThresholdExists = getFieldValue('volume_Threshold_qty_edit')?.length > 0;
                return (
                  <>
                    {!volumeThresholdExists && (
                      <>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Minimum Quantity"
                            name={'min_quantity_item_vol'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              validationHelpers.getNumericValidatorForInput({})
                            ]}
                          >
                            <Input size="large" placeholder="Minimum Quantity" disabled={false} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Maximum Quantity"
                            name={'max_quantity_item_vol'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              validationHelpers.getNumericValidatorForInput({})
                            ]}
                          >
                            <Input size="large" placeholder="Maximum Quantity" disabled={false} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Discount Value"
                            name={'discount_value_item_vol'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              validationHelpers.getNumericValidatorForInput({}),
                              {
                                // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                validator: async (_, value) => {
                                  const discountType = promotionForm.getFieldValue('discount_type');
                                  if (discountType === 'PERCENTAGE' && value > 100) {
                                    return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                  }
                                  return Promise.resolve();
                                }
                              },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <Input size="large" placeholder="Discount Value" disabled={false} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Maximum Discount Value"
                            name={'max_discount_item_vol'}
                            rules={[
                              { required: false, message: "This field can't be empty" },
                              validationHelpers.getNumericValidatorForInput({})
                            ]}
                          >
                            <Input size="large" placeholder="Maximum Discount Value" disabled={false} />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {volumeThresholdExists && (
                      <>
                        <div
                          style={{
                            height: 'fit-content',
                            width: '100%',
                            background: '#e1eded',
                            padding: '20px',
                            borderRadius: '20px',
                            marginBottom: '15px'
                          }}
                        >
                          <Typography.Title level={4}>Threshold</Typography.Title>
                          <Form.List name="volume_Threshold_qty_edit">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                                  const maxLimitSet = percentPriceVolumeModalForm.getFieldValue([
                                    'volume_Threshold_qty_edit',
                                    name,
                                    'max_limit_set'
                                  ]);
                                  const isLastItem = index === fields.length - 1;

                                  return (
                                    <div key={key} {...restField}>
                                      <Row gutter={28}>
                                        <Col xs={12} md={6}>
                                          <Form.Item
                                            name={[name, 'from_Quantity']}
                                            label="From Quantity"
                                            rules={[
                                              { message: 'Field Is Required', required: true },
                                              getFormattedNumeric2DecimalPointValidatorForInput({
                                                message:
                                                  'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                              })
                                            ]}
                                          >
                                            <InputNumber
                                              min={1}
                                              className="w-full"
                                              size="large"
                                              placeholder="From Quantity (Min Threshold val)"
                                            />
                                          </Form.Item>
                                        </Col>

                                        {!maxLimitSet && (
                                          <Col xs={12} md={6}>
                                            <Form.Item
                                              name={[name, 'to_Quantity']}
                                              label="To Quantity"
                                              rules={[
                                                { message: 'Field Is Required', required: true },
                                                getFormattedNumeric2DecimalPointValidatorForInput({
                                                  message:
                                                    'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                })
                                              ]}
                                            >
                                              <InputNumber
                                                min={1}
                                                className="w-full"
                                                size="large"
                                                placeholder="To Quantity (Max Threshold val)"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        <Col xs={12} md={6}>
                                          <Form.Item
                                            name={[name, 'discount_value']}
                                            label="Value"
                                            rules={[
                                              { message: 'Field Is Required', required: true },
                                              getFormattedNumeric2DecimalPointValidatorForInput({
                                                message:
                                                  'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                              })
                                            ]}
                                          >
                                            <InputNumber min={1} className="w-full" size="large" placeholder="Value" />
                                          </Form.Item>
                                        </Col>

                                        {isLastItem && (
                                          <Col xs={12} md={3} className="mt-8">
                                            <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                              <Checkbox
                                                onChange={e => {
                                                  const checked = e.target.checked;
                                                  if (checked) {
                                                    percentPriceVolumeModalForm.setFieldsValue({
                                                      [`volume_Threshold_qty_edit.${name}.to_Quantity`]: 999999
                                                    });
                                                  }
                                                }}
                                              >
                                                Max Limit Set
                                              </Checkbox>
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {fields.length > 1 && (
                                          <Col xs={12} md={2}>
                                            <div className="mx-4 h-full flex justify-center items-center">
                                              <DeleteOutlined
                                                className="text-red-500 text-xl cursor-pointer"
                                                role="button"
                                                title="Remove"
                                                onClick={() => remove(name)}
                                              />
                                            </div>
                                          </Col>
                                        )}
                                      </Row>
                                    </div>
                                  );
                                })}

                                <Form.Item>
                                  <Button type="primary" onClick={() => add()}>
                                    Add Threshold
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </div>
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        centered
        open={fixedPriceVolumeModal}
        onCancel={() => {
          setFixedPriceVolumeModal(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Item 3</Typography.Title>
          </div>
        }
      >
        <Form
          form={fixedPriceVolumeModalForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnUpdateFixedPriceVolumeModalForm();
          }}
        >
          <Row gutter={24}>
            <Col xs={12} md={6}>
              <Form.Item label="SKU" name={'sku'} rules={[{ required: true, message: "This field can't be empty" }]}>
                <Input size="large" placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Unit Price Ref"
                name={'unit_current_price'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Unit Price" disabled={true} />
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.volume_Threshold_qty_edit !== curValues.volume_Threshold_qty_edit
              }
            >
              {({ getFieldValue }) => {
                const volumeThresholdExists = getFieldValue('volume_Threshold_qty_edit')?.length > 0;

                // let previousMaxValue = 0;
                // if (subtotalThreshold?.length > 0) {
                //   const lastItem = subtotalThreshold[subtotalThreshold.length - 1];
                //   previousMaxValue = lastItem.maximum_value || 0;
                // }

                return (
                  <>
                    {!volumeThresholdExists && (
                      <>
                        {' '}
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Minimum Quantity"
                            name={'min_quantity'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              validationHelpers.getNumericValidatorForInput({})
                            ]}
                          >
                            <Input size="large" placeholder="Minimum Quantity" disabled={false} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Maximum Quantity"
                            name={'max_quantity'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              validationHelpers.getNumericValidatorForInput({})
                            ]}
                          >
                            <Input size="large" placeholder="Maximum Quantity" disabled={false} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Discount Value"
                            name={'percent_discount_value'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              validationHelpers.getNumeric2DecimalPointValidatorForInput({}),
                              {
                                // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                validator: async (_, value) => {
                                  const discountType = promotionForm.getFieldValue('discount_type');
                                  if (discountType === 'PERCENTAGE' && value > 100) {
                                    return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                  }
                                  return Promise.resolve();
                                }
                              },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Discount Value"
                              disabled={false}
                              // suffix={<Tooltip title="Percent">%</Tooltip>}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.volume_Threshold_qty_edit !== curValues.volume_Threshold_qty_edit
            }
          >
            {({ getFieldValue }) => {
              const volumeThresholdExists = getFieldValue('volume_Threshold_qty_edit')?.length > 0;
              return (
                volumeThresholdExists && (
                  <div
                    style={{
                      height: 'fit-content',
                      width: '100%',
                      background: '#e1eded',
                      padding: '20px',
                      borderRadius: '20px',
                      marginBottom: '15px'
                    }}
                  >
                    <Typography.Title level={4}>Threshold </Typography.Title>
                    <Form.List name="volume_Threshold_qty_edit">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }) => {
                            return (
                              <div key={key} {...restField}>
                                <Row gutter={28}>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      name={[name, 'from_Quantity']}
                                      label="From Quantity"
                                      rules={[
                                        { message: 'Field Is Required', required: true },
                                        getFormattedNumeric2DecimalPointValidatorForInput({
                                          message:
                                            'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                        })
                                      ]}
                                    >
                                      <InputNumber
                                        min={1}
                                        className="w-full"
                                        size="large"
                                        placeholder="From Quantity(Min Treshold val)"
                                      ></InputNumber>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      name={[name, 'to_Quantity']}
                                      label="To Quantity"
                                      rules={[
                                        { message: 'Field Is Required', required: true },
                                        getFormattedNumeric2DecimalPointValidatorForInput({
                                          message:
                                            'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                        })
                                      ]}
                                    >
                                      <InputNumber
                                        min={1}
                                        className="w-full"
                                        size="large"
                                        placeholder="To Quantity(Max Treshold val)"
                                      ></InputNumber>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      name={[name, 'discount_value']}
                                      label="Value"
                                      rules={[
                                        { message: 'Field Is Required', required: true },
                                        getFormattedNumeric2DecimalPointValidatorForInput({
                                          message:
                                            'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                        })
                                      ]}
                                    >
                                      <InputNumber
                                        min={1}
                                        className="w-full"
                                        size="large"
                                        placeholder="Value"
                                      ></InputNumber>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={2}>
                                    <div className="mx-4 h-full flex justify-center items-center">
                                      <DeleteOutlined
                                        className="text-red-500 text-xl cursor-pointer"
                                        role="button"
                                        title="Remove Post Code"
                                        onClick={() => remove(name)}
                                      ></DeleteOutlined>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                          <Form.Item>
                            <Button type="primary" onClick={() => add()}>
                              Add Threshold
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>
                )
              );
            }}
          </Form.Item>

          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={stItemEditModal}
        onCancel={() => {
          setStItemEditModal(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Item 4</Typography.Title>
          </div>
        }
      >
        <Form
          form={percentPriceSubTotalForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnUpdatePercentPriceSTModalForm();
          }}
        >
          <Row gutter={24}>
            <Col xs={12} md={6}>
              <Form.Item label="SKU" name={'sku'} rules={[{ required: true, message: "This field can't be empty" }]}>
                <Input size="large" placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Unit Price Ref"
                name={'unit_current_price'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Unit Price" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Minimum Quantity"
                name={'min_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <Input size="large" placeholder="Minimum Quantity" disabled={false} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Quantity"
                name={'max_quantity_item'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Discount Value"
                name={'percent_discount_value_item'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  {
                    // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                    validator: async (_, value) => {
                      const discountType = promotionForm.getFieldValue('discount_type');
                      if (discountType === 'PERCENTAGE' && value > 100) {
                        return Promise.reject(new Error('Percentage cannot exceed 100.'));
                      }
                      return Promise.resolve();
                    }
                  },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Discount Value" className="w-full" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Discount Value"
                name={'max_discount_Value_item'}
                rules={[
                  { required: false, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Maximum Discount Value" className="w-full" />
              </Form.Item>
            </Col>
          </Row>
          {percentPriceSubTotalForm.getFieldsValue().subtotal_treshold_edit && (
            <div
              style={{
                height: 'fit-content',
                width: '100%',
                background: '#e1eded',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Typography.Title level={4}>Threshold </Typography.Title>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return (
                    prevValues.threshold_selection !== curValues.threshold_selection ||
                    prevValues.subtotal_treshold_edit !== curValues.subtotal_treshold_edit
                  );
                }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const thresholdSelection = getFieldValue('threshold_selection');
                  const subtotalThreshold = getFieldValue('subtotal_treshold_edit') || [];

                  if (thresholdSelection && thresholdSelection !== 'NO_THRESHOLD' && subtotalThreshold.length === 0) {
                    setFieldsValue({
                      subtotal_treshold_edit: [{ minimum_value: null, max_limit_set: false }]
                    });
                  }

                  return (
                    <Form.List name="subtotal_treshold_edit">
                      {(fields, { add, remove }) => {
                        const maxLimitSetChecked = fields.some(field =>
                          getFieldValue(['subtotal_treshold_edit', field.name, 'max_limit_set'])
                        );
                        return (
                          <>
                            {/* <Typography.Title level={4}>SubTotal Threshold:</Typography.Title> */}
                            {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                              const maxLimitSet = getFieldValue(['subtotal_treshold_edit', name, 'max_limit_set']);
                              const isLastItem = index === fields.length - 1;
                              const isOnlyItem = fields.length === 1;

                              return (
                                <div key={key} {...restField}>
                                  <Row gutter={12}>
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Minimum Value"
                                        name={[name, 'minimum_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          })
                                        ]}
                                      >
                                        <InputNumber
                                          min={1}
                                          size="large"
                                          placeholder="Minimum Value"
                                          className="w-full"
                                        />
                                      </Form.Item>
                                    </Col>
                                    {!maxLimitSet && (
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Maximum Value"
                                          name={[name, 'maximum_value']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            getFormattedNumeric2DecimalPointValidatorForInput({
                                              message:
                                                'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                            })
                                          ]}
                                        >
                                          <InputNumber
                                            min={1}
                                            size="large"
                                            placeholder="Maximum Value"
                                            className="w-full"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Discount Value"
                                        name={[name, 'discount_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          }),
                                          {
                                            // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                            validator: async (_, value) => {
                                              const discountType = promotionForm.getFieldValue('discount_type');
                                              if (discountType === 'PERCENTAGE' && value > 100) {
                                                return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                              }
                                              return Promise.resolve();
                                            }
                                          }
                                        ]}
                                      >
                                        <Input
                                          size="large"
                                          placeholder="Discount Value"
                                          onBlur={formatToTwoDecimalPlaces}
                                          // suffix={<Tooltip title="Percent">%</Tooltip>}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {isLastItem && (
                                      <Col xs={12} md={3} className="mt-8">
                                        <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                          <Checkbox>Max Limit Set</Checkbox>
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {!isOnlyItem && (
                                      <Col xs={12} md={2}>
                                        <div className="mx-4 h-full flex justify-center items-center">
                                          <DeleteOutlined
                                            className="text-red-500 text-xl cursor-pointer"
                                            role="button"
                                            title="Remove"
                                            onClick={() => remove(name)}
                                          />
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              );
                            })}
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => add({ minimum_value: 1, max_limit_set: false, discount_value: 0 })}
                                className="mt-2"
                                disabled={maxLimitSetChecked}
                              >
                                Add
                              </Button>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.List>
                  );
                }}
              </Form.Item>
            </div>
          )}
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={stPercentItemEditModal}
        onCancel={() => {
          setStPercentItemEditModal(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Item 5</Typography.Title>
          </div>
        }
      >
        <Form
          form={fixedSubTotalForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnUpdateFixedSTModalForm();
          }}
        >
          <Row gutter={24}>
            <Col xs={12} md={6}>
              <Form.Item label="SKU" name={'sku'} rules={[{ required: true, message: "This field can't be empty" }]}>
                <Input size="large" placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Unit Price Ref"
                name={'unit_current_price'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Unit Price" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Minimum Quantity"
                name={'min_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <Input size="large" placeholder="Minimum Quantity" disabled={false} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Quantity"
                name={'max_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <Input size="large" placeholder="Maximum Quantity" disabled={false} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Discount Value"
                name={'discount_value'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({}),
                  {
                    // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                    validator: async (_, value) => {
                      const discountType = promotionForm.getFieldValue('discount_type');
                      if (discountType === 'PERCENTAGE' && value > 100) {
                        return Promise.reject(new Error('Percentage cannot exceed 100.'));
                      }
                      return Promise.resolve();
                    }
                  },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <Input size="large" placeholder="Discount Value" disabled={false} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Discount Value"
                name={'max_discount'}
                rules={[
                  { required: false, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <Input size="large" placeholder="Maximum Discount Value" disabled={false} />
              </Form.Item>
            </Col>
          </Row>
          {fixedSubTotalForm.getFieldsValue().volume_Threshold_qty && (
            <div
              style={{
                height: 'fit-content',
                width: '100%',
                background: '#e1eded',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Typography.Title level={4}>Threshold </Typography.Title>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return (
                    prevValues.threshold_selection !== curValues.threshold_selection ||
                    prevValues.subtotal_treshold_Perc_edit !== curValues.subtotal_treshold_Perc_edit
                  );
                }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const thresholdSelection = getFieldValue('threshold_selection');
                  const subtotalThreshold = getFieldValue('subtotal_treshold_Perc_edit') || [];

                  if (thresholdSelection && thresholdSelection !== 'NO_THRESHOLD' && subtotalThreshold.length === 0) {
                    setFieldsValue({
                      subtotal_treshold_Perc_edit: [{ minimum_value: 1, max_limit_set: false }]
                    });
                  }

                  return (
                    <Form.List name="subtotal_treshold_Perc_edit">
                      {(fields, { add, remove }) => {
                        const maxLimitSetChecked = fields.some(field =>
                          getFieldValue(['subtotal_treshold_Perc_edit', field.name, 'max_limit_set'])
                        );
                        return (
                          <>
                            {/* <Typography.Title level={4}>SubTotal Threshold:</Typography.Title> */}
                            {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                              const maxLimitSet = getFieldValue(['subtotal_treshold_Perc_edit', name, 'max_limit_set']);
                              const isLastItem = index === fields.length - 1;
                              const isOnlyItem = fields.length === 1;

                              return (
                                <div key={key} {...restField}>
                                  <Row gutter={12}>
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Minimum Value"
                                        name={[name, 'minimum_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          })
                                        ]}
                                      >
                                        <InputNumber
                                          min={1}
                                          size="large"
                                          placeholder="Minimum Value"
                                          className="w-full"
                                        />
                                      </Form.Item>
                                    </Col>
                                    {!maxLimitSet && (
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Maximum Value"
                                          name={[name, 'maximum_value']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            getFormattedNumeric2DecimalPointValidatorForInput({
                                              message:
                                                'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                            })
                                          ]}
                                        >
                                          <InputNumber
                                            min={1}
                                            size="large"
                                            placeholder="Maximum Value"
                                            className="w-full"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Discount Value"
                                        name={[name, 'discount_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          }),
                                          {
                                            // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                            validator: async (_, value) => {
                                              const discountType = promotionForm.getFieldValue('discount_type');
                                              if (discountType === 'PERCENTAGE' && value > 100) {
                                                return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                              }
                                              return Promise.resolve();
                                            }
                                          }
                                        ]}
                                      >
                                        <Input
                                          size="large"
                                          placeholder="Discount Value"
                                          onBlur={formatToTwoDecimalPlaces}
                                          // suffix={<Tooltip title="Percent">%</Tooltip>}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {isLastItem && (
                                      <Col xs={12} md={3} className="mt-8">
                                        <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                          <Checkbox>Max Limit Set</Checkbox>
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {!isOnlyItem && (
                                      <Col xs={12} md={2}>
                                        <div className="mx-4 h-full flex justify-center items-center">
                                          <DeleteOutlined
                                            className="text-red-500 text-xl cursor-pointer"
                                            role="button"
                                            title="Remove"
                                            onClick={() => remove(name)}
                                          />
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              );
                            })}
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => add({ minimum_value: 1, max_limit_set: false, discount_value: 0 })}
                                className="mt-2"
                                disabled={maxLimitSetChecked}
                              >
                                Add
                              </Button>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.List>
                  );
                }}
              </Form.Item>
            </div>
          )}
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={stSpecialItemEditModal}
        onCancel={() => {
          setStSpecialItemEditModal(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Item 6</Typography.Title>
          </div>
        }
      >
        <Form
          form={specialSubTotalForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnUpdateSpecialSTModalForm();
          }}
        >
          <Row gutter={24}>
            <Col xs={12} md={6}>
              <Form.Item label="SKU" name={'sku'} rules={[{ required: true, message: "This field can't be empty" }]}>
                <Input size="large" placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Unit Price Ref"
                name={'unit_current_price'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Unit Price" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Minimum Quantity"
                name={'min_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <Input size="large" placeholder="Minimum Quantity" disabled={false} />
              </Form.Item>
            </Col>
          </Row>
          {percentPriceSubTotalForm.getFieldsValue().subtotal_treshold_Perc_edit && (
            <div
              style={{
                height: 'fit-content',
                width: '100%',
                background: '#e1eded',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Typography.Title level={4}>Threshold </Typography.Title>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return (
                    prevValues.threshold_selection !== curValues.threshold_selection ||
                    prevValues.subtotal_treshold_Perc_edit !== curValues.subtotal_treshold_Perc_edit
                  );
                }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const thresholdSelection = getFieldValue('threshold_selection');
                  const subtotalThreshold = getFieldValue('subtotal_treshold_Perc_edit') || [];

                  if (thresholdSelection && thresholdSelection !== 'NO_THRESHOLD' && subtotalThreshold.length === 0) {
                    setFieldsValue({
                      subtotal_treshold_Perc_edit: [{ minimum_value: 1, max_limit_set: false }]
                    });
                  }

                  return (
                    <Form.List name="subtotal_treshold_Perc_edit">
                      {(fields, { add, remove }) => {
                        const maxLimitSetChecked = fields.some(field =>
                          getFieldValue(['subtotal_treshold_Perc_edit', field.name, 'max_limit_set'])
                        );
                        return (
                          <>
                            {/* <Typography.Title level={4}>SubTotal Threshold:</Typography.Title> */}
                            {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                              const maxLimitSet = getFieldValue(['subtotal_treshold_Perc_edit', name, 'max_limit_set']);
                              const isLastItem = index === fields.length - 1;
                              const isOnlyItem = fields.length === 1;

                              return (
                                <div key={key} {...restField}>
                                  <Row gutter={12}>
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Minimum Value"
                                        name={[name, 'minimum_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          })
                                        ]}
                                      >
                                        <InputNumber
                                          min={1}
                                          size="large"
                                          placeholder="Minimum Value"
                                          className="w-full"
                                        />
                                      </Form.Item>
                                    </Col>
                                    {!maxLimitSet && (
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Maximum Value"
                                          name={[name, 'maximum_value']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            getFormattedNumeric2DecimalPointValidatorForInput({
                                              message:
                                                'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                            })
                                          ]}
                                        >
                                          <InputNumber
                                            min={1}
                                            size="large"
                                            placeholder="Maximum Value"
                                            className="w-full"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Discount Value"
                                        name={[name, 'discount_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          }),
                                          {
                                            // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                            validator: async (_, value) => {
                                              const discountType = promotionForm.getFieldValue('discount_type');
                                              if (discountType === 'PERCENTAGE' && value > 100) {
                                                return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                              }
                                              return Promise.resolve();
                                            }
                                          }
                                        ]}
                                      >
                                        <Input
                                          size="large"
                                          placeholder="Discount Value"
                                          onBlur={formatToTwoDecimalPlaces}
                                          // suffix={<Tooltip title="Percent">%</Tooltip>}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {isLastItem && (
                                      <Col xs={12} md={3} className="mt-8">
                                        <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                          <Checkbox>Max Limit Set</Checkbox>
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {!isOnlyItem && (
                                      <Col xs={12} md={2}>
                                        <div className="mx-4 h-full flex justify-center items-center">
                                          <DeleteOutlined
                                            className="text-red-500 text-xl cursor-pointer"
                                            role="button"
                                            title="Remove"
                                            onClick={() => remove(name)}
                                          />
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              );
                            })}
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => add({ minimum_value: 1, max_limit_set: false, discount_value: 0 })}
                                className="mt-2"
                                disabled={maxLimitSetChecked}
                              >
                                Add
                              </Button>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.List>
                  );
                }}
              </Form.Item>
            </div>
          )}
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={categoryEditModal}
        onCancel={() => {
          setCategoryEditModal(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Category</Typography.Title>
          </div>
        }
      >
        <Form
          form={categoryEditForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnCategoryEditModal();
          }}
        >
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Category Index"
                name={'category_index'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Index" disabled={true} className="w-full" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Select Category"
                name={'category'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Select
                  placeholder="Select"
                  allowClear
                  size="large"
                  disabled={true}
                  options={categoryProductOptions || []}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Minimum Quantity"
                name={'minimum_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Minimum Quantity" className="w-full" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Quantity"
                name={'maximum_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Discount Value"
                name={'percent_discount_value_item'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  {
                    // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                    validator: async (_, value) => {
                      const discountType = promotionForm.getFieldValue('discount_type');
                      if (discountType === 'PERCENTAGE' && value > 100) {
                        return Promise.reject(new Error('Percentage cannot exceed 100.'));
                      }
                      return Promise.resolve();
                    }
                  },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Discount Value" className="w-full" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Discount Value"
                name={'max_discount_Value_item'}
                rules={[
                  { required: false, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Maximum Discount Value" className="w-full" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={categoryEditModalVolume}
        onCancel={() => {
          setCategoryEditModalVolume(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Category</Typography.Title>
          </div>
        }
      >
        <Form
          form={categoryEditVolumeForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnCategoryEditModalVolume();
          }}
        >
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Category Index"
                name={'category_index'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Index" disabled={true} className="w-full" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Select Category"
                name={'category'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Select
                  placeholder="Select"
                  allowClear
                  size="large"
                  disabled={true}
                  options={categoryProductOptions || []}
                ></Select>
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.volume_treshold_Perc_edit !== curValues.volume_treshold_Perc_edit
              }
            >
              {({ getFieldValue }) => {
                const volumeThresholdExists = getFieldValue('volume_treshold_Perc_edit')?.length > 0;
                return (
                  <>
                    {!volumeThresholdExists && (
                      <>
                        {' '}
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Minimum Quantity"
                            name={'minimum_quantity'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Minimum Quantity" className="w-full" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Maximum Quantity"
                            name={'maximum_quantity'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Discount Value"
                            name={'percent_discount_value_item'}
                            rules={[
                              { required: true, message: "This field can't be empty" },
                              {
                                // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                validator: async (_, value) => {
                                  const discountType = promotionForm.getFieldValue('discount_type');
                                  if (discountType === 'PERCENTAGE' && value > 100) {
                                    return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                  }
                                  return Promise.resolve();
                                }
                              },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Discount Value" className="w-full" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label="Maximum Discount Value"
                            name={'max_discount_Value_item'}
                            rules={[
                              { required: false, message: "This field can't be empty" },
                              getFormattedNumeric2DecimalPointValidatorForInput({
                                message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                              })
                            ]}
                          >
                            <InputNumber min={1} size="large" placeholder="Maximum Discount Value" className="w-full" />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {volumeThresholdExists && (
                      <div
                        style={{
                          height: 'fit-content',
                          width: '100%',
                          background: '#e1eded',
                          padding: '20px',
                          borderRadius: '20px',
                          marginBottom: '15px'
                        }}
                      >
                        <Typography.Title level={4}>Threshold </Typography.Title>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) => {
                            return (
                              prevValues.threshold_selection !== curValues.threshold_selection ||
                              prevValues.volume_treshold_Perc_edit !== curValues.volume_treshold_Perc_edit
                            );
                          }}
                        >
                          {({ getFieldValue, setFieldsValue }) => {
                            const thresholdSelection = getFieldValue('threshold_selection');
                            const subtotalThreshold = getFieldValue('volume_treshold_Perc_edit') || [];

                            if (
                              thresholdSelection &&
                              thresholdSelection !== 'NO_THRESHOLD' &&
                              subtotalThreshold.length === 0
                            ) {
                              setFieldsValue({
                                volume_treshold_Perc_edit: [{ minimum_value: 1, max_limit_set: false }]
                              });
                            }

                            return (
                              <Form.List name="volume_treshold_Perc_edit">
                                {(fields, { add, remove }) => {
                                  const maxLimitSetChecked = fields.some(field =>
                                    getFieldValue(['volume_treshold_Perc_edit', field.name, 'max_limit_set'])
                                  );
                                  return (
                                    <>
                                      {/* <Typography.Title level={4}>SubTotal Threshold:</Typography.Title> */}
                                      {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                                        const maxLimitSet = getFieldValue([
                                          'volume_treshold_Perc_edit',
                                          name,
                                          'max_limit_set'
                                        ]);
                                        const isLastItem = index === fields.length - 1;
                                        const isOnlyItem = fields.length === 1;

                                        return (
                                          <div key={key} {...restField}>
                                            <Row gutter={12}>
                                              <Col xs={12} md={6}>
                                                <Form.Item
                                                  label="From Qty"
                                                  name={[name, 'from_Quantity']}
                                                  rules={[
                                                    { required: true, message: "This field can't be empty" },
                                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                                      message:
                                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                    })
                                                  ]}
                                                >
                                                  <InputNumber
                                                    min={1}
                                                    size="large"
                                                    placeholder="From Qty"
                                                    className="w-full"
                                                  />
                                                </Form.Item>
                                              </Col>
                                              {!maxLimitSet && (
                                                <Col xs={12} md={6}>
                                                  <Form.Item
                                                    label="To Qty"
                                                    name={[name, 'to_Quantity']}
                                                    rules={[
                                                      { required: true, message: "This field can't be empty" },
                                                      getFormattedNumeric2DecimalPointValidatorForInput({
                                                        message:
                                                          'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                      })
                                                    ]}
                                                  >
                                                    <InputNumber
                                                      min={1}
                                                      size="large"
                                                      placeholder="Maximum Value"
                                                      className="w-full"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              )}
                                              <Col xs={12} md={6}>
                                                <Form.Item
                                                  label="Discount Value"
                                                  name={[name, 'volume_value']}
                                                  rules={[
                                                    { required: true, message: "This field can't be empty" },
                                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                                      message:
                                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                                    }),
                                                    {
                                                      // Custom validator to check if discount type is 'PERCENTAGE' and value <= 100
                                                      validator: async (_, value) => {
                                                        const discountType =
                                                          promotionForm.getFieldValue('discount_type');
                                                        if (discountType === 'PERCENTAGE' && value > 100) {
                                                          return Promise.reject(
                                                            new Error('Percentage cannot exceed 100.')
                                                          );
                                                        }
                                                        return Promise.resolve();
                                                      }
                                                    }
                                                  ]}
                                                >
                                                  <Input
                                                    size="large"
                                                    placeholder="Discount Value"
                                                    onBlur={formatToTwoDecimalPlaces}
                                                    // suffix={<Tooltip title="Percent">%</Tooltip>}
                                                  />
                                                </Form.Item>
                                              </Col>
                                              {isLastItem && (
                                                <Col xs={12} md={3} className="mt-8">
                                                  <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                                    <Checkbox>Max Limit Set</Checkbox>
                                                  </Form.Item>
                                                </Col>
                                              )}
                                              {!isOnlyItem && (
                                                <Col xs={12} md={2}>
                                                  <div className="mx-4 h-full flex justify-center items-center">
                                                    <DeleteOutlined
                                                      className="text-red-500 text-xl cursor-pointer"
                                                      role="button"
                                                      title="Remove"
                                                      onClick={() => remove(name)}
                                                    />
                                                  </div>
                                                </Col>
                                              )}
                                            </Row>
                                          </div>
                                        );
                                      })}
                                      <Form.Item>
                                        <Button
                                          type="primary"
                                          onClick={() =>
                                            add({ minimum_value: 1, max_limit_set: false, discount_value: 0 })
                                          }
                                          className="mt-2"
                                          disabled={maxLimitSetChecked}
                                        >
                                          Add
                                        </Button>
                                      </Form.Item>
                                    </>
                                  );
                                }}
                              </Form.List>
                            );
                          }}
                        </Form.Item>
                      </div>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={categoryEditModalBundle}
        onCancel={() => {
          setCategoryEditModalBundle(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Bundle specialPriceEditBundleForm</Typography.Title>
          </div>
        }
      >
        <Form
          form={specialPriceEditBundleForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnSpecialPriceEditModalBundle();
          }}
        >
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item label="SKU" name={'sku'} rules={[{ required: true, message: "This field can't be empty" }]}>
                <Input size="large" placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Minimum Quantity"
                name={'quantity'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Minimum Quantity" disabled={false} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Special Prices"
                name={'special_price'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <Input size="large" placeholder="Special Price" disabled={false} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={categoryEditPercentModalBundle}
        onCancel={() => {
          setCategoryPercentEditModalBundle(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Bundle percentageEditBundleForm</Typography.Title>
          </div>
        }
      >
        <Form
          form={percentageEditBundleForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnPercentEditModalBundle();
          }}
        >
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item label="SKU" name={'sku'} rules={[{ required: true, message: "This field can't be empty" }]}>
                <Input size="large" placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Minimum Quantity"
                name={'min_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Minimum Quantity" className="w-full" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Quantity"
                name={'max_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={categoryEditFixedModalBundle}
        onCancel={() => {
          setCategoryEditFixedModalBundle(false);
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Bundle setCategoryEditFixedModalBundle</Typography.Title>
          </div>
        }
      >
        <Form
          form={FixedEditBundleForm}
          scrollToFirstError
          layout="vertical"
          onFinish={() => {
            handleOnFixedEditModalBundle();
          }}
        >
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item label="SKU" name={'sku'} rules={[{ required: true, message: "This field can't be empty" }]}>
                <Input size="large" placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Minimum Quantity"
                name={'min_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Minimum Quantity" className="w-full" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Quantity"
                name={'max_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber min={1} size="large" placeholder="Maximum Quantity" className="w-full" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Update"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form>
  );
};

export default PromotionForm;
