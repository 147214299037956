import { CloudUploadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, InputNumber, Modal, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ActionButton from '../../../components/ActionButton';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { fileHelpers, objectHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import { priceListService } from '../services/pricelist.service';
import { IPriceEntries, IPriceEntriesResp } from '../types/price-list-types.s';
// import CustomPagination from '../../../components/custom-pagination';
import { useBasicDetails } from '../../../stores/use-promotion';
import dayjs from 'dayjs';

const uomOptions = [
  { label: 'EA', value: 'EA' },
  { label: 'KG', value: 'KG' },
  { label: 'LB', value: 'LB' }
];

const discountType = [
  { label: 'Special Price', value: 'SPECIAL-PRICE' },
  { label: 'Percentage', value: 'PERCENTAGE' },
  { label: 'Absolute Discount', value: 'FIXED' }
];

interface IPriceListPriceEnteries {
  onClickSuggestedLines: () => void;
  onClickCopyLines: () => void;
  handleGoBack: () => void;
  getProductName: () => { product_Name: string; prefered_button: string };
  handleOnClickDownload: () => void;
  isformVisible: boolean;
}

const PriceListPriceEnteries: React.FunctionComponent<IPriceListPriceEnteries> = ({
  onClickSuggestedLines,
  onClickCopyLines,
  handleGoBack,
  getProductName,
  handleOnClickDownload,
  isformVisible
}) => {
  //new declare
  const { parentId } = useParams();
  const navigate = useNavigate();
  const [priceEnteriesForm] = Form.useForm();
  const [PriceEnteriesEditForm] = Form.useForm();

  //new State
  const [priceEnteriesResponse, setPriceEnteriesResponse] = React.useState({} as any);
  const [editSalesPriceInfo, setEditSalesPriceInfo] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState<any>(null);
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [excelResult, setExcelResult] = React.useState(false);
  const [totalSuccess, setTotalSuccess] = React.useState(0);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [confirmUploadData, setConfirmUploadData] = React.useState<any>(null);
  const [totalDuplicate, setTotalDuplicate] = React.useState(0);
  const [totalFailed, setTotalFailed] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [statusOfExcel, setStatusOfExcel] = React.useState<any>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageParam, setPageParam] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '5');
    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 5
    };
  });

  //other declerations
  const { product_Name, prefered_button } = getProductName();

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const { productData } = useBasicDetails(({ productData }) => ({ productData }));
  // console.log('productData', productData);

  const columns: TableColumnsType<IPriceEntries> = [
    {
      title: 'Product ID',
      align: 'center',
      render(value, record, index) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value, record, index) {
        return <>{record?.name}</>;
      }
    },
    // {
    //   title: 'Currency',
    //   align: 'center',
    //   render(value, record, index) {
    //     return <>{record?.currency_code}</>;
    //   }
    // },
    // {
    //   title: 'UOM',
    //   align: 'center',
    //   render(value, record, index) {
    //     let UOM = record.price_entries?.sale_price_info[0]?.unit_of_measure;
    //     return <>{UOM}</>;
    //   }
    // },
    {
      title: 'Schedule Start Date',
      align: 'center',
      render(value, record, index) {
        let startDate = record?.schedule_info?.schedule_start_date || '';
        // let startTime = record?.schedule_info?.schedule_start_time || '';
        const formattedStartDateTime = dayjs(
          `${record?.schedule_info?.schedule_start_date}T${record?.schedule_info?.schedule_start_time}`
        ).format('DD-MMM-YYYY hh:mm:ss a');
        return { startDate } ? <div style={{ width: '100px' }}>{formattedStartDateTime}</div> : null;
      }
    },
    {
      title: 'Schedule End Date',
      align: 'center',
      render(value, record, index) {
        let endDate = record?.schedule_info?.schedule_end_date;
        // let endTime = record?.schedule_info?.schedule_end_time;
        const formattedStartDateTime = dayjs(
          `${record?.schedule_info?.schedule_end_date}T${record?.schedule_info?.schedule_end_time}`
        ).format('DD-MMM-YYYY hh:mm:ss a');
        return { endDate } ? <div style={{ width: '100px' }}>{formattedStartDateTime}</div> : null;
      }
    },
    {
      title: 'Cost Price',
      align: 'center',
      render(value, record, index) {
        let data = record?.price_entries?.cost_price;
        return <>{data}</>;
      }
    },
    // {
    //   title: 'Threshold Based',
    //   align: 'center',
    //   render(value, record, index) {
    //     let data = String(record?.price_entries?.is_threshold_based);
    //     return <>{data}</>;
    //   }
    // },
    {
      title: 'Sales Lock Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = String(record?.price_entries?.lock_sale_price);
        return <>{data}</>;
      }
    },
    {
      title: 'Override Master Lock Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = String(record?.price_entries?.override_master_lock_price);
        return <>{data}</>;
      }
    },
    {
      title: 'Minimum Discount percentage',
      align: 'center',
      render(value, record, index) {
        let data = record?.price_entries?.minimum_markup_percent;
        return <>{data}</>;
      }
    },
    {
      title: 'Maximum Discount percentage',
      align: 'center',
      render(value, record, index) {
        let data = record?.price_entries?.maximum_markup_percent;
        return <>{data}</>;
      }
    },
    {
      title: 'Sales Price Info',
      align: 'center',
      children: [
        {
          title: 'List Price',
          dataIndex: 'minimum_quantity',
          key: 'original_list_price',
          align: 'center',
          render(value, record, index) {
            return (
              <div className="flex justify-center gap-[10px]">
                <ul>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    return <li key={record.item_id}>{cv.original_list_price}</li>;
                  })}
                </ul>
                {/* {record.price_entries.sale_price_info.length > 0 && isformVisible && (
                  <EditOutlined
                    style={{ color: '#5885af', fontSize: '16px' }}
                    onClick={() => handleOnEditModal(record)}
                  />
                )} */}
              </div>
            );
          }
        },
        {
          title: 'Discount Value',
          dataIndex: 'discount_value',
          key: 'discount_value',
          align: 'center',
          render(value, record, index) {
            return (
              <div className="flex justify-center gap-[10px]">
                <ul>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    return <li key={record.item_id}>{cv.discount_value || '-'}</li>;
                  })}
                </ul>
                {/* {record.price_entries.sale_price_info.length > 0 && isformVisible && (
                  <EditOutlined
                    style={{ color: '#5885af', fontSize: '16px' }}
                    onClick={() => handleOnEditModal(record)}
                  />
                )} */}
              </div>
            );
          }
        },
        {
          title: 'Discount Price',
          dataIndex: 'list_price',
          key: 'list_price',
          align: 'center',
          render(value, record, index) {
            return (
              <div className="flex justify-center gap-[10px]">
                <ul>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    return <li key={record.item_id}>{cv.list_price}</li>;
                  })}
                </ul>
              </div>
            );
          }
        },
        {
          title: 'Minimum Quantity',
          dataIndex: 'minimum_quantity',
          key: 'minimum_quantity',
          align: 'center',
          render(value, record, index) {
            return (
              <div className="flex justify-center gap-[10px]">
                <ul>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    return <li key={record.item_id}>{cv.minimum_quantity}</li>;
                  })}
                </ul>
                {/* {record.price_entries.sale_price_info.length > 0 && isformVisible && (
                  <EditOutlined
                    style={{ color: '#5885af', fontSize: '16px' }}
                    onClick={() => handleOnEditModal(record)}
                  />
                )} */}
              </div>
            );
          }
        },
        {
          title: 'Unit of Measure',
          dataIndex: 'unit_of_measure',
          key: 'unit_of_measure',
          align: 'center',
          render(value, record, index) {
            return (
              <div className="flex justify-center gap-[10px]">
                <ul>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    return <li key={record.item_id}>{cv.unit_of_measure}</li>;
                  })}
                </ul>
                {/* {record.price_entries.sale_price_info.length > 0 && isformVisible && (
                  <EditOutlined
                    style={{ color: '#5885af', fontSize: '16px' }}
                    onClick={() => handleOnEditModal(record)}
                  />
                )} */}
              </div>
            );
          }
        },
        {
          title: 'Discount Type',
          dataIndex: 'discount_type',
          key: 'discount_type',
          align: 'center',
          render(value, record, index) {
            return (
              <div className="flex justify-center gap-[10px]" style={{ width: '150px' }}>
                <ul>
                  {record.price_entries.sale_price_info.map((cv: any) => {
                    let str;
                    if (cv.discount_type === 'SPECIAL-PRICE') {
                      str = 'Special Price';
                    } else if (cv.discount_type === 'FIXED') {
                      str = 'Fixed';
                    } else {
                      str = 'Percentage';
                    }
                    return <li key={record.item_id}>{str}</li>;
                  })}
                </ul>
                {/* {record.price_entries.sale_price_info.length > 0 && isformVisible && (
                  <EditOutlined
                    style={{ color: '#5885af', fontSize: '16px' }}
                    onClick={() => handleOnEditModal(record)}
                  />
                )} */}
              </div>
            );
          }
        }
      ]
    },
    // {
    //   title: 'Additional Promotion Info',
    //   align: 'center',
    //   children: [
    //     {
    //       title: 'Promotion Restricted',
    //       dataIndex: 'is_promotion_restricted',
    //       key: 'is_promotion_restricted',
    //       align: 'center',
    //       render(value, record, index) {
    //         const data = record?.addnl_promotion_info?.is_promotion_restricted ? 'True' : 'False';
    //         return <>{data}</>;
    //       }
    //     },
    //     {
    //       title: 'Start Date of Promotion Restricted',
    //       dataIndex: 'promotion_restriction_start_date',
    //       key: 'promotion_restriction_start_date',
    //       align: 'center',
    //       render(value, record, index) {
    //         const date = record?.addnl_promotion_info?.promotion_restriction_start_date;
    //         const time = record?.addnl_promotion_info?.promotion_restriction_start_time;
    //         const formattedStartDateTime = dayjs(`${date}T${time}`).format('DD-MMM-YYYY hh:mm:ss a');
    //         return <>{formattedStartDateTime}</>;
    //       }
    //     },
    //     {
    //       title: 'End Date of Promotion Restricted',
    //       dataIndex: 'promotion_restriction_end_date',
    //       key: 'promotion_restriction_end_date',
    //       align: 'center',
    //       render(value, record, index) {
    //         const date = record?.addnl_promotion_info?.promotion_restriction_end_date;
    //         const time = record?.addnl_promotion_info?.promotion_restriction_end_time;
    //         const formattedStartDateTime = dayjs(`${date}T${time}`).format('DD-MMM-YYYY hh:mm:ss a');
    //         return <>{formattedStartDateTime}</>;
    //       }
    //     }
    //   ]
    // },
    {
      title: 'Action',
      render(value: any, record: any, index: any) {
        return (
          <section className="flex flex-wrap justify-center">
            <ActionButton
              action="VIEW"
              title="View"
              onClick={() => {
                const params = {
                  offset: '0',
                  backTabTo: 'PRICE-ENTERIES'
                };
                const paramString = new URLSearchParams(params).toString();
                let itemId = record?.item_id;
                navigate(
                  `/promotion-engine/promotions/price-list/${parentId}/price-list-view-form/${itemId}/product-edit?${paramString}`
                );
              }}
            ></ActionButton>
            {isformVisible && (
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  // handleOnEdit(record);
                  const params = {
                    offset: '0',
                    backTabTo: 'PRICE-ENTERIES'
                  };
                  const paramString = new URLSearchParams(params).toString();
                  let itemId = record?.item_id;
                  navigate(
                    `/promotion-engine/promotions/price-list/${parentId}/price-list-edit-form/${itemId}/product-edit?${paramString}`
                  );
                }}
              ></ActionButton>
            )}
            {isformVisible && (
              <ActionButton
                action="DELETE"
                title="Delete"
                onClick={() => {
                  handleOnDelete(record?.item_id, record?.price_list_id);
                }}
              ></ActionButton>
            )}
          </section>
        );
      },
      align: 'center'
    },
    {
      title: 'Status',
      align: 'center',
      render(value, record, index) {
        let Status = record?.status === 'ACTIVE' ? 'Active' : 'InActive';
        return <div style={{ width: '100px' }}>{Status}</div>;
      }
    }
  ];

  const handleOnDelete = async (itemId: any, Id: any) => {
    setLoading(true);
    const { errors } = await priceListService.removeItemFromPriceList(itemId, Id);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Item Removed Success' });
      handleOnSearch(0);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  // React.useEffect(() => {
  //   handleOnSearch(pageParam);
  // }, []);

  React.useEffect(() => {
    if (pageControl?.currentPage) handleOnSearch(pageControl?.currentPage - 1);
  }, [pageControl]);

  const handleOnSearch = async (offset = 0) => {
    setLoading(true);
    let params = { offset: offset + '', limit: pageControl.pageSize + '' };
    const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(params), offset: offset + '' };
    const { data, errors } = await priceListService.getAllItemsOnPriceList(filteredFormValues, parentId);
    if (_.isEmpty(errors)) {
      const transformedData = data?.line_items?.map((item: IPriceEntriesResp) => {
        return {
          ...item,
          key: item?.id
        };
      });
      const transformedResponse: any = {
        ...data,
        data: {
          data: transformedData,
          count: data?.count
        }
      };
      setPriceEnteriesResponse(transformedResponse);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnEditModal = async (record: any) => {
    setLoading(true);
    const { data, errors } = await priceListService.getProductItemDetails(parentId as any, record.item_id);
    if (_.isEmpty(errors)) {
      setSelectedRowData(data);
      setEditSalesPriceInfo(true);
      let salesPriceInfo = data.price_entries.sale_price_info;
      PriceEnteriesEditForm.setFieldsValue({
        updatedSalespriceInfoData: salesPriceInfo
      });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnUpdateInfo = async () => {
    setLoading(true);
    let productId = selectedRowData.price_list_id;
    let itemId = selectedRowData.item_id;
    let selectedData = selectedRowData;
    let updatedData = PriceEnteriesEditForm.getFieldsValue().updatedSalespriceInfoData;
    selectedData.price_entries.sale_price_info = updatedData;
    const { errors } = await priceListService.updateItemOnPriceList(productId, itemId, selectedData);
    if (_.isEmpty(errors)) {
      handleOnSearch(pageParam);
      displaySuccessNotification({ message: 'Data updated Sucessfully!' });
    } else {
      displayErrorNotifications(errors);
    }
    setEditSalesPriceInfo(false);
    setLoading(false);
  };

  const handleOnTemplateDownload = async () => {
    setLoading(true);
    const { data, errors } = await priceListService.getTemplate();

    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'priceListEntries_upload_template', extension: '.xlsx' });
    }
    setLoading(false);
  };

  const handleProductUpload = async () => {
    if (_.isEmpty(fileList)) return;

    const formData = new FormData();
    formData.set('file', fileList[0] as RcFile);
    if (fileList) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const data = event?.target?.result as ArrayBuffer;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        let excelDataParsed: any[] = [];
        workbook.SheetNames.forEach(sheet => {
          if (excelDataParsed?.length === 0) {
            const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            const jsonObject = JSON.stringify(rowObject);
            const getExceldata = JSON.parse(jsonObject);
            excelDataParsed.push(getExceldata);
            getValidData(excelDataParsed[0]);
          }
        });
      };
      let selectedFile = fileList[0];
      fileReader.readAsBinaryString(selectedFile);
    }
  };

  const ExcelTimeFormat = (time: number) => {
    const hours = Math.floor(time * 24);
    const minutes = Math.floor((time * 24 * 60) % 60);
    const seconds = Math.floor((time * 24 * 3600) % 60);
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
    return formattedTime;
  };

  const loadExcelBodyData = (excelData: any) => {
    let allData = excelData.map((cv: any) => {
      let special_Price_Info =
        cv.discount_type ||
        cv.original_list_price ||
        cv.list_price ||
        cv.discount_value ||
        cv.minimum_quantity ||
        cv.unit_of_measure;
      let schedule_start_time = cv.schedule_start_time ? ExcelTimeFormat(cv.schedule_start_time) : null;
      let schedule_end_time = cv.schedule_end_time ? ExcelTimeFormat(cv.schedule_end_time) : null;
      let promotion_restriction_start_time = cv.promotion_restriction_start_time
        ? ExcelTimeFormat(cv.promotion_restriction_start_time)
        : null;
      let promotion_restriction_end_time = cv.promotion_restriction_end_time
        ? ExcelTimeFormat(cv.promotion_restriction_end_time)
        : null;
      let sendData = {
        name: cv.article_name ? cv.article_name : '',
        item_id: cv.item_id ? cv.item_id : null,
        article_id: cv.article_id ? cv.article_id : null,
        is_product_variant: String(cv.is_item_variant)?.length > 0 ? cv.is_item_variant : null,
        // currency_code: cv.currency_code ? cv.currency_code : null,
        schedule_info: {
          schedule_start_date: cv.schedule_start_date ? cv.schedule_start_date.trim() : null,
          schedule_end_date: cv.schedule_end_date ? cv.schedule_end_date.trim() : null,
          schedule_start_time: schedule_start_time,
          schedule_end_time: schedule_end_time
        },
        price_entries: {
          cost_price: cv.cost_price ? String(cv.cost_price) : null,
          is_threshold_based: String(cv.is_threshold_based).length > 0 ? String(cv.is_threshold_based) : null,
          override_master_lock_price: 'true',
          lock_sale_price: 'true',
          // sales_lock_Price: String(cv?.sales_lock_Price).length > 0 ? cv?.sales_lock_Price : null,
          minimum_markup_percent: String(cv.minimum_markup_percent).length > 0 ? cv.minimum_markup_percent : null,
          maximum_markup_percent: String(cv.maximum_markup_percent).length > 0 ? cv.maximum_markup_percent : null,
          sale_price_info: special_Price_Info
            ? [
                {
                  discount_type: cv.discount_type,
                  discount_value: String(cv.discount_value),
                  // list_price: String(cv.list_price),
                  minimum_quantity: cv?.minimum_quantity || null,
                  original_list_price: String(cv.original_list_price),
                  unit_of_measure: cv.unit_of_measure
                }
              ]
            : []
        },
        addnl_promotion_info: {
          is_promotion_restricted: String(cv.is_promotion_restricted).length > 0 ? cv.is_promotion_restricted : null,
          promotion_restriction_start_date: cv.promotion_restriction_start_date
            ? cv.promotion_restriction_start_date.trim()
            : null,
          promotion_restriction_end_date: cv.promotion_restriction_end_date
            ? cv.promotion_restriction_end_date.trim()
            : null,
          promotion_restriction_end_time: promotion_restriction_end_time,
          promotion_restriction_start_time: promotion_restriction_start_time
        },
        status: cv.status ? cv.status : null
      };
      return sendData;
    });
    return { block_reference: true, line_items: allData };
  };

  const getValidData = async (excelData: any) => {
    setLoading(true);
    if (excelData.length > 10000) {
      displayErrorNotifications([{ message: 'Data cannot exced more than ten thousand' }]);
      setLoading(false);
      return;
    } else {
      let bodyData = loadExcelBodyData(excelData);
      const { data, errors } = await priceListService.getExcelValidation(parentId, bodyData);
      if (_.isEmpty(errors)) {
        setExcelResult(true);
        setConfirmUploadData(bodyData);
        setTotalDuplicate(data.total_duplicate);
        setTotalFailed(data.total_failed);
        setTotalItems(data.total_line_items);
        setTotalSuccess(data.total_success);
        setStatusOfExcel(data);
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };

  const handleOnConfirmUpload = async () => {
    const bodyData = { line_items: confirmUploadData };
    const { errors } = await priceListService.createListOfItems_bulk(parentId as any, bodyData.line_items);
    if (_.isEmpty(errors)) {
      setExcelResult(false);
      setTotalSuccess(0);
      setFileList([]);
      setUploadModalVisible(false);
      handleOnSearch(pageParam);
      displaySuccessNotification({ message: 'Items Added Successfully!!' });
    } else {
      setExcelResult(false);
      setTotalSuccess(0);
      setFileList([]);
      setUploadModalVisible(false);
      displayErrorNotifications(errors);
    }
  };

  const getReportData = async () => {
    setLoading(true);
    const { data, errors } = await priceListService.getReport(parentId, statusOfExcel);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'report_excel', extension: '.xlsx' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  // const handlePageChangeNext = (current_page: number) => {
  //   let nextPage = pageParam + 10;
  //   setCurrentPage(current_page);
  //   handleOnSearch(nextPage);
  //   setPageParam(nextPage);
  // };

  // const handlePageChangePrevious = (current_page: number) => {
  //   let prevPage = pageParam - 10;
  //   setCurrentPage(current_page);
  //   handleOnSearch(prevPage);
  //   setPageParam(prevPage);
  // };

  // const handlePageChange = (current_page: number) => {
  //   setCurrentPage(current_page);
  // };

  // console.log('productData?.catalog_type', productData?.catalog_type);

  return (
    <Form form={priceEnteriesForm} layout="vertical" onFinish={() => handleOnSearch(pageParam)}>
      <Card>
        <div className="flex flex-row sm:flex-col lg:flex-row justify-between align-middle">
          <div>
            <Typography.Text className="font-medium">
              {product_Name && `Product Name: ${product_Name}`}{' '}
            </Typography.Text>
          </div>
          <Row className="flex flex-row-reverse" gutter={12}>
            {productData?.catalog_type === 'MASTER-CATALOG' && (
              <Col>
                <Button size="large" type="primary" onClick={onClickSuggestedLines}>
                  <BoldButtonLabel labelText="Suggested Line" />
                </Button>
              </Col>
            )}
            {productData?.catalog_type === 'PARENT-LIST' && (
              <Col>
                <Button size="large" type="primary" onClick={onClickCopyLines}>
                  <BoldButtonLabel labelText="Copy Line" />
                </Button>
              </Col>
            )}
            {productData?.catalog_type === 'IMPORT-CATALOG' && (
              <Col>
                <Button size="large" type="primary" onClick={() => setUploadModalVisible(true)}>
                  <BoldButtonLabel labelText="Import" />
                </Button>
              </Col>
            )}
            <Col>
              <Button
                size="large"
                type="primary"
                onClick={handleOnClickDownload}
                disabled={priceEnteriesResponse?.count === 0}
              >
                <BoldButtonLabel labelText="Download" />
              </Button>
            </Col>
            <Col>
              <Button size="large" block onClick={handleGoBack}>
                <BoldButtonLabel labelText="Go Back" />
              </Button>
            </Col>
          </Row>
        </div>
      </Card>
      <div className="mt-2">
        {/* {priceEnteriesResponse?.count > 0 && (
          <Row className="my-2" justify={'end'} gutter={[12, 12]} align={'middle'}>
            <Col>
              <CustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(priceEnteriesResponse?.data.count / 10)}
                handleNext={handlePageChangeNext}
                handlePageChange={handlePageChange}
                handlePrevious={handlePageChangePrevious}
              />
            </Col>
          </Row>
        )} */}
        <Table
          loading={false}
          pagination={{
            current: pageControl?.currentPage,
            total: priceEnteriesResponse?.data?.count || 0,
            pageSize: pageControl?.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['1', '2', '5', '10', '20', '50', '100'],

            onChange: (currentPage, pageSize) => setPageControl({ currentPage, pageSize })
          }}
          bordered
          dataSource={priceEnteriesResponse?.data?.data || []}
          columns={columns}
          scroll={{ x: 1000 }}
        ></Table>
      </div>
      <Modal
        centered
        open={editSalesPriceInfo}
        onCancel={() => {
          setEditSalesPriceInfo(false);
        }}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Edit Sales Price Info:</Typography.Title>
          </div>
        }
      >
        <Form form={PriceEnteriesEditForm} layout="vertical" onFinish={handleOnUpdateInfo}>
          <section className="flex flex-col justify-center">
            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
              <Form.Item noStyle shouldUpdate>
                {form => {
                  const { updatedSalespriceInfoData } = form.getFieldsValue();
                  return (
                    <Form.List name="updatedSalespriceInfoData">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map(({ key, name, ...restField }, index) => {
                              let discType = updatedSalespriceInfoData && updatedSalespriceInfoData[key]?.discount_type;
                              return (
                                <div
                                  key={key}
                                  style={{
                                    height: 'fit-content',
                                    width: '100%',
                                    background: '#9b9b9b29',
                                    padding: '20px',
                                    borderRadius: '20px',
                                    marginBottom: '15px'
                                  }}
                                >
                                  <Row gutter={12}>
                                    {
                                      <Col xs={24} md={12}>
                                        <Form.Item
                                          label="Discount Type"
                                          name={[name, 'discount_type']}
                                          rules={[{ required: true, message: "This field can't be empty" }]}
                                        >
                                          <Select
                                            allowClear
                                            options={discountType}
                                            size="large"
                                            placeholder="Select Discount Type"
                                            // disabled={viewPage}
                                          ></Select>
                                        </Form.Item>
                                      </Col>
                                    }
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        label="Unit Of Measure"
                                        name={[name, 'unit_of_measure']}
                                        rules={[{ required: true, message: "This field can't be empty" }]}
                                      >
                                        <Select
                                          placeholder="Select an option"
                                          size="large"
                                          options={uomOptions}
                                        ></Select>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={12}>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        label="Minimum Quantity"
                                        name={[name, 'minimum_quantity']}
                                        rules={[{ required: true, message: "This field can't be empty" }]}
                                      >
                                        <InputNumber
                                          className="w-[100%]"
                                          size="large"
                                          placeholder="Minimum Quantity"
                                          min={1}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {/*   */}
                                    {discType === 'PERCENTAGE' && (
                                      <Col xs={24} md={12}>
                                        <Form.Item
                                          label="Discount Value"
                                          name={[name, 'discount_value']}
                                          rules={[{ required: true, message: "This field can't be empty" }]}
                                        >
                                          <InputNumber
                                            className="w-[100%]"
                                            min={1}
                                            max={100}
                                            size="large"
                                            placeholder="Discount Value"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {/* } */}
                                  </Row>
                                  {/* */}
                                  {discType !== 'PERCENTAGE' && (
                                    <Row gutter={12}>
                                      <Col xs={24} md={12}>
                                        <Form.Item
                                          label="List Price"
                                          name={[name, 'original_list_price']}
                                          rules={[{ required: true, message: "This field can't be empty" }]}
                                        >
                                          <InputNumber className="w-[100%]" size="large" placeholder="List Price" />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  )}
                                  {/* } */}
                                </div>
                              );
                            })}
                          </>
                        );
                      }}
                    </Form.List>
                  );
                }}
              </Form.Item>
            </div>
            <Button block size="large" type="primary" className="mt-4" htmlType="submit">
              <BoldButtonLabel labelText="Update" />
            </Button>
          </section>
        </Form>
      </Modal>
      <Modal
        centered
        open={uploadModalVisible}
        onCancel={() => {
          setExcelResult(false);
          setTotalSuccess(0);
          setFileList([]);
          setUploadModalVisible(false);
        }}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Import Price Entries</Typography.Title>
          </div>
        }
      >
        <section className="flex flex-col justify-center">
          <Button type="link" className="mb-4" onClick={handleOnTemplateDownload}>
            Download Sample Template
          </Button>
          <Dragger
            beforeUpload={file => {
              setFileList([file]);
              return false;
            }}
            onRemove={() => {
              setExcelResult(false);
              setTotalSuccess(0);
              setFileList([]);
            }}
            fileList={fileList}
            name="file"
            accept={'.csv,.xlsx'}
          >
            <p>
              <CloudUploadOutlined className="text-9xl text-[#5885AF]" />
            </p>
            <p>Click or Drag Customers Excel File to upload</p>
          </Dragger>
          <Button
            disabled={_.isEmpty(fileList)}
            block
            size="large"
            type="primary"
            onClick={handleProductUpload}
            className="mt-4"
          >
            <BoldButtonLabel labelText="Validate" />
          </Button>
          {excelResult && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.Text className="text-red-500">
                Uploaded File duplicate Enteries : {totalDuplicate}
              </Typography.Text>
              <Typography.Text className="text-red-500">Total Failed: {totalFailed}</Typography.Text>
              <Typography.Text className="text-blue-500">Total Validated Items: {totalItems}</Typography.Text>
              <Typography.Text className="text-green-500">Total Success: {totalSuccess}</Typography.Text>
              <div className="flex gap-5">
                <Button
                  disabled={totalItems !== totalSuccess}
                  block
                  // size="large"
                  type="primary"
                  onClick={handleOnConfirmUpload}
                  className="mt-4"
                >
                  <BoldButtonLabel labelText="Confirm Upload" />
                </Button>
                <Button block type="primary" onClick={getReportData} className="mt-4">
                  <BoldButtonLabel labelText="Download Report" />
                </Button>
              </div>
            </div>
          )}
        </section>
      </Modal>
    </Form>
  );
};

export default PriceListPriceEnteries;
