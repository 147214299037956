import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, TimePicker, Typography } from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import { createEarnRulesInitialXml, createRuleTriggerTypeData, triggerTypeSelectOptions } from '../../data';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoader } from '../../../../stores/use-loader';
import { loyaltyService } from '../../services/loyalty.service';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import DmnModeler from 'dmn-js';
import dayjs from 'dayjs';
import { IEarnRuleDetails, IRuleDescription } from '../../types/earn-rules';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

interface IEarnRulesViewProps {}

const EarnRulesView: React.FunctionComponent<IEarnRulesViewProps> = props => {
  const [createForm] = Form.useForm();
  const {t}= useTranslation();

  const [earnRuleDetails, setEarnRuleDetails] = React.useState({} as IEarnRuleDetails);

  const navigate = useNavigate();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const modellerRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (_.isEmpty(earnRuleDetails)) {
      fetchRuleDetails();
    }

    if (modellerRef.current === null && earnRuleDetails.xml) {
      initializeModeller();
    }

    return () => {
      if (modellerRef.current !== null) {
        modellerRef.current.detach();
        modellerRef.current = null;
      }
    };
  }, [earnRuleDetails]);

  const { id } = useParams();

  const fetchRuleDetails = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getEarnRuleDetail(id as string);
    if (_.isEmpty(errors)) {
      setEarnRuleDetails(data);
      //!Add api values to form remaining
      let formValues = {
        trigger_type: data.trigger_type,
        applicablity_type: data.applicability_type,
        name: data.name,
        date_range: [dayjs(data.start_date), dayjs(data.end_date)]
      };

      if (data.scheduled_time) {
        const scheduled = {
          scheduled_time: dayjs(data.scheduled_time),
          scheduled_date: dayjs(data.scheduled_time)
        };
        formValues = { ...formValues, ...scheduled };
      }
      createForm.setFieldsValue(formValues);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const initializeModeller = async () => {
    const modeller = new DmnModeler({
      container: '#viewEarnRulesCanvas'
    });
    modellerRef.current = modeller;
    try {
      const { warnings } = await modeller.importXML(earnRuleDetails.xml);

      if (warnings.length) {
        console.log('import with warnings', warnings);
      } else {
        console.log('import successful');
      }
    } catch (err) {
      console.log('something went wrong:', err);
    }
  };

  return (
    <PrimaryLayout>
    <div className="container mx-auto px-4">
      <Card>
        <Row justify={'space-between'} className="mb-4">
          <Col>
            <Typography.Title level={3} className="text-[#2e2a5b]">
              {t('viewRule')}
            </Typography.Title>
          </Col>
        </Row>
        <div className="flex gap-4">
          <TenantInfo />
        </div>

        <section className="mt-4">
          <Form layout="vertical" form={createForm}>
            <Row gutter={12}>
              <Col xs={24} md={6}>
                <Form.Item name="trigger_type" label={t('triggerType')}>
                  <Select
                    placeholder={t('selectTriggerType')}
                    size="large"
                    onChange={() => createForm.setFieldValue('applicablity_type', null)}
                    options={triggerTypeSelectOptions}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.trigger_type !== curValues.trigger_type;
                  }}
                >
                  {form => {
                    const { trigger_type } = form.getFieldsValue();
                    const options = createRuleTriggerTypeData[trigger_type]?.applicabilityTypes || [];

                    return (
                      <Form.Item name="applicablity_type" label={t('applicabilityType')}>
                        <Select
                          placeholder={t('selectApplicabilityType')}
                          size="large"
                          options={options}
                          disabled={true}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item name="name" label={t('ruleName')}>
                  <Input placeholder={t('enterRuleName')} size="large" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.applicablity_type !== curValues.applicablity_type;
                }}
              >
                {form => {
                  const { applicablity_type } = form.getFieldsValue();

                  return applicablity_type === createRuleTriggerTypeData?.SCHEDULED?.label ? (
                    <>
                      <Col xs={24} md={6}>
                        <Form.Item name="schedule_date" label={t('scheduleDate')}>
                          <DatePicker className="w-full" size="large" disabled={true} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={6}>
                        <Form.Item name="schedule_time" label={t('scheduleTime')}>
                          <TimePicker className="w-full" size="large" format={'hh:mm A'} disabled={true} />
                        </Form.Item>
                      </Col>
                    </>
                  ) : null;
                }}
              </Form.Item>
              <Col xs={24} md={6}>
                <Form.Item name="date_range" label={t('date')}>
                  <DatePicker.RangePicker size="large" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div id="viewEarnRulesCanvas" className="h-[400px]"></div>
        </section>
        <Row gutter={12}>
          <Col xs={24} md={6}>
            <Button
              size="large"
              block
              onClick={() => {
                navigate(-1);
              }}
            >
              <BoldButtonLabel labelText={t('goBack')} />
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  </PrimaryLayout>
  );
};

export default EarnRulesView;
